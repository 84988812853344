import { TypePrevious } from '@/enums';
import Locale from '../types';

const locale: Locale = {
  systemName: 'Meta Track',
  systemCopyright: 'Developed by Meta Globaltech.',
  periodOfDay: {
    night: 'night',
    morning: 'morning',
    afternoon: 'afternoon',
  },
  daysOfWeek: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  input: {
    responsibleEmails: 'Email de la persona responsable',
    cantInteractMessage: 'Format the message to be displayed',
    name: 'Name',
    email: 'E-mail',
    password: 'Password',
    search: {
      name: 'Name',
      nameOrDocument: 'Name or document',
      code: 'Code',
    },
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    phone: 'Phone',
    maxText: 'Question (maximum 30 characters)',
    options: 'Options',
    confirmPassword: 'Password confirmation',
    date: 'Date',
    ncm: 'NCM',
    ean: 'EAN-13',
    fuelAmount: 'Fuel amount (in liters)',
    registrationKey: "Driver's key",
    oldPassword: 'Old password',
    newPassword: 'New password',
    code: 'Code',
    defaultValue: 'Default value',
    state: 'State',
    city: 'City',
    zipCode: 'ZIP Code',
    neighborhood: 'Neighborhood',
    street: 'Street',
    number: 'Number',
    complement: 'Complement',
    document: 'Document',
    documentType: 'Document type',
    description: 'Description',
    externalId: 'External identifier',
    observation: 'Observation',
    lot: 'Lot',
    resourceId: 'Resource',
    intrinsicMarking: 'Intrinsic marking',
    manufacturing: 'Manufacturing',
    due: 'Due',
    block: 'Block',
    inspection: 'Inspection date',
    days: 'Days',
    municipalRegistration: 'Municipal Registration',
    stateRegistration: 'State Registration',
    trade: 'Corporate Name ',
    active: 'Active',
    activateUsers: 'Activate Users',
    expires: 'Expires',
    kind: 'Kind',
    required: 'Required',
    userId: 'User',
    locationId: 'Select location',
    offline: 'Offline days',
    perRoll: 'Number of code rolls',
    price: 'Price',
    valid: 'Valid',
    like: 'Contains',
    equals: 'Equals',
    moreThan: 'Bigger then',
    lessThan: 'Less than',
    status: 'Status',
    externalCode: 'Code',
    codeAggregator: 'Aggregator code',
    accessKey: 'Access key',
    activation: 'select an event',
    unitCode: 'Unit code',
    port: 'Port',
    url: 'URL',
    aggregatorCodeAmount: 'Quantity of aggregator codes',
    companyId: 'Company',
    resourceNo: 'Event does not require resource',
    resource: 'Resource',
    startDate: 'Start date',
    endDate: 'End date',
    whatYouLikeOnProduct:
      'What caught your attention the most about this product?',
    doYouRecomend: 'Would you recommend this product?',
    classifyProduct: 'What rating does this product deserve?',
    marketplace: 'Marketplace',
  },
  select: {
    placeholder: {
      role: 'Role',
      fuelType: 'Fuel type',
      subsidiary: 'Subsidiary',
      invoiceTypes: 'Invoice types',
      invoiceStatus: 'Invoice status',
      select: 'Select an option',
      selectId: 'Select',
      selectCompany: 'Select a company',
      selectFunction: 'Select a feature',
      selectResource: 'Select a feature to activate',
      aggregator: 'To get started, add the aggregator code (box) below',
      selectPrinter: 'Select printer',
      aggregatorCode:
        'Add aggregator code (box) or volume by scanning or manually type below',
      sonCode: 'Then add the unit codes manually',
      codeScanner: 'Add code or volume by scanning or manually type below',
    },
    notify: 'Notify in case of multiple interactions',
    canMoreInteract: 'Allow multiple interactions for the same code',
    visualization: 'Visualization',
    interaction: 'Interaction',
    all: 'All',
    loading: 'Loading...',
    noOptions: {
      noRole: 'No roles found.',
      noFuelType: 'No fuel type found.',
      noSubsidiary: 'No subsidiary found.',
      noInvoiceTypes: 'No invoice types found.',
      noInvoiceStatus: 'No invoice status found.',
      generic: 'No data found.',
    },
  },
  button: {
    exportData: 'Export data',
    list: 'List',
    login: 'Log in',
    filter: 'Filter',
    previous: 'Previous',
    next: 'Next',
    register: 'Register',
    cleanFilters: 'Clean filters',
    cancel: 'Cancel',
    confirm: 'Confirm',
    import: 'Import',
    add: 'Add',
    resendCode: 'Resend code',
    addCode: 'Add code',
    update: 'Update',
    registerCodes: 'Register codes',
    generate: 'Generate',
    send: 'Send',
    uploadImage: 'Background image',
  },
  listing: {
    duplicated: 'Duplicate Interaction',
    name: 'Name',
    creationDate: 'Event time and date',
    quickActions: 'Quick Actions',
    email: 'E-mail',
    role: 'Role',
    client: 'Client',
    ncm: 'NCM',
    ean: 'EAN-13',
    proportion: 'Proportion',
    fuelAvailable: 'Gasoline available',
    accessKey: 'Access key',
    productAmount: 'Quantity of products',
    state: 'State',
    document: 'Document',
    documentType: 'Document type',
    codeValue: 'Code value',
    due: 'Expiration',
    resource: 'Resource',
    inspection: 'Inspection',
    currentState: 'Current state',
    codeAggregator: 'Aggregator code',
    printerAddress: 'Printer addressddd',
    companyId: 'Company',
    generated: 'Generated',
    amount: 'Amount',
    responsible: 'Responsible',
    identifier: 'Identifier',
    field: 'Field',
    origin: 'Origin',
    visualization: 'Visualization',
    question: 'Question',
    type: 'Type',
    options: 'Options',
    code: 'Code',
    week: 'Week',
    period: 'Period',
    dateTime: 'Date and Time',
    geoLocation: 'Geolocation',
    showStatus: 'Show Status',
    interactions: 'Interactions',
    answer: 'Answer',
    marketplace: 'Marketplace',
    interaction: 'Interaction',
    first: 'First',
    repeated: 'Repeated',
    resourceUnassigned: 'Unassigned Resource',
    time: 'Time',
  },
  accessibility: {
    iconTitle: {
      deleteCode: 'Remove code',
      details: 'Details',
      edit: 'Edit',
      importInvoice: 'Import invoice',
      increaseFuelStock: 'Add fuel',
      delete: 'Delete',
      cancel: 'Cancel',
      dispatch: 'Dispatch/Send',
      close: 'Close',
      clearSearch: 'Clear search',
      search: 'Search',
      language: 'Site language',
      myAccount: 'My account',
      filterDate: 'Filter by date',
    },
  },
  invoiceFields: {
    access_key: 'Access key',
    address: 'Address',
    number: 'Number',
    neighborhood: 'Neighborhood',
    city_name: 'City',
    city_code: 'City code',
    state: 'State',
    zip_code: 'ZIP code',
    nation_name: 'Country',
    nation_code: 'Country code',
    phone: 'Phone',
    destination_name: 'Destination name',
    document: 'Document',
    amount: 'Quantity',
    unit: 'Unit',
    price: 'Price',
    name: 'Product name',
  },
  feature: {
    master_company: 'Master Permission',
    index_company: 'Company Listing',
    show_company: 'Company Details',
    store_company: 'Register Company',
    update_company: 'Company Update',
    index_plan: 'Plan Listing',
    store_plan: 'Register Plan',
    update_plan: 'Plan Update',
    show_plan: 'Plan Details',
    index_location: 'Location Listing',
    store_location: 'Register Location',
    show_location: 'Location Details',
    update_location: 'Location Update',
    index_locationPosition: 'Location Position Listing',
    store_locationPosition: 'Register Location Position',
    show_locationPosition: 'Location Position Details',
    update_locationPosition: 'Location Position Update',
    index_invoice: 'Invoice Listing',
    store_invoice: 'Register Invoice',
  },
  page: {
    generic: {
      list: {
        filter: {
          title: 'Filters',
          total: 'Total',
          filterAnyData: 'Filter data',
          others: 'Others filters',
          role: 'Role',
          noFilterMessage: 'No filter selected',
        },
        noDataMessage: 'No Data',
        download: 'Download',
      },
      save: {
        headerTitle: 'Add',
        import: 'Import',
      },
      edit: {
        headerTitle: 'Edit',
        title: 'Edit {{identifier}}',
      },
      details: {
        headerTitle: 'Details',
        notAssociated: 'Not associated',
        uninformed: 'Uninformed',
      },
    },
    login: {
      formLabel: 'Log in',
      formTitle: 'your account',
      button: {
        forgotPassword: 'Forgot my password',
      },
    },
    users: {
      headerTitle: 'Users',
      list: {
        filter: {
          type: 'type',
          activated: 'Activated',
          disabled: 'Disabled',
        },
        noDataMessage: 'No roles message',
        modal: {
          search: {
            title: 'Search in users',
          },
          filter: {
            title: 'Filter users',
          },
          confirmStatusChange: {
            title: 'Confirm change',
            activate: 'activate',
            disable: 'disable',
            warning:
              'You are trying to {{action}} user {{name}}. Are you sure about that?',
          },
        },
      },
      save: {
        headerTitle: 'Register',
        title: 'Register a new user',
        error: {
          cant_add_user: 'Can not register users.',
        },
      },
      edit: {
        headerTitle: 'Edit',
        title: 'Edit a user',
        error: {
          cant_edit_user: 'Can not edit users.',
        },
      },
    },
    distributors: {
      headerTitle: 'Distributors',
      list: {
        filter: {
          total: 'Total',
        },
        noDataMessage: 'No distributors found',
        modal: {
          search: {
            title: 'Search in distributors',
          },
          filter: {
            title: 'Filter distributors',
          },
        },
      },
      save: {
        headerTitle: 'Register',
        title: 'Register a new distributor',
        subTitle: 'Register administrator',
        error: {
          cant_add_distributor: 'You can not register distributors.',
        },
      },
      edit: {
        error: {
          cant_edit_distributor: 'You can not register distributors.',
        },
      },
      details: {
        headerTitle: 'Details',
        title: 'Subsidiaries',
        noSubsidiariesFound: 'No subsidiaries found',
        noStockFound: 'No stock found',
        stockItem: 'It has {{amount}}L (liters) of {{name}}',
      },
    },
    invoices: {
      headerTitle: 'Invoices',
      list: {
        noData: 'No invoice imported.',
        modal: {
          search: {
            title: 'Search in invoices',
          },
          transferCustody: {
            title: 'Custody transfer',
          },
          confirmCancel: {
            title: 'Cancel invoice',
            warning:
              'This action is IRREVERSIBLE. Are you sure you want to cancel this invoice?',
          },
          filter: {
            title: 'Filter invoices',
          },
        },
        types: {
          company: 'Meta',
          distributor: 'Distributors',
        },
        status: {
          creation: 'Created/No movement',
          dispatched: 'Shipped cargo',
          received: 'Received cargo',
          canceled: 'Canceled',
        },
      },
      save: {
        headerTitle: 'Invoices',
        error: {
          cant_import_invoices: 'You can not import invoices.',
          no_files_inserted: 'No files inserted.',
        },
        xFilesInserted: '{{length}} files inserted.',
        uploadBoxLabel: 'Click or drag files here.',
      },
      details: {
        label: {
          accessKey: 'Access key',
          importDate: 'Import date (in the system)',
          dispatchDate: 'Dispatch date',
          receiverName: 'Receiver name',
          receiverDocument: 'Receiver document',
          documentType: 'Document type',
          city: 'City',
          state: 'State',
          zipCode: 'ZIP Code',
          neighborhood: 'Neighborhood',
          address: 'Address',
          phone: 'Phone',
          driverName: 'Driver name',
          driverEmail: 'E-mail',
          registrationKey: 'Registration key',
          ean: 'EAN-13: ',
          ncm: 'NCM: ',
          price: 'Price: ',
          statusInvoice: 'Invoice status',
        },
        value: {
          notDispatched: 'Not dispatched',
        },
        productsTitle: 'Products',
      },
    },
    subsidiaries: {
      headerTitle: 'Subsidiaries',
      list: {
        noDataMessage: 'No subsidiary found',
        modal: {
          search: {
            title: 'Search in subsidiaries',
          },
          filter: {
            title: 'Filter subsidiaries',
          },
        },
      },
    },
    fuelTypes: {
      headerTitle: 'Fuel types',
      list: {
        noDataMessage: 'No types found',
        modal: {
          search: {
            title: 'Search in fuel types',
          },
          filter: {
            title: 'Filter fuel types',
          },
        },
      },
      save: {
        headerTitle: 'Add',
        title: 'Add fuel type',
        subTitle: 'Proportion in liters (Fuel x Catalyst)',
        error: {
          cant_add_fuel_types: 'You can not add fuel types.',
        },
      },
      edit: {
        error: {
          cant_edit_fuel_types: 'You can not edit fuel types.',
        },
      },
    },
    distributorFuels: {
      headerTitle: 'Fuels',
      list: {
        noDataMessage: 'No fuels found',
        modal: {
          search: {
            title: 'Search in fuels',
          },
          filter: {
            title: 'Filter fuels',
          },
          increaseFuelStocks: {
            title: 'Add fuel to stock',
          },
        },
      },
      save: {
        headerTitle: 'Add',
        title: 'Add fuel',
        error: {
          cant_add_distributorFuels: 'You can not add fuel.',
        },
      },
      edit: {
        error: {
          cant_edit_distributorFuels: 'You can not edit fuel.',
        },
      },
    },
    activateAccount: {
      title: 'Activate your account',
    },
    addEvent: {
      headerTitle: 'Update product status',
      noCodeOnList: 'No code added to list',
      events: {
        updated: 'Updated',
        canceled: 'Canceled',
        misplaced: 'Misplaced',
        stolen: 'Stolen',
        damaged: 'Damaged',
      },
      error: {
        code_already_added:
          'The code {{code}} has already been added to the list.',
        no_code_added: 'No code added.',
        unknow_user: 'User not found',
        invalid_company_code: 'This code is not associated with your company.',
        no_valid_codes: 'There are no valid codes in the list.',
        unknow_resource: 'Unassigned Resource',
        no_events_found: 'No events found',
      },
    },
    automaticActivation: {
      headerTitle: 'Activate products at the production level',
    },
    aggregationAndEvents: {
      headerTitle: 'Aggregation and activation in production',
    },
    passwordRecovery: {
      title: 'Recover password',
    },
    passwordUpdate: {
      title: 'Update password',
    },
    safety: {
      headerTitle: 'Rules',
      title: 'All rules',
      add: {
        title: 'Add rule',
      },
      edit: {
        title: 'Edit role',
        information: 'Information for this role',
      },
      permissionsLabel: 'permissions',
      error: {
        no_permissions_selected: 'No permissions selected',
      },
      permission: {
        module: {
          distributor: 'Distributor',
          distributorFuel: 'Distributor fuel',
          distributorInvoice: 'Distributor fiscal note',
          event: 'Events',
          fuelStock: 'Fuel stock',
          fuelType: 'Type of fuel',
          gasStation: 'Gas station',
          invoice: 'Invoice',
          role: 'Rule',
          subsidiary: 'Subsidiary',
          user: 'User',
          company: 'Company',
          client: 'Client',
          resource: 'Resource',
          catalyst: 'Catalyst',
          plan: 'Plans',
          code: 'Codes',
          status: 'Status',
          resourceField: 'Resource field',
          locationPosition: 'Location position',
          location: 'Location',
          roll: 'Roll',
          printer: 'Printer',
          detailedReport: 'Detail report',
          questions: 'Questions',
          interactions: 'Interactions',
          answers: 'Answers',
          heatMapReport: 'Heat map report',
          report: 'Report',
          reducedReport: 'Reduce report',
          totalReport: 'Total report',
          licenQrCode: 'License QrCode',
          entrance: 'Entrance',
          packing: 'Packing',
          tracking: 'Tracking',
        },
        type: {
          nothing: 'Nothing',
          index: 'Read',
          store: 'Read and create',
          index_update: 'Read and update',
          update: 'Read, update and delete',
          update_store: 'Read, create, and update',
          delete: 'Read, create, update and delete',
          store_event: 'Create event',
          index_event: 'List events',
          store_event_transfer_custody: 'Transfer of custody',
          store_event_update: 'Update',
          store_event_cancel: 'Cancel',
          store_event_misplaced: 'Lost',
          store_event_damaged: 'Damaged',
          store_event_receipt_confirmed: 'Confirmation of receipt',
        },
      },
    },
    tracking: {
      headerTitle: 'Tracking',
      noEvents: 'No movement performed.',
      modal: {
        search: {
          title: 'Search in movements',
        },
      },
      popupLabel: {
        name: 'Name',
        realizedAt: 'Realized at',
      },
      filterLabel: {
        all: 'All',
        write: 'Write',
        read: 'Read',
      },
    },
    settings: {
      headerTitle: 'Settings',
    },
    status: {
      previousStatus: 'Previous status',
      headerTitle: 'Status',
      save: {
        headerTitle: 'Add Status',
        [TypePrevious.ALL]: 'All status',
        [TypePrevious.NO_ONE_OF]: 'None of',
        [TypePrevious.ONE_OF]: 'One of the',
        editable: 'Editable',
        statusFields: 'Status fields',
      },
    },
    clients: {
      headerTitle: 'Clients',
      save: {
        title: 'Add clients',
      },
      edit: {
        title: 'Edit client',
      },
    },
    plans: {
      headerTitle: 'Plans',
      save: {
        headerTitle: 'Add',
        title: 'Plans',
      },
      edit: {
        headerTitle: 'Edit',
        title: 'Plans',
      },
    },
    companies: {
      headerTitle: 'Companies',
      save: {
        headerTitle: 'Add',
        title: 'Companies',
        license: 'License',
        address: 'Address',
      },
      edit: {
        headerTitle: 'Edit',
        title: 'Companies',
        license: 'License',
        address: 'Address',
      },
    },
    resources: {
      headerTitle: 'Resources',
      save: {
        headerTitle: 'Add feature',
        title: 'Register product',
      },
      edit: {
        headerTitle: 'Resource editing',
        title: 'To edit',
      },
    },
    resourceFields: {
      headerTitle: 'Resource field',
      save: {
        headerTitle: 'Add field',
        title: 'Register field',
      },
      list: {
        headerTitle: 'List resource field',
      },
      edit: {
        headerTitle: 'Edit field',
        title: 'To edit',
      },
    },
    locationsPositions: {
      headerTitle: 'Location position',
      List: {
        headerTitle: 'List locations',
      },
    },
    locations: {
      headerTitle: 'Location',
    },
    codes: {
      headerTitle: 'Codes',
      due: {
        headerTitle: 'Due',
        list: {
          noDataMessage: 'No existing data',
          filter: {
            type: 'Filter expiration',
          },
          modal: {
            filter: {
              title: 'Filter days',
            },
          },
        },
      },
      inspection: {
        headerTitle: 'Inspection',
        list: {
          noDataMessage: 'No existing data',
          filter: {
            type: 'Filter expiration',
          },
          modal: {
            filter: {
              title: 'Filter days',
            },
          },
        },
      },
      details: {
        label: {
          code: 'Code',
          updatedAt: 'Last update',
          accessKey: 'Access key',
          resourceName: 'Resource',
          currentState: 'Status',
          externalCode: 'External code',
          lot: 'Batch',
          aggregatorCode: 'Aggregator code',
        },
      },
    },
    statusFields: {
      headerTitle: 'Status fields',
    },
    packing: {
      headerTitle: 'Aggregation of unit codes',
    },
    entrance: {
      headerTitle: 'Product custody transfer',
    },
    rolls: {
      headerTitle: 'Code roll',
      print: {
        headerTitle: 'Roll printing',
      },
    },
    rollsList: {
      headerTitle: 'List of generated codes',
    },
    events: {
      headerTitle: 'Events',
    },
    printers: {
      headerTitle: 'Impresoras',
    },
    interactionConfig: {
      headerTitle: 'Fields of direct interaction',
      reviewTitle: 'Campos de interacción directa',
    },
    interactionReducedReport: {
      headerTitle: 'Reduced Interaction Report',
    },
    interactionTotalReport: {
      headerTitle: 'Total Interaction Report',
    },
    interactionDetailedReport: {
      headerTitle: 'Detailed Interaction Report',
    },
    licensedCodeList: {
      headerTitle: 'Licensee QR Code',
      save: {
        headerTitle: 'Add',
        title: 'Licensee QR Code',
      },
    },
  },

  error: {
    minTwoElements: 'Must have at least two options',
    validation: {
      email_invalid: 'Invalid e-mail',
      email_required: 'Enter the e-mail',
      password_required: 'Enter a password',
      name_required: 'Enter your name',
      description_required: 'Inform the description',
      phone_required: 'Enter your phone',
      max_char_limit_reached: 'Maximum characters reached',
      min_password_char_required: 'Password must be at least 8 characters',
      confirm_password_required: 'Confirm your password',
      password_dont_match_pattern:
        'Use eight or more characters with a combination of letters, numbers and symbols',
      passwords_dont_match: 'Passwords do not match',
      distributor_name_required: 'Enter distributors name',
      location_permission_required: 'We need access to your location.',
      code_not_belongs_to_company:
        'Invalid code: The entered code is not registered for this company.',
      ncm_required: 'Enter the NCM',
      ean_required: 'Enter the EAN-13',
      fuel_type_required: 'Enter the fuel type',
      event_required: 'Enter the event',
      observation_required: 'Enter a observation',
      fuel_amount_required: 'Enter the fuel amount',
      access_denied: 'Access denied.',
      page_not_found: 'Page not found.',
      missing_code: 'No code entered.',
      role_required: 'Enter a role.',
      document_required: 'Enter the selected document',
      zip_code_required: 'Mandatory postal code',
      state_required: 'Mandatory state field',
      city_required: 'Mandatory city',
      neighborhood_required: 'Mandatory neighborhood',
      street_required: 'Mandatory street',
      number_required: 'Mandatory number',
      features_required: 'Mandatory features',
      port_required: 'Enter a port',
      url_required: 'Enter a URL',
      companyId_required: 'Enter a company',
      marketplace_required: 'Enter a marketplace',
      emails_required: 'Enter a E-mail',
      no_numbers_allowed: 'Enter only letters',
      municipalRegistration_required: 'Inform the municipal registry',
      stateRegistration_required: 'Report the state registry',
      trade_required: 'Enter the company name',
      trade_invalid_format: 'Minimum 8 characters',
      municipal_format: 'Mínimum 6 characters',
      municipalRegistration_invalid_format:
        'This field does not accept letters, minimum 8 numbers and maximum 15',
      planId_required: 'Select a license',
      plan_required: 'Enter the name of the plan',
      userId_required: 'Select a user',
      location_required: 'Provide a location',
      kind_required: 'Inform the type of the field',
    },
    api: {
      // server
      internal_server_error: 'Internal server error',
      // company
      company_not_found:
        'Company data not found. Inform your system administrator.',
      // consumer
      consumer_document_already_register:
        'A consumer is already registered with this document.',
      consumer_email_already_exist:
        'A consumer is already registered with this email.',
      consumer_not_found: 'Consumer not found. {{identifier}} not found.',
      consumer_password_incorrect: 'Incorrect password.',
      consumer_phone_already_register:
        'A consumer with this phone is already registered.',
      // distributorFuel
      distributor_fuel_not_found: 'Fuel not found.',
      missing_fuel_ean_and_ncm: 'EAN-13 and NCM not found.',
      // distributor
      distributor_already_register: 'Distributor already registered',
      distributor_not_found: 'Distributor not found',
      // driver
      driver_client_not_found: 'Client not found.',
      driver_document_already_register:
        'A driver is already registered with this document.',
      driver_profile_not_found: 'Driver not found.',
      driver_work_already_delivered:
        'This service has already been completed. Cargo delivered.',
      driver_work_not_found: 'Service not found.',
      // gasStation
      gas_station_not_found: 'Station not found.',
      // gasStationStock
      gas_station_stock_blocked: "This station's stock is blocked.",
      gas_station_stock_not_available:
        'Station stock unavailable: there is not enough stock for the action.',
      gas_station_stock_not_found: 'Station stock not found.',
      // invoice
      error_when_import_invoice:
        'Error trying to import the invoice, try again.',
      invoice_already_dispatched: 'Invoice has already been dispatched.',
      invoice_cancel_failed: 'Error trying to cancel invoice. Try again.',
      invoice_not_able_to_cancel: 'Invoice can no longer be cancelled.',
      invoice_not_able_to_work: 'Invoice not eligible for work.',
      invoice_not_found: 'Invoice not found.',
      invoice_products_identifiers_not_found:
        'EAN-13 and NCM of the product {{productName}} of the invoice not found.',
      missing_field_in_xml_invoice:
        'The field {{field}} of the invoice was not found.',
      no_fiels_uploaded: 'No files uploaded.',
      user_not_allowed_to_import_invoice:
        'You do not have permission to import an invoice.',
      // fuelType
      fuel_type_already_register: 'Fuel type already registered.',
      fuel_type_not_found: 'Fuel type not found.',
      // receipt
      access_key_receipt_not_found: 'Receipt not found.',
      consumer_invoice_not_found: 'Consumer Invoice not found.',
      // roll
      permission_not_allowed:
        'The role you are trying to create has permissions that are not allowed for your user.',
      permission_not_found: 'Permission not found.',
      role_already_exist: 'Role already created.',
      role_not_found: 'Role not found.',
      // solicitation
      amount_not_available: 'Not enough codes. Try again.',
      code_not_found: 'Code not found.',
      movement_permission_denied: 'You are not allowed to hold this event.',
      solicitation_not_found: 'Request codes not found.',
      // user
      cant_disable_user:
        'You are not allowed to activate or deactivate an employee.',
      company_deactivated_for_add:
        'The company must be activated to register an employee.',
      email_already_registered: 'A user with this email is already registered.',
      inform_old_password: 'Please enter the old password.',
      incorrect_password: 'Incorrect password.',
      password_are_the_same: 'The passwords are the same.',
      passwords_dont_check: "Passwords don't match.",
      role_not_allowed: 'System role not allowed.',
      token_already_used: 'Token already used.',
      token_not_found: 'Token not found.',
      token_not_provided: 'Token not informed.',
      user_active_limit:
        'You have exceeded your active user limit, please update your plan to activate more.',
      user_add_limit:
        'You have exceeded the limit of registered users, update your plan to register more.',
      user_already_exist: 'User already registered.',
      user_disabled: 'User disabled.',
      user_unauthorized: 'Unauthorized user.',
      user_not_found: 'User not found.',
      // session
      token_expired: 'Session expired.',
    },
  },
  success: {
    updatedData: 'Data updated successfully.',
    passwordRecoveryLinkSent: 'Password recovery link sent.',
    passwordRecovered: 'Password recovery.',
    successfullyImportedInvoices: 'Successfully imported invoices.',
    distributorRegistered: 'Distributor successfully registered.',
    transferCustodySuccess: 'Transferred custody',
    increaseFuelStock: 'Added fuel stock',
  },

  // olds
  Site: {
    initialMessage:
      'With the MetaTrack tracking system you have greater stability, intelligence and ease in managing and moving your products.',
    accountActive: 'Activate your account',
    passwordRecovery: 'Forgot password?',
    passwordRecoveryTitle: 'Recover your password',
    newPasswordTitle: 'Defina sua nova senha',
  },
  Label: {
    ncm: 'ncm',
    fuelAmount: 'Amount of fuel',
    distributorName: 'Client name',
    ptBR: 'Portuguese - Brazil',
    enUS: 'English',
    esES: 'Spanish',
    password: 'Password',
    email: 'E-mail',
    verifyCode: 'Product code',
    confirmPassword: 'Confirm your password',
    code: 'Code',
    region: 'Region',
    fuel: 'Amount of fuel in liters',
    trade: 'Trading Name',
    companyName: 'Company name',
    municipalRegistration: 'Municipal registration',
    municipalExempt: 'exempt',
    stateRegistration: 'State registration',
    stateExempt: 'exempt',
    state: 'State',
    city: 'City',
    address: 'Address',
    neighborhood: 'Neighborhood',
    complement: 'Complement',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    cep: 'CEP',
    name: 'Full name',
    phone: 'Phone',
    office: 'Office',
    contactType: 'Department',
    ownFleet: 'Own fleet',
    driverName: 'Driver name',
    destiny: 'Destiny',
    licensePlate: 'Vehicle plate',
    observation: 'Observation',
    aggregateCode: 'Volume / product code',
    aggregationCode: 'Volume code',
    nickname: 'Nickname',
    url: 'URL',
    port: 'TCP port',
    productImage: 'Product Image',
    amount: 'Amount',
    partitions: 'Partitions',
    pce: 'PCE',
    country: 'Country',
    factory: 'factory',
    weight: 'Weight',
    unit: 'Unit of measurement',
    ean: 'EAN-13',
    dun: 'DUN-14',
    stackingLimit: 'Stacking limit',
    warning: 'Warning',
    productIdentifier: 'Porduct ID',
    description: 'Description',
    perishable: 'Perishable',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    shippingCompanyName: 'Company name or nickname',
    productCode: 'Product code',
    solicitationDescription: 'Request description',
    manufacturing: 'Manufacturing date',
    due: 'Due date',
    lot: 'allotment',
    intrinsicMarking: 'Intrinsic marking',
    partitionsAmount: 'Number of partitions',
    abbrev: 'Abbreviation',
    un: 'UN number',
    netWeight: 'Net weight',
    netUnit: 'Unit of measurement (Kg, g, ...)',
    grossWeight: 'Gross weight',
    grossUnit: 'Unit of measurement  (Kg, g, ...)',
    productSearch: 'Search by name or PCE',
    nameSearch: 'Search by name',
    invoiceSearch: 'Search by name',
    searchPrinter: 'Search by name or url',
    carriersSearch: 'Search by name, CNPJ or CPF',
    activitySearch: 'Paste the code here',
    tr: 'TR',
    store: 'Store',
    clientSearch: 'Search by name',
    client: 'Client',
    unitCode: 'Código unitário',
    company_not_exist: 'Company does not exist',
  },
  Button: {
    unBlock: 'unBlock',
    confirm: 'Confirm',
    add: 'Add',
    cancel: 'Cancel',
    submit: 'Submit',
    update: 'Update',
    transfer: 'Transfer',
    import: 'Import',
    register: 'Register',
    addCode: 'Add code',
    filter: 'Filter',
    order: 'Order',
    previous: 'Previous',
    next: 'Next',
    reprint: 'Reprint',
    newRequest: 'New request',
    downloadCodes: 'Download codes',
    requestPartitionCodes: 'Request partition codes',
    aggregateNewPartition: 'Aggregate new partition',
    printRequests: 'Print requests',
    printPartitionRequests: 'print partition requests',
    makeRequest: 'Make a request',
    makeAggregation: 'Make aggregation',
    print: 'Print',
    printedRequests: 'Printed requests',
    unprintedRequests: 'unprinted requests',
    product: 'Produto',
    volume: 'Volume',
    cleanFilters: 'Clean filters',
    olderRequests: 'Older requests',
    latestRequests: 'Latest requests',
    backToDefault: 'Back to default',
    dProduction: 'Daily production',
    linkInvoice: 'Link invoice',
    transferInvoice: 'Transfer custody',
    choosePrinter: 'Choose a printer.',
    download: 'Download',
    edit: 'Edit',
    delete: 'Delete',
    details: 'Details',
    addRep: 'Add rep',
    moreOptions: 'More options',
  },
  SolicitationPage: {
    title: 'Request codes',
    products: 'Products',
    partitions: 'Partitions',
    volumes: 'Volumes',
    impressPrint: 'ImpressPrint',
    tableProduct: {
      requestByProduct: 'Request by product',
      favorites: 'Favorites',
      products: 'Products',
      available: 'Available',
      print: 'Print',
      generateCodes: 'Generate codes',
      more: 'More',
    },
    tableVolume: {
      volumeRequest: 'Volume Request',
      amount: 'Amount: ',
    },
    addSolicitation: {
      subTitle: 'New request',
      cardTitleProduct: 'Generate more codes for',
      cardTitlePartition: 'New partition request ',
      labelProduct: 'Product',
      labelVolume: 'Volume',
      selectProduct: 'Select the product ...',
      selectVolume: 'Select the volume ...',
      noProductsFound: 'No products found',
      noVolumeFound: 'No volume found',
      yup: {
        noObservation: 'Enter the description of the request.',
        required: 'Enter the number of codes to generate.',
        amount: 'Enter a number for the number of volume codes.',
      },
    },
    addSolicitationModal: {
      title: 'Make a new request for ',
    },
    partitionAggregationModal: {
      title: 'Partition aggregation',
      yup: {
        volumeRequired: 'Enter a volume code.',
        productRequired: 'Enter a product code.',
      },
    },
    addSolicitationPartitionModal: {
      title: 'Partition request',
    },
    print: {
      selectPrinter: 'Select printer...',
      noPrinterFound: 'Not printer found.',
      noCodeAdded: 'No code added.',
      productPrinting: 'Product printing ',
      partitionPrinting: 'Partition printing ',
      volumePrinting: 'Volume printing ',
      available: 'Available',
      volumeOf: 'Volume of',
      availableCode: ' Available code(s)',
      yupProduct: {
        requiredLot: 'Enter the batch.',
        requiredObservation: 'Inform the observation of this operation.',
        amountRequired: 'Inform the quantity of products to be printed.',
        manufacturingRequired: 'Inform the date of manufacture of the product.',
        dueRequired: 'The product is perishable, inform the expiration date.',
        intrinsicMarkingRequired: 'Enter the intrinsic markup.',
        printerRequired: 'Choose a printer.',
      },
      error: {
        description: 'You must write code.',
        codeLimit: 'You cannot exceed the aggregate limit.',
        thisCodeOne: 'The code',
        thisCodeTwo: 'is already in the code list.',
        notBelongProduct: 'This product does not belong to this volume.',
        notBelongVolume: 'That volume does not belong to this volume.',
        exceedingLimitCodes: 'Quantity is exceeding the limit of',
      },
    },
    filterModal: {
      title: 'Filter requests',
      subTitleOne: 'Filter by print status',
      subTitleTwo: 'Filter by type',
      subTitleThree: 'Filter by date',
    },
    orderModal: {
      title: 'Sort requests',
      subTitleOne: 'Sort by name',
      subTitleTwo: 'Sort by date',
    },
    reprint: {
      title: 'Reprint volume label',
      yup: {
        codeRequest: 'Enter the volume code. ',
        printerRequest: 'Escolha uma impressora.',
      },
    },
  },

  Volume: {
    title: 'Volumes',
    selectVolumeContent: 'Select volume content',
    total: 'Total: ',
    all: 'All',
    addVolumes: {
      add: 'Add',
      edit: 'Details',
      cardTitle: 'Add a new volume',
      products: 'Products',
      volumes: 'Volumes',
      selectProduct: 'Select the product...',
      selectVolume: 'Select the volume...',
      selectBarcode: 'Select the type of bars...',
    },
    name: 'Name',
    abbrev: 'Abbreviation',
    amount: 'Quantity',
    codes: 'Available codes',
    content: 'Content',
    date: 'Date',
    actions: 'More options',
    options: {
      details: 'Details',
      codeGenerate: 'Generate Codes',
      printBox: 'Print Box',
      rePrint: 'reprint Box',
    },
  },
  Locale: {
    role: 'Roles',
    user: 'User',
    company: 'Comapny',
    license: 'License',
    shippingCompany: 'Shipping Company',
    solicitation: 'Solicitations',
    code: 'Codes',
    invoice: 'Invoices',
    event: 'Movimentations',
    product: 'Products',
    printer: 'Printers',
    report: 'Reports',
    distributor: 'Distributors',
    gasStation: 'Fuel Stations',
    distributorFuel: 'Distributor Fuels',
    subsidiary: 'Subsidiary',
    fuelStock: 'Fuel Stock',
    employee: 'Employees',
    gasStationStock: 'Stock of Gas Stations',
    driverClient: 'Carrier Clients',
    driverWork: 'Carrier Services',
    driverProfile: 'Carrier Profile',
  },
  Error: {
    noDataSolicitation: 'No resquest found.',
    noDataVolume: 'No volume found',
    noDataProduct: 'No sales found',
    locationPermission: 'Access to your location is required.',
    minorDate:
      'The expiration date may not be less than the manufacturing date.',
    noExistCode: 'This code does not exist in the system.',
    unableCode: 'This code is unable to be printed.',
    codeNeverPrinted: 'This code was never printed.',
    choosePrinter: 'Choose a printer.',
    register: 'Register',
    previous: 'Previous',
    next: 'Next',
    dProduction: 'Daily production',
    linkInvoice: 'Link invoice',
    transferInvoice: 'Transfer custody',
    productPermission: 'You are not allowed to add a product.',
    userAddPermission: 'You do not have permission to register an user.',
    volumeAddPermission: 'You are not allowed to add a volume.',
    addShippingCompanyAddPermission:
      'You do not have permission to register a carrier.',
    dailyProductionPermission:
      'You are not allowed to generate a report on daily production.',
    editShippingCompanyAddPermission:
      'You do not have permission to register a carrier.',
    clientAddPermission: 'You do not have permission to add a client.',
    employeeAddPermission: 'You do not have permission to add a employee.',
    representativeAddPermission:
      'You do not have permission to add a delegate.',
    clientEditPermission: 'You do not have permission to edit a client.',
    representativeEditPermission:
      'You do not have permission to edit a delegate.',
    volumeEditPermission: 'You are not allowed to edit a volume.',
    printerEditPermission: 'You do not have permission to edit a printer.',
    userEditPermission: 'You do not have permission to edit an user.',
    employeeEditPermission: 'You do not have permission to edit an employee.',
    typePackageProdcut: 'Inform the type of packaging',
    noBarCode: 'Inform the type of the barcode.',
    existProductIdentifier: 'A product with this identifier already exists.',
    amountProductSolicitation: 'The requested quantity is not available',
    notFoundBarcode: 'No barcode type found.',
    serverCommunication: 'There was an error communicating with the server.',
    noAddress: 'Address not found',
    noState: 'State not found',
    noCity: 'City not found',
    downloadSucess: 'File downloaded successfully',
    oneField: 'Please fill at least one field.',
    deletePermission: 'You do not have permission to delete this item.',
    errorPassword:
      'The password must have a lowercase letter, a capital letter, a number and a special character.',
  },
  Select: {
    noSubsidiaries: 'noSubsidiaries',
    subsidiaries: 'subsidiaries',
    statesList: 'Select one condition...',
    solicitationSearch: 'Search by description',
    nameSearch: 'Search by name or E-mail',
    function: 'Select occupation...',
    noFunction: 'No occupation found.',
    select: 'Select...',
    selectPacking: 'Select the packaging',
    selectBarCode: 'Select the type of barcode...',
    selectCity: 'Select the city...',
    noState: 'No state found.',
    noCity: 'No cities found.',
    fuelType: 'Select the fuel type',
    noFuelType: 'No fuel type available, register at least one',
  },
  Navbar: {
    settings: 'Settings',
    exit: 'Exit',
  },
  Yup: {
    empty: 'This field cannot be empty',
    noName: 'Enter the name',
    noEmail: 'Enter the e-mail',
    validEmail: 'Email must be a valid email',
    noPhone: 'Enter the phone',
    noAmount: 'Inform the quantity.',
    noObservation: 'Enter a note for this operation.',
    noCode: 'Enter the link code',
    noEan: 'Inform the products EAN-13.',
    noDistributorName: 'Enter the name of the distributor to be registered',
    noDun: 'Inform the DUN-14 of the packaging.',
    noPositive: 'This number must be positive',
    noWarning: 'Enter the warning for this volume',
    noStackingLimit: 'Enter the stacking limit for this volume',
    noInteger: 'This number must be an integer',
    noDivisible:
      'The quantity of products must be divisible by the quantity of partitions.',
    noBarCode: 'Enter the type of the barcode.',
    noPartitions: 'Enter the number of partitions.',
    noPacking: 'Inform the type of packaging.',
    noCountry: 'Enter the country code.',
    noFactory: 'Enter the factory code.',
    noWeight: 'Inform the mass of the product.',
    noUnity: 'Enter the unit of measure of mass.',
    noId: 'Enter the product id',
    noUrl: 'Enter the printers url.',
    noPort: 'Enter the printer port.',
    noAbbrev: 'The abbreviation is mandatory.',
    noNetWeight: 'Enter the net weight.',
    noGrossWeight: 'Enter the gross weight.',
    noProduct: 'Choose the product.',
    noVolume: 'Choose the volume.',
    minFixed3: 'the length must be 3 characters',
    minFixed4: 'the length must be 4 characters.',
    minFixed5: 'the length must be 5 characters',
    min4: 'the length must be at least 4 characters.',
    min5: 'the length must be at least 5 characters',
    max5: 'the length must be a maximum of 5 characters',
    min8: 'the length must be at least 8 characters',
    maxFixed15: 'The number entered exceeds the 15 character limit.',
    noCnpj: 'Inform the CNPJ.',
    noCpf: 'Inform the CPF.',
    sizeCnpj: 'CNPJ invalid, type again.',
    sizeCPF: 'CPF invalid, type again.',
    noMunicipalRegistration: 'Inform the municipal registry.',
    noStateRegistration: 'Inform the state registry.',
    noCep: 'Inform the zip code',
    noTargetUf: 'Inform the State',
    noTargetCity: 'Inform the city.',
    noNeighborhood: 'Inform the neighborhood.',
    noAddress: 'Enter the address.',
    noOffice: 'Inform the position',
    noContactType: 'Inform the sector',
    noPassword: 'enter a password',
    confirmPassword: 'Confirm the password',
    passwordsDontCheck: "Passwords don't check",
    minPassword: 'Password must be at least 8 characters',
    matchesPassword:
      'Password must be at least a lowercase letter, a capital letter, a number and a special character.',
    requiredField: 'Required field',
    noTrade: 'Enter your fancy name.',
    companyName: 'Inform the company name.',
    oldPassword: 'Informe the old password',
    noDriver: 'Enter the name of the driver.',
    errorEAN: 'Letters are not allowed in an EAN.',
    driverCPF: "Inform the driver's CPF.",
    errorCPF: 'Invalid CPF, type again.',
    noDestiny: 'Inform the destination.',
    noPlate: 'Inform the license plate.',
    noLocation: 'You must allow access to your location.',
    grossUnit: 'grossUnit',
    netUnit: 'netUnit',
  },
  AddMoviment: {
    title: 'Codes moviments',
    noCode: 'No code added.',
    options: {
      deaggregation: 'Volume breakdown',
      location: 'Location',
      cancel: 'Canceled',
      lost: 'Lost',
      damaged: 'Damaged',
      unlinked: 'Unlinked from Invoice',
      partition: 'Cancel partitions',
    },
  },
  Company: {
    title: 'Your company',
    infoTitle: 'Company information',
    updateTitle: 'Update business data',
  },
  User: {
    subsidiaryName: 'subsidiaryName',
    title: 'Users',
    noUser: 'No users found',
    occupation: 'Occupation:',
    client: 'Client',
    role: 'User rules',
    actions: 'More options',
    total: 'Total',
    type: 'Type',
    register: 'Register',
    addUser: 'Register a new user',
    userActive: 'User Activated',
    userDeactive: 'User Disabled',
    edit: 'Edit',
    creat: 'Joined:',
    infor: 'User information',
    permi: 'Customer information',
    active: 'Actives',
    desable: 'Disabled',
    orgName: 'Customer name:',
    name: 'Name',
    email: 'E-mail',
    activeUser: 'Activate user',
    deactiveUser: 'Deactivate user',
    editUser: 'Edit user',
    disable: {
      part1: 'Are you sure you want to',
      active: 'activate',
      deactive: 'disable',
      activation: 'activation',
      deactivation: 'deactivation',
      part2: 'this user?',
      part3: ' This is a',
      part4: 'risk action',
      part5: ' Upon confirming the ',
      part6: ', this action',
      part7: "had direct effects on the user's use of the system",
    },
  },
  Distributor: {
    addAdmin: 'addAdmin',
    client: 'client',
    type: 'type',
    title: 'Distributors',
    noData: 'No distributor found',
    total: 'Total',
    register: 'Register',
    add: 'Register a new distributor',
    edit: 'Edit',
    creat: 'Joined:',
    orgName: 'Customer name:',
    name: 'Name',
    editDistributor: 'Edit distributor',
  },
  Invoice: {
    accessKey: 'accessKey',
    invoiceDelivered: 'invoiceDelivered',
    invoiceDispatched: 'invoiceDispatched',
    name: 'name',
    selectCarrier: 'selectCarrier',
    title: 'Invoices',
    total: 'Total:',
    noMove: 'No moves',
    aggregated: 'Linked',
    transfered: 'Custody Transfer',
    nfe: 'Invoice',
    number: 'Product amount',
    state: 'State',
    date: 'Date',
    actions: 'More options',
    linkInvoice: 'Link invoice',
    details: 'Detalhes',
    importTitle: 'Import of NF-e',
    aggregation: 'aggregate notes',
    trow: 'Drag invoices or click here',
    noMovimets: 'No moves',
    linked: 'Linked',
    transfer: 'Custody transferred',
    added: 'Added on ',
    productsAmount: 'N ° of products:',
    creat: 'Created at ',
    receiver: 'Receiver',
    locality: 'Locality',
    items: 'Items',
    ean: 'EAN',
    amount: 'Amount',
    price: 'Price',
    ncm: 'NCM',
    unity: 'Unity',
    noProduct: 'No products found.',
    driverInfo: 'Driver information',
    info: 'Operation information',
    importedSucesfully: 'Notes imported successfully.',
    deleteModal: {
      part1: 'Delete',
      part2: 'Invoice',
      part3: 'This is an',
      part4: 'risk action',
      part5: 'Are you sure you want to',
      part6: 'delete',
      part7: 'this',
      part8: 'Invoice?',
    },
    dailyProductionModal: {
      title: 'Daily production',
      text: 'Do you want to download the daily production XML file?',
    },
  },
  Products: {
    title: 'Products',
    filter: 'Filter by registration date',
    addProducts: 'Register a new product',
    productsCodes: 'Available codes',
    register: 'Register',
    noBarCode: 'No barcode',
    addImage: 'Choose a file ',
    total: 'Total: ',
    date: 'Date',
    details: 'Details',
    noData: 'No registered products.',
    noPacking: 'No packaging found.',
    name: 'Name',
    product: 'Product',
    amount: 'Amount',
    actions: 'More options',
    proportions: {
      name: 'Catalyst',
      proportion: 'Proportion',
    },
    options: {
      details: 'Details',
      codeGenerate: 'Generate Codes',
      printBox: 'Print Box',
      printPartitions: 'Print Partitions',
      partitionSolicitation: 'Request new partitions codes',
      aggregatePartition: 'Add new partitions',
    },
    info: 'Details',
    volumes: {
      drum: 'Drum',
      barrel: 'Barrel',
      bombona: 'Bombona',
      box: 'Box',
      bag: 'Bag',
      compositePackaging: 'Composite Packaging',
    },
  },
  Printers: {
    title: 'Printers',
    total: 'Total:',
    date: 'Date:',
    name: 'Name',
    port: 'URL:Port',
    actions: 'More options',
    noData: 'No printer found.',
    add: 'Add',
    addTitle: 'Add a new printer',
    select: 'Select if it is a USB printer',
    noPrinter: 'No usb printer found.',
    edit: 'Edit',
    printer: 'Printer',
    localPrinter: 'Local printer',
    delete: {
      part1: 'Are you sure you want to',
      part2: 'delete',
      part3: 'this printer?',
      part4: 'This is a',
      part5: 'risk action',
      part6: ' Upon confirming the deletion, this action',
      part7: "can't be undone",
    },
  },
  Safety: {
    title: 'Security',
    list: 'Registered roles',
    details: 'View role details',
    edit: 'Role edit',
    register: 'Register a new role',
    choosePermission: 'Choose permissions for this role',
    select: 'Choose permissions...',
    noPermission: 'No permissions found.',
  },
  Permissions: {
    nothing: 'Nothing',
    index: 'Read',
    store: 'Create',
    indexStore: 'Read and create',
    indexUpdate: 'Read and update',
    update: 'Read, update and delete',
    delete: 'Read, create, update e delete',
  },
  Settings: {
    title: 'Settings',
    subtitle: 'Update your data',
  },
  Report: {
    all: 'all',
    title: 'Report',
    dateInit: 'Start date',
    dateEnd: 'End date',
    codeState: 'Select to generate code status report',
    report: 'Generate report',
    reportGenerate: 'Generate a report to display the data',
    pdfGenerate: 'Generate pdf',
  },
  ShippingCompanies: {
    title: 'Carriers',
    all: 'All',
    register: 'Register',
    total: 'Total: ',
    type: 'Type: ',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    date: 'Date: ',
    details: 'Details',
    noCarriers: 'No registered carriers.',
    newCarrier: 'Register a new carrier',
    company: 'Company or MEI',
    selfuser: 'Self-employed',
    edit: 'Edit',
    companyName: 'Company Name / Name',
    document: 'CNPJ/CPF',
    actions: 'More options',
  },
  Tracking: {
    title: 'Track code',
    noData: 'There were no recent moves.',
  },
  Clients: {
    title: 'Client',
    add: 'Register',
    addClient: 'Add a new customer',
    registerRepresentative: 'Add representative',
    representative: 'Representative',
    all: 'All',
    noData: 'No registered clients',
    date: 'Date: ',
    name: 'Company name',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    edit: 'Edit',
    total: 'Total: ',
    details: 'Details',
    addRepresentative: 'Add representative',
    editRepresentative: 'Edit representative',
    noRepresentative: 'No added',
    editClient: 'Edit client',
    activeClient: 'Activate client',
    deactiveClient: 'Deactivate client',
  },
  Employee: {
    title: 'Employee',
    register: 'Register',
    noData: 'No employee found',
    add: 'Register a new employee',
    editEmployee: 'Edit employee',
    activeEmployee: 'Active employee',
    deactiveEmployee: 'Deactive employee',
  },
  BackEndErrors: {
    // module users errors
    you_do_not_have_permission_to_enable_or_disable_a_user:
      'You are not allowed to enable or disable an user.',
    the_company_must_be_activated_in_order_to_register_a_user:
      'The company must be activated to register an user.',
    email_already_registered: 'Email has already been registered.',
    you_have_exceeded_the_limit_of_active_users_upgrade_your_plan_to_register_more:
      'You have exceeded the limit of active users, update your plan to register more.',
    'The company has exceeded the activate users limit. Please, upgrade your plan.':
      'The company has exceeded the limit of active users. Please update your plan.',
    user_with_this_email_already_registered:
      'User with this email already exists.',
    user_not_found: 'User not found.',
    'You cannot change your password without informing your old password.':
      'You cannot change your password without informing your old password.',
    incorrect_password: 'Incorrect password.',
    token_already_used: 'Token already used.',
    token_expired: 'Token expired.',
    token_not_found: 'Token not found.',
    user_disabled: 'User disabled.',
    // company module
    company_not_found: 'Company not found.',
    // solicitation module
    amount_not_available: 'Quantity not available.',
    this_code_is_already_aggregated_to_an_invoice:
      'This code has already been added to an invoice.',
    code_not_found: 'Code not found.',
    invalid_code: 'Invalid code',
    the_code_does_not_belong_to_a_volume:
      'The code does not belong to a volume.',
    solicitation_not_found: 'Request not found',

    'Only products solicitations allowed':
      'Only products solicitations allowed',
    'The company must be activated in order to register an user.':
      'The company must be activated in order to register an user.',
    'You do not have permission to enable or disable an user.':
      'You do not have permission to enable or disable an user.',
    'Your license expired. Please, upgrade your plan to get access again.':
      'Your license expired. Please, upgrade your plan to get access again.',
    the_company_exceeded_the_activate_users_limit_please_upgrade_your_plan:
      'the_company_exceeded_the_activate_users_limit_please_upgrade_your_plan',
    you_cant_change_your_password_without_inform_your_old_password:
      'you_cant_change_your_password_without_inform_your_old_password',

    'Insufficient partition codes.': 'Insufficient partition codes.',
    'Insufficient volume codes.': 'Insufficient volume codes.',
    'Insufficient product codes.': 'Insufficient product codes.',
    'Internal server error.': 'Internal server error',
    'Your license expired. Please upgrade your plan to get access again.':
      'Your license has expired, please update your access plan.',
    'Your license offline time expired. Please, connect you server in internet to get access again.':
      'Your offline license has expired, please connect to the internet to gain access again.',
    'Access denied.': 'Access denied.',
    'Validation error.': 'Validation error.',
    'No invoice aggregate with this code.':
      'There is no invoice linked to this code.',
    'It is not possible to delete an invoice already aggregated.':
      'You cannot delete an invoice already aggregated.',
    'Invoice not found.': 'Invoice not found.',
    'Printer not found': 'Printer not found.',
    'A product with this PCE has already been registered.':
      'A product with this PCE has already been registered.',
    'A product with this EAN-13 has already been registered.':
      'A product with this ean is already registered.',
    'Product not found.': 'Product not found.',
    'Permission not found.': 'Permission not found.',
    'A rule with this name has already been created.':
      'A rule with this name has already been created.',
    'There is already a shipping company with this CNPJ.':
      'There is already a carrier with this CNPJ.',
    'There is already a shipping company with this CPF.':
      'There is already a carrier with this CPF.',
    'Shipping company not found.': 'Carrier not found.',
    'Codes not found.': 'Codes not found.',
    'Only products requests allowed': 'Only products requests are allowed.',
    'Solicitation already printed.': 'Request already printed.',
    'Solicitation not printed.': 'Request not printed.',
    'A volume with this abbreviation has already been created.':
      'A volume with this abbreviation has already been created.',
    'Volume not found.': 'Volume not found.',
    'A volume with this has already been created.':
      'A volume with this DUN-14 has already been created.',
    'No files uploaded': 'The files were not uploaded.',
    'Unknown permission.': 'Unknown permission.',
    'This invoice was already transferred.':
      'This invoice has already been transferred.',
    'Old password is incorrect.': 'Incorrect password.',
    code_not_active: 'Code not activated',
    code_not_print: 'Code not printed',
    no_event_found: 'Event not found',
    code_already_active: 'Code already active',
    code_already_active_aggregation: 'Code already active for aggregation',
    code_not_already_active_aggregation: 'Code not active for aggregation',
    code_does_not_belong_aggregation: 'Code does not belong to the aggregation',
    code_is_not_valid: 'Code does not exist',
    code_already_marked: 'Code already printed',
  },
  Modal: {
    unBlock: 'unBlock',
    userFilter: 'Filter users',
    userOrder: 'Sort users',
    nameOrder: 'Alphabetical order',
    mailOrder: 'Sort by email',
    dateFilter: 'Filter by date:',
    registerFilter: 'Filter by registration date',
  },
  PrintTest: {
    title: 'Product printing test',
    text: 'Label printing',
  },
  sideBar: {
    licenQrCode: 'QRCode de. Bachelor',
    moviments: 'Move code',
    activation: 'Events in production',
    aggregationAndEvents: 'Aggregation and events in production',
    tracking: 'Track code',
    status: 'Status',
    statusList: 'List all',
    statusAdd: 'Add new',
    interaction: 'Interaction',
    config: 'Configuration',
    detailedTotalizado: 'Totalized report',
    detailedReport: 'Detailed report',
    reducedReport: 'Reduced report',
    heatMap: 'Heat map',
    distributorFuelsAdd: 'Add new',
    volumes: 'Volumes',
    volumesList: 'List all',
    volumesAdd: 'Add new',
    testPrint: 'Print test',
    shippingCompanies: 'Carriers',
    shippingCompaniesList: 'List all',
    shippingCompaniesAdd: 'Register',
    invoices: 'Invoices',
    invoicesList: 'List all',
    invoicesAdd: 'Import',
    printers: 'Printers',
    printersList: 'List all',
    printersAdd: 'Add',
    report: 'Report',
    users: 'Users',
    usersList: 'List all users',
    usersAdd: 'Register',
    distributors: 'Distributors',
    distributorsList: 'List all',
    distributorsAdd: 'Register',
    company: 'Company',
    security: 'Security',
    settings: 'Settings',
    clients: 'Clients',
    clientsAdd: 'Register clients',
    clientsList: 'List customer users',
    employee: 'Employees',
    employeesAdd: 'Register',
    employeesList: 'List all',
    subsidiaryStock: 'Stock Control',
    gasStations: 'Gas Stations',
    resources: 'Resources',
    resourcesAdd: 'Add Resources',
    resourcesList: 'Resource List',
    resourcesEdit: 'resource editing',
    resourceField: 'Resource Field',
    clientList: 'List all',
    clientAdd: 'Add new',
    codes: 'Codes',
    due: 'Due',
    inspection: 'Inspection',
    add: 'Add new',
    list: 'List all',
    locations: 'Locations',
    packing: 'Packing',
    entrance: 'Entrance',
    events: 'Events',
    ImportInvoiceCodesDetails: 'Invoice Details',
    ImportInvoiceCodes: 'Import codes by invoice',
    rolls: 'Code generator',
  },
  Success: {
    userUpdate: 'userUpdate',
    fuelTypeAdd: 'Fuel type added successfully!',
    distributorFuelsAdd: 'Fuel added successfully!',
    companyUpdate: 'Company updated successfully!',
    completeSearch: 'Search done successfully',
    registerUser: 'User successfully registered.',
    invoiceLinked: 'Aggregate invoice',
    invoiceTransfered:
      'Custody of the invoice transferred to the license plate vehicle',
    invoiceDelete: 'Invoice deleted.',
    moveError: 'An error occurred while making the move.',
    moveSuccess: 'Move done.',
    printDone: 'Print done.',
    error: 'Error',
    inCode: 'in code',
    rePrintDone: 'Reprint done.',
    codesError: 'Error in the following codes: ',
    printerCreated: 'Printer created.',
    questionCreated: 'Question created.',
    printerUpdate: 'Printer updated.',
    printerDelete: 'Printer deleted.',
    questionDelete: 'Question deleted.',
    productAdd: 'Product added.',
    productUpdate: 'Product updated.',
    newOcupation: 'Function created.',
    updateRole: 'Rules updated successfully.',
    carrierRegister: 'Registered carrier.',
    carrierUpdate: 'Carrier updated.',
    solicitationDone: 'Request made.',
    aggregationDone: 'Aggregation done.',
    successUpdate: 'Data updated successfully.',
    successRegister: 'Successful registration',
    recoveryEmail: 'A password recovery link has been sent to your email.',
    passwordRecovery: 'Password recovered.',
    activeLink: 'An activation link has been sent to your email.',
    registerActive: 'Account activated successfully.',
    volumeAdd: 'Volume added.',
    volumeUpdate: 'Volume updated.',
    roles: {
      nothing: 'None',
      index: 'Read',
      store: 'Read and create',
      indexUpdate: 'Read and update',
      update: 'Read, update and delete',
      delete: 'Read, create, update and delete',
    },
    createClient: 'Client created.',
    updateClient: 'Cliente updated.',
    createRepresentative: 'Representative created.',
    updateRepresentative: 'Representative updated.',
  },
};

export default locale;
