import { all, call, put, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import { PageDTO } from '@/dtos/generics';
import { LicensedCodeDTO } from '@/dtos/licensedCodes';
import licensedCodesProvider from '@/lib/licensedCodes';
import history from '@/services/history';

import LicensedCodesActions, { LicensedCodesTypes } from './duck';

type ListParams = ReturnType<typeof LicensedCodesActions.listRequest>;
export function* getLicensedCodes({ payload }: ListParams): Generator {
  try {
    const data = yield call(licensedCodesProvider.list, payload);
    yield put(
      LicensedCodesActions.listSuccess(data as PageDTO<LicensedCodeDTO>)
    );
  } catch (err) {
    const error = handleError(err);
    yield put(LicensedCodesActions.listError(error));
  }
}

type GetParams = ReturnType<typeof LicensedCodesActions.getRequest>;
export function* detail({ payload }: GetParams): Generator {
  try {
    const data = yield call(licensedCodesProvider.getById, payload);
    yield put(LicensedCodesActions.getSuccess(data as LicensedCodeDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(LicensedCodesActions.getError(error));
  }
}

type AddParams = ReturnType<typeof LicensedCodesActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(licensedCodesProvider.create, payload);
    yield put(LicensedCodesActions.addSuccess());
    history.push('/licenQrCode');
  } catch (err) {
    const error = handleError(err);
    yield put(LicensedCodesActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof LicensedCodesActions.addUpdate>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(licensedCodesProvider.update, payload);
    yield put(LicensedCodesActions.addSuccess());
  } catch (err) {
    const error = handleError(err);
    yield put(LicensedCodesActions.addError(error));
  }
}

export default all([
  takeLatest(LicensedCodesTypes.LIST_REQUEST, getLicensedCodes),
  takeLatest(LicensedCodesTypes.GET_REQUEST, detail),
  takeLatest(LicensedCodesTypes.ADD_REQUEST, add),
  takeLatest(LicensedCodesTypes.ADD_UPDATE, update),
]);
