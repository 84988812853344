import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

type MenuProps = {
  show: boolean;
};
export const Menu = styled.div<MenuProps>`
  position: absolute;
  top: ${({ theme }) => theme.sizes.header};
  right: 0;
  display: ${({ show }) => (show ? 'grid' : 'none')};
  width: max-content;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  row-gap: 10px;
  margin-top: 6px;
  padding: 15px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => `${theme.sizes.shadow} ${theme.colors.shadow}`};
  animation: ${slideUp} 0.1s ease-in-out;
  z-index: 999;
`;
