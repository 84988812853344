import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import { store, persistor } from '@/store';

import GlobalStyle from '@/styles/GlobalStyle';
import theme from '@/styles/theme';
import Loader from '@/components/Loader';
import Routes from './routes';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ToastContainer
      enableMultiContainer
      autoClose={3000}
      position="top-right"
    />
    <ToastContainer
      enableMultiContainer
      containerId="permission"
      autoClose={3000}
      position="top-right"
      limit={1}
    />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

export default App;
