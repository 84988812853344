import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { RoleDTO, AddRoleDTO, UpdateRoleDTO } from '@/dtos/roles';
import PageDTO from '@/dtos/generics/PageDTO';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { RolesState } from './types';

export enum RolesTypes {
  GET_ROLES_REQUEST = '@roles/getRolesRequest',
  GET_ROLES_SUCCESS = '@roles/getRolesSuccess',
  GET_ROLES_ERROR = '@roles/getRolesError',
  GET_ROLE_REQUEST = '@roles/getRoleRequest',
  GET_ROLE_SUCCESS = '@roles/getRoleSuccess',
  GET_ROLE_ERROR = '@roles/getRoleError',
  ADD_ROLE_REQUEST = '@roles/addRoleRequest',
  ADD_ROLE_SUCCESS = '@roles/addRolesSuccess',
  ADD_ROLE_ERROR = '@roles/addRolesError',
  UPDATE_ROLES_REQUEST = '@roles/updateRolesRequest',
  UPDATE_ROLES_SUCCESS = '@roles/updateRolesSuccess',
  UPDATE_ROLES_ERROR = '@roles/updateRolesError',
  CLEAR_SELECTED = '@roles/clearSelected',
}
const RolesActions = {
  getRolesRequest: () => action(RolesTypes.GET_ROLES_REQUEST),
  getRolesSuccess: (data: PageDTO<RoleDTO>) =>
    action(RolesTypes.GET_ROLES_SUCCESS, data),
  getRolesError: (error: string) =>
    action(RolesTypes.GET_ROLES_ERROR, { error }),
  getRoleRequest: (id: string) => action(RolesTypes.GET_ROLE_REQUEST, { id }),
  getRoleSuccess: (data: RoleDTO) =>
    action(RolesTypes.GET_ROLE_SUCCESS, { data }),
  getRoleError: (error: string) => action(RolesTypes.GET_ROLE_ERROR, { error }),
  addRoleRequest: (data: AddRoleDTO) =>
    action(RolesTypes.ADD_ROLE_REQUEST, { data }),
  addRoleSuccess: () => action(RolesTypes.ADD_ROLE_SUCCESS),
  addRoleError: (error: string) => action(RolesTypes.ADD_ROLE_ERROR, { error }),
  updateRolesRequest: (data: UpdateRoleDTO) =>
    action(RolesTypes.UPDATE_ROLES_REQUEST, { data }),
  updateRolesSuccess: (data: RoleDTO) =>
    action(RolesTypes.UPDATE_ROLES_SUCCESS, { data }),
  updateRolesError: (error: string) =>
    action(RolesTypes.UPDATE_ROLES_ERROR, { error }),
  clearSelected: () => action(RolesTypes.CLEAR_SELECTED),
};
export default RolesActions;

const INITIAL_STATE: RolesState = {
  data: [],
  selected: {
    id: '',
    name: '',
    type: '',
    permissions: [],
  },
  loading: false,
  error: '',
};

type RolesReducer<Action extends AnyAction> = Reducer<RolesState, Action>;

const getRolesSuccess: RolesReducer<
  ReturnType<typeof RolesActions.getRolesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  data: payload.data,
});

const getRoleSuccess: RolesReducer<
  ReturnType<typeof RolesActions.getRoleSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload.data,
  loading: false,
});

const updateRolesSuccess: RolesReducer<
  ReturnType<typeof RolesActions.updateRolesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload.data,
  error: '',
  loading: false,
});

const clearSelected: RolesReducer<any> = (state = INITIAL_STATE) => ({
  ...state,
  selected: INITIAL_STATE.selected,
});

export const reducer = createReducer<RolesState>(INITIAL_STATE)
  .handleAction(RolesTypes.GET_ROLES_REQUEST, genericRequest)
  .handleAction(RolesTypes.GET_ROLES_SUCCESS, getRolesSuccess)
  .handleAction(RolesTypes.GET_ROLES_ERROR, genericError)
  .handleAction(RolesTypes.GET_ROLE_REQUEST, genericRequest)
  .handleAction(RolesTypes.GET_ROLE_SUCCESS, getRoleSuccess)
  .handleAction(RolesTypes.GET_ROLE_ERROR, genericError)
  .handleAction(RolesTypes.ADD_ROLE_REQUEST, genericRequest)
  .handleAction(RolesTypes.ADD_ROLE_SUCCESS, genericSuccess)
  .handleAction(RolesTypes.ADD_ROLE_ERROR, genericError)
  .handleAction(RolesTypes.UPDATE_ROLES_REQUEST, genericRequest)
  .handleAction(RolesTypes.UPDATE_ROLES_SUCCESS, updateRolesSuccess)
  .handleAction(RolesTypes.UPDATE_ROLES_ERROR, genericError)
  .handleAction(RolesTypes.CLEAR_SELECTED, clearSelected);
