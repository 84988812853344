import React, { useRef } from 'react';
import { useTheme } from 'styled-components';

import { useClickOutside } from '@/hooks/useClickOutside';

import { Icon, Title } from '@/components';
import { ModalProps } from './types';
import { Container, Content, TitleContainer, CloseIcon } from './styles';

const Modal: React.FC<ModalProps> = ({ title, children, isOpen, setOpen }) => {
  const theme = useTheme();
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setOpen(false));

  if (!isOpen) return <></>;

  return (
    <Container open={isOpen}>
      <Content ref={modalRef}>
        <TitleContainer>
          <CloseIcon onClick={() => setOpen(!isOpen)}>
            <Icon
              name="x"
              size={22}
              colorOnHover={theme.colors.primary.base}
              title="close"
            />
          </CloseIcon>
          <Title>{title}</Title>
        </TitleContainer>
        {children}
      </Content>
    </Container>
  );
};

export default Modal;
