import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ApplicationState } from '@/store/types';
import checkPermission from '@/services/auth';

interface RouteParams extends ReactDOMRouteProps {
  component: React.ComponentType;
  permission?: string;
  type?: string[];
}

const Route: React.FC<RouteParams> = ({
  component: Component,
  permission,
  type,
  ...rest
}) => {
  const { t: translation } = useTranslation();
  const { role, type: userType } = useSelector(
    (store: ApplicationState) => store.user.data
  );
  const toastOption = { containerId: 'permission' };

  const noHavePermission =
    permission &&
    !checkPermission({
      permissions: role.permissions,
      permissionsVerify: permission,
    });
  const haveDiffType = !!(type && type.length && !type.includes(userType));
  if (noHavePermission || haveDiffType) {
    toast.error(translation('error.validation.access_denied'), toastOption);
    toast.clearWaitingQueue(toastOption);
    return <Redirect to="/" />;
  }

  return <ReactDOMRoute {...rest} component={Component} />;
};

export default Route;
