import styled from 'styled-components';

export default styled.button`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  background-color: transparent;
  font-size: ${(props) => props.theme.sizes.text};
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;

  &:focus,
  &:hover {
    outline: none;
  }
`;
