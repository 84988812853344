import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { SidebarState, SideBarItem } from './types';

export enum SidebarTypes {
  SET_OPEN_ON_MOBILE = '@sidebar/setOpenOnMobile',
  SET_MENU_ITEM = '@sidebar/setMenuItem',
  SET_ITEMS = '@sidebar/setItems',
}
const SidebarActions = {
  setOpenOnMobile: (open: boolean) =>
    action(SidebarTypes.SET_OPEN_ON_MOBILE, { open }),
  setMenuItem: (itemKey: string, parentItemKey?: string) =>
    action(SidebarTypes.SET_MENU_ITEM, { itemKey, parentItemKey }),
};
export default SidebarActions;

const INITIAL_STATE: SidebarState = {
  items: [
    {
      key: 'event',
      icon: 'layers',
      label: 'sideBar.moviments',
      to: '/events/add',
      permission: 'store:event',
      active: false,
    },
    /*
    modulos de ativação e agregação em produção desativados temporariamente
    {
      key: 'activation',
      icon: 'plus',
      label: 'sideBar.activation',
      to: '/activation/add',
      permission: 'index:event',
      active: false,
    },
    {
      key: 'AggregationAndEvents',
      icon: 'package',
      label: 'sideBar.aggregationAndEvents',
      to: '/aggregationAndEvents/add',
      permission: 'index:event',
      active: false,
    },
    */
    {
      key: 'interaction',
      icon: 'file-plus',
      label: 'sideBar.interaction',
      active: false,
      items: [
        {
          key: 'interactionConfig',
          label: 'sideBar.config',
          to: '/interaction/config',
          permission: 'store:interactions',
          active: false,
        },
        {
          key: 'interactionReducedReport',
          label: 'sideBar.reducedReport',
          to: '/interaction/reduced-report',
          permission: 'index:reducedReport',
          active: false,
        },
        {
          key: 'interactionTotalizedReport',
          label: 'sideBar.detailedTotalizado',
          to: '/interaction/total-report',
          permission: 'index:totalReport',
          active: false,
        },
        {
          key: 'interactionDetailedReport',
          label: 'sideBar.detailedReport',
          to: '/interaction/detailed-report',
          permission: 'index:detailedReport',
          active: false,
        },
        {
          key: 'interactionHeatMap',
          label: 'sideBar.heatMap',
          to: '/interaction/heatMap-report',
          permission: 'index:heatMapReport',
          active: false,
        },
      ],
    },
    {
      key: 'licenQrCode',
      icon: 'file-plus',
      label: 'sideBar.licenQrCode',
      active: false,
      items: [
        {
          key: 'rollsList',
          label: 'sideBar.list',
          to: '/licenQrCode',
          permission: 'index:licenQrCode',
          active: false,
        },
        {
          key: 'rollsAdd',
          label: 'sideBar.add',
          to: '/licenQrCode/add',
          permission: 'store:licenQrCode',
          active: false,
        },
      ],
    },
    {
      key: 'rolls',
      icon: 'file-plus',
      label: 'sideBar.rolls',
      active: false,
      items: [
        {
          key: 'rollsList',
          label: 'sideBar.list',
          to: '/rolls',
          permission: 'index:roll',
          active: false,
        },
        {
          key: 'rollsAdd',
          label: 'sideBar.add',
          to: '/rolls/add',
          permission: 'store:roll',
          active: false,
        },
      ],
    },
    {
      key: 'packing',
      icon: 'codesandbox',
      label: 'sideBar.packing',
      to: '/packing',
      permission: 'store:packing',
      active: false,
    },
    {
      key: 'entrance',
      icon: 'check-square',
      label: 'sideBar.entrance',
      permission: 'store:entrance',
      to: '/entrance',
      active: false,
    },
    {
      key: 'tracking',
      icon: 'map',
      label: 'sideBar.tracking',
      to: '/tracking',
      permission: 'index:tracking',
      active: false,
    },
    {
      key: 'users',
      icon: 'users',
      label: 'sideBar.users',
      active: false,
      items: [
        {
          key: 'usersList',
          label: 'sideBar.list',
          to: '/users',
          permission: 'index:user',
          active: false,
        },
        {
          key: 'usersAdd',
          label: 'sideBar.add',
          to: '/users/add',
          permission: 'store:user',
          active: false,
        },
      ],
    },
    {
      key: 'companies',
      icon: 'briefcase',
      label: 'page.companies.headerTitle',
      active: false,
      items: [
        {
          key: 'companiesList',
          label: 'sideBar.list',
          to: '/companies',
          permission: 'index:company',
          feature: 'index_company',
          active: false,
        },
        {
          key: 'companiesAdd',
          label: 'sideBar.add',
          to: '/companies/add',
          permission: 'store:company',
          feature: 'store_company',
          active: false,
        },
      ],
    },
    {
      key: 'plans',
      icon: 'book-open',
      label: 'page.plans.headerTitle',
      active: false,
      items: [
        {
          key: 'plansList',
          label: 'sideBar.list',
          to: '/plans',
          permission: 'index:plan',
          feature: 'index_plan',
          active: false,
        },
        {
          key: 'plansAdd',
          label: 'sideBar.add',
          to: '/plans/add',
          permission: 'store:plan',
          feature: 'store_plan',
          active: false,
        },
      ],
    },
    {
      key: 'resources',
      icon: 'shopping-bag',
      label: 'sideBar.resources',
      active: false,
      items: [
        {
          key: 'resourcesList',
          label: 'sideBar.resourcesList',
          to: '/resources',
          permission: 'index:resource',
          active: false,
        },
        {
          key: 'resourcesAdd',
          label: 'sideBar.resourcesAdd',
          to: '/resources/add',
          permission: 'store:resource',
          active: false,
        },
      ],
    },
    {
      key: 'resourceField',
      icon: 'shopping-bag',
      label: 'sideBar.resourceField',
      active: false,
      items: [
        {
          key: 'resourceFieldAdd',
          label: 'sideBar.add',
          to: '/resource-fields/add',
          permission: 'store:resourceField',
          active: false,
        },
        {
          key: 'resourceFieldList',
          label: 'sideBar.list',
          to: '/resource-fields',
          permission: 'index:resourceField',
          active: false,
        },
      ],
    },
    {
      key: 'locations',
      icon: 'map-pin',
      label: 'sideBar.locations',
      active: false,
      items: [
        {
          key: 'locationsList',
          label: 'sideBar.list',
          to: '/locations',
          permission: 'index:location',
          active: false,
        },
        {
          key: 'locationsAdd',
          label: 'sideBar.add',
          to: '/locations/add',
          permission: 'store:location',
          active: false,
        },
      ],
    },
    {
      key: 'printers',
      icon: 'printer',
      label: 'sideBar.printers',
      permission: '',
      active: false,
      items: [
        {
          key: 'printersList',
          label: 'sideBar.list',
          to: '/printers',
          permission: 'index:printer',
          active: false,
        },
        {
          key: 'printersAdd',
          label: 'sideBar.add',
          to: '/printers/add',
          permission: 'store:printer',
          active: false,
        },
      ],
    },
    {
      key: 'codes',
      icon: 'code',
      label: 'sideBar.codes',
      permission: '',
      active: false,
      items: [
        {
          key: 'codesList',
          label: 'sideBar.list',
          to: '/codes',
          permission: 'index:code',
          active: false,
        },
      ],
    },
    {
      key: 'eventList',
      icon: 'clipboard',
      label: 'sideBar.events',
      permission: '',
      active: false,
      items: [
        {
          key: 'eventsList',
          label: 'sideBar.list',
          to: '/events',
          permission: 'index:event',
          active: false,
        },
      ],
    },
    {
      key: 'locationPositions',
      icon: 'navigation-2',
      label: 'page.locationsPositions.headerTitle',
      to: '/location-positions/add',
      permission: '',
      active: false,
      items: [
        {
          key: 'locationPositionsAdd',
          label: 'sideBar.add',
          to: '/location-positions/add',
          permission: 'store:locationPosition',
          active: false,
        },
        {
          key: 'locationPositionsList',
          label: 'page.locationsPositions.List.headerTitle',
          to: '/location-positions',
          permission: 'index:locationPosition',
          active: false,
        },
      ],
    },
    {
      key: 'status',
      icon: 'columns',
      label: 'sideBar.status',
      active: false,
      items: [
        {
          key: 'statusList',
          label: 'sideBar.statusList',
          to: '/status',
          permission: 'index:status',
          active: false,
        },
        {
          key: 'statusAdd',
          label: 'sideBar.statusAdd',
          to: '/status/add',
          permission: 'store:status',
          active: false,
        },
      ],
    },

    {
      key: 'statusField',
      icon: 'shopping-bag',
      label: 'page.statusFields.headerTitle',
      active: false,
      items: [
        {
          key: 'statusFieldList',
          label: 'sideBar.list',
          to: '/status-fields',
          permission: 'index:status',
          active: false,
        },
        {
          key: 'statusFieldAdd',
          label: 'sideBar.add',
          to: '/status-fields/add',
          permission: 'store:status',
          active: false,
        },
      ],
    },
    {
      key: 'invoices',
      icon: 'file-text',
      label: 'sideBar.invoices',
      active: false,
      items: [
        {
          key: 'invoicesList',
          label: 'sideBar.invoicesList',
          to: '/invoices',
          permission: 'index:invoice',
          feature: 'index_invoice',
          active: false,
        },
        {
          key: 'invoicesAdd',
          label: 'sideBar.invoicesAdd',
          to: '/invoices/import',
          permission: 'store:invoice',
          feature: 'store_invoice',
          active: false,
        },
      ],
    },
    {
      key: 'security',
      icon: 'shield',
      label: 'sideBar.security',
      to: '/security',
      permission: 'store:role',
      active: false,
    },
    {
      key: 'settings',
      icon: 'settings',
      label: 'sideBar.settings',
      to: '/settings',
      permission: '',
      active: false,
    },
  ],
  openOnMobile: false,
  loading: false,
  error: '',
};

export function checkMenuItems(
  items: SideBarItem[],
  checkPermission: (permission: string) => boolean,
  checkFeature: (feature: string) => boolean
): SideBarItem[] {
  return items.reduce((accumulator: SideBarItem[], menuItem: SideBarItem) => {
    const notHavePermission =
      menuItem.permission && !checkPermission(menuItem.permission);
    if (notHavePermission) return accumulator;
    const notHaveFeature = menuItem.feature && !checkFeature(menuItem.feature);
    if (notHaveFeature) return accumulator;
    if (!menuItem.items) return [...accumulator, menuItem];
    if (!checkMenuItems(menuItem.items, checkPermission, checkFeature).length)
      return accumulator;

    return [
      ...accumulator,
      {
        ...menuItem,
        items: checkMenuItems(menuItem.items, checkPermission, checkFeature),
      },
    ];
  }, [] as SideBarItem[]);
}

type SidebarReducer<Action extends AnyAction> = Reducer<SidebarState, Action>;

const setMenuItem: SidebarReducer<
  ReturnType<typeof SidebarActions.setMenuItem>
> = (state = INITIAL_STATE, { payload }) => {
  const key = payload.parentItemKey || payload.itemKey;
  return {
    ...state,
    items: state.items.map((item) => ({
      ...item,
      active: key === item.key,
      items: item?.items?.map((subItem) => ({
        ...subItem,
        active: subItem.key === payload.itemKey,
      })),
    })),
  };
};

const setOpenOnMobile: SidebarReducer<
  ReturnType<typeof SidebarActions.setOpenOnMobile>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  openOnMobile: payload.open,
  loading: false,
  error: '',
});

export const reducer = createReducer(INITIAL_STATE)
  .handleAction(SidebarTypes.SET_OPEN_ON_MOBILE, setOpenOnMobile)
  .handleAction(SidebarTypes.SET_MENU_ITEM, setMenuItem);
