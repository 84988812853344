import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import UserActions from '@/store/modules/user/duck';

import Icon from '@/components/Icon';
import { Text } from '@/components/Text';
import { Menu, Option } from './styles';

export type SettingsMenuParams = {
  isSettingsMenuOpen: boolean;
  setSettingsMenuOpen: (isOpen: boolean) => void;
  settingsMenuRef: React.Ref<HTMLDivElement>;
};
const SettingsMenu: React.FC<SettingsMenuParams> = ({
  isSettingsMenuOpen,
  setSettingsMenuOpen,
  settingsMenuRef,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const logout = (): void => {
    setSettingsMenuOpen(false);
    dispatch(UserActions.logoutRequest());
  };

  return (
    <Menu show={isSettingsMenuOpen} ref={settingsMenuRef}>
      <Option as={Link} to="/settings">
        <Icon
          name="settings"
          size={18}
          title="settings"
          color={theme.colors.primary.base}
        />
        <Text title={translation('Navbar.settings')}>
          {translation('Navbar.settings')}
        </Text>
      </Option>
      <Option onClick={logout}>
        <Icon
          name="log-out"
          size={18}
          title="logout"
          color={theme.colors.primary.base}
        />
        <Text title={translation('Navbar.exit')}>
          {translation('Navbar.exit')}
        </Text>
      </Option>
    </Menu>
  );
};

export default SettingsMenu;
