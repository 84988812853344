import React, { forwardRef, TextareaHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Container, FieldArea, Label } from './styles';

export interface InputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  id?: string;
  error?: boolean;
  toClipboard?: boolean;
}
const Input: React.ForwardRefRenderFunction<HTMLTextAreaElement, InputProps> = (
  { label, id: identify, error, ...rest },
  ref
) => {
  const id = identify || uuid();
  const { t: translation } = useTranslation();

  return (
    <Container htmlFor={id}>
      {label && <Label>{translation(`input.${label}`)}</Label>}
      <FieldArea id={id} {...rest} ref={ref} error={error} placeholder="" />
    </Container>
  );
};

export default forwardRef(Input);
