import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import AddEventDTO from '@/dtos/events/AddEventDTO';
import { PageDTO } from '@/dtos/generics';
import EventDTO from '@/dtos/events/EventDTO';
import FindEventDTO from '@/dtos/events/FindEventDTO';
import SearchQuery from '@/utils/SearchQuery';
import CreateEntranceDTO from '@/dtos/events/CreateEntranceDTO';

import { EventsState } from './types';

export enum EventsTypes {
  ADD_EVENT_REQUEST = '@events/addEventRequest',
  ADD_EVENT_SUCCESS = '@events/addEventSuccess',
  ADD_EVENT_ERROR = '@events/addEventError',
  SET_FILTERS = '@events/SET_FILTERS',
  GET_BY_CODE_REQUEST = '@events/getByCodeRequest',
  GET_BY_CODE_SUCCESS = '@events/getByCodeSuccess',
  GET_BY_CODE_ERROR = '@events/getByCodeError',
  LIST_REQUEST = '@events/listRequest',
  LIST_SUCCESS = '@events/listSuccess',
  LIST_ERROR = '@events/listError',
  ENTRANCE_REQUEST = '@events/entranceRequest',
  ENTRANCE_SUCCESS = '@events/entranceSuccess',
  ENTRANCE_ERROR = '@events/entranceError',
  RE_PRINT_EVENT_REQUEST = '@events/rePrintEventRequest',
  PRINT_EVENT_REQUEST = '@events/printEventRequest',
  PRINT_EVENT_SUCCESS = '@events/printEventSuccess',
  PRINT_EVENT_ERROR = '@events/printEventError',
  CLEAR_ERRORS = '@events/clearErrors',
  CLEAR = '@events/clear',
}
const EventsActions = {
  addEventRequest: (data: AddEventDTO[], codes: string[]) =>
    action(EventsTypes.ADD_EVENT_REQUEST, { data, codes }),
  addEventSuccess: () => action(EventsTypes.ADD_EVENT_SUCCESS),
  addEventError: (errors: string[]) =>
    action(EventsTypes.ADD_EVENT_ERROR, { errors }),
  setFilters: (filters: SearchQuery) =>
    action(EventsTypes.SET_FILTERS, { filters }),
  getByCodeRequest: (params: FindEventDTO) =>
    action(EventsTypes.GET_BY_CODE_REQUEST, params),
  getByCodeSuccess: (page: PageDTO<EventDTO>) =>
    action(EventsTypes.LIST_SUCCESS, page),
  getByCodeError: (error: string) =>
    action(EventsTypes.GET_BY_CODE_ERROR, { error }),
  listRequest: (params: SearchQuery) =>
    action(EventsTypes.LIST_REQUEST, params),
  listSuccess: (page: PageDTO<EventDTO>) =>
    action(EventsTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(EventsTypes.LIST_ERROR, { error }),
  entranceRequest: (params: CreateEntranceDTO) =>
    action(EventsTypes.ENTRANCE_REQUEST, params),
  entranceSuccess: () => action(EventsTypes.ENTRANCE_SUCCESS),
  entranceError: (errors: string[]) =>
    action(EventsTypes.ENTRANCE_ERROR, { errors }),
  printEventRequest: (data: AddEventDTO[], resetForm: () => void) =>
    action(EventsTypes.PRINT_EVENT_REQUEST, { data, resetForm }),
  rePrintEventRequest: (data: AddEventDTO[]) =>
    action(EventsTypes.RE_PRINT_EVENT_REQUEST, { data }),
  printEventSuccess: () => action(EventsTypes.PRINT_EVENT_SUCCESS),
  printEventError: (errors: string[]) =>
    action(EventsTypes.PRINT_EVENT_ERROR, { errors }),
  clearErrors: () => action(EventsTypes.CLEAR_ERRORS),
  clear: () => action(EventsTypes.CLEAR),
};
export default EventsActions;

const INITIAL_STATE: EventsState = {
  data: [],
  loading: false,
  filters: SearchQuery.build(),
  next: undefined,
  prev: undefined,
  total: 0,
  errors: [],
};

type EventsReducer<Action extends AnyAction> = Reducer<EventsState, Action>;

const genericSuccess: EventsReducer<any> = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  errors: [],
});

const genericRequest: EventsReducer<any> = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const genericError: EventsReducer<
  ReturnType<typeof EventsActions.addEventError>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  errors: payload.errors,
});

const listSuccess: EventsReducer<
  ReturnType<typeof EventsActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const setFilters: EventsReducer<ReturnType<typeof EventsActions.setFilters>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  filters: payload.filters,
});

const clear: EventsReducer<ReturnType<typeof EventsActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<EventsState>(INITIAL_STATE)
  .handleAction(EventsTypes.ADD_EVENT_REQUEST, genericRequest)
  .handleAction(EventsTypes.ADD_EVENT_SUCCESS, genericSuccess)
  .handleAction(EventsTypes.ADD_EVENT_ERROR, genericError)
  .handleAction(EventsTypes.SET_FILTERS, setFilters)
  .handleAction(EventsTypes.GET_BY_CODE_REQUEST, genericRequest)
  .handleAction(EventsTypes.GET_BY_CODE_SUCCESS, listSuccess)
  .handleAction(EventsTypes.GET_BY_CODE_ERROR, genericError)
  .handleAction(EventsTypes.LIST_REQUEST, genericRequest)
  .handleAction(EventsTypes.LIST_SUCCESS, listSuccess)
  .handleAction(EventsTypes.LIST_ERROR, genericError)
  .handleAction(EventsTypes.ENTRANCE_REQUEST, genericRequest)
  .handleAction(EventsTypes.ENTRANCE_SUCCESS, genericSuccess)
  .handleAction(EventsTypes.ENTRANCE_ERROR, genericError)
  .handleAction(EventsTypes.CLEAR_ERRORS, genericSuccess)
  .handleAction(EventsTypes.PRINT_EVENT_REQUEST, genericRequest)
  .handleAction(EventsTypes.RE_PRINT_EVENT_REQUEST, genericRequest)
  .handleAction(EventsTypes.CLEAR, clear);
