import api from '@/services/api';
import { CreateRollDTO, RollDTO } from '@/dtos/rolls';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';

const rollsProvider = {
  async list(filters: SearchQuery): Promise<PageDTO<RollDTO>> {
    const { data } = await api.get<PageDTO<RollDTO>>(`/rolls`, {
      params: filters.query,
    });
    return data;
  },
  async getById(id: string): Promise<RollDTO> {
    const { data } = await api.get<RollDTO>(`/rolls/${id}`);
    return data;
  },
  async getCodesByRollId(id: string): Promise<string[]> {
    const { data } = await api.get<string[]>(`/rolls/${id}/codes`);
    return data;
  },
  async create(roll: CreateRollDTO): Promise<RollDTO> {
    const { data } = await api.post<RollDTO>('/rolls', roll);
    return data;
  },
  async download(params: SearchQuery): Promise<string> {
    const { data } = await api.get<string>('/rolls/export', {
      params: params.query,
    });
    return data;
  },
};

export default rollsProvider;
