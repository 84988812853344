import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export default styled.button`
  position: fixed;
  right: 1.4rem;
  bottom: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  grid-auto-flow: column;
  column-gap: 0.6rem;
  justify-items: center;
  align-items: center;
  padding: 1rem;
  border: none;
  border-radius: 30px;
  background-color: ${(props) => props.theme.colors.primary.base};
  box-shadow: 0px 1px 8px #00000033, 0px 3px 3px #0000001f,
    0px 3px 4px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s;
  animation: ${slideLeft} 0.2s ease-in-out;
  z-index: ${(props) => props.theme.sizes.zIndexes.floatingButtons};
  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.primary.base)};
  }
`;
