import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './modules/rootDuck';
import sagas from './modules/rootSaga';

const persistConfig = {
  key: '@meta_track/company',
  whitelist: ['user'],
  storage,
};

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

const composer = composeWithDevTools(applyMiddleware(...middlewares));

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, composer);
export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
