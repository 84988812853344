import styled from 'styled-components';
import { FiArrowDown } from 'react-icons/fi';
// import ArrowDownSelect from '@/assets/images/chevron-down.svg';

export default styled.select`
  width: 100%;
  height: 2.8rem;
  padding: 0.6rem;
  background-color: #fff;
  color: #000;
  border: 1px solid ${(props) => props.theme.colors.primary.base};
  cursor: pointer;
  transition: box-shadow 0.3s;
  outline: none;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(${FiArrowDown}) no-repeat 98% center;

  &::-ms-expand {
    display: none;
  }

  &:focus,
  &.focus {
    border: 2px solid ${(props) => props.theme.colors.primary.base};
    outline: none;
  }

  &.required + label::after {
    content: ' *';
    font-weight: bold;
    color: ${(props) => props.theme.colors.error};
  }

  option {
    font-size: 14px;
    background-color: #fff;
    color: #000;
  }
`;
