/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FiCopy } from 'react-icons/fi';
import Clipboard from 'clipboard';

import { toast } from 'react-toastify';
import themes from '@/styles/theme';

import { Input } from '@/components/Form';
import Icon from '@/components/Icon';
import { Container, ClipButton } from './styles';

const ClipboardText = ({ id, label, name, value, ...rest }) => {
  const input = useRef(null);
  const button = useRef(null);

  useEffect(() => {
    const clipboard = new Clipboard(button.current, {
      target: () => input.current,
    });
    clipboard.on('success', () => toast.success('Copiado!'));
  }, [button, input]);

  return (
    <Container>
      <Input
        id={id}
        name={name}
        label={label}
        value={value}
        ref={input}
        toClipboard
        readOnly
        {...rest}
      />
      <ClipButton ref={button}>
        <Icon
          icon={<FiCopy />}
          size="22px"
          colorOnHover={themes.colors.primary.base}
        />
      </ClipButton>
    </Container>
  );
};

ClipboardText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

ClipboardText.defaultProps = {
  value: '',
};
export default ClipboardText;
