import { useSelector } from 'react-redux';
import checkPermission from '@/services/auth';
import { ApplicationState } from '@/store/types';

type CallbackResponse = (permission: string) => boolean;
const useCheckPermissionCallback = (): CallbackResponse => {
  const { permissions } = useSelector(
    ({ user }: ApplicationState) => user.data.role
  );
  return (permission) =>
    checkPermission({
      permissions,
      permissionsVerify: permission,
    });
};

export default useCheckPermissionCallback;
