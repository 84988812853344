import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import { CreatePlanParams, PlanDTO } from '@/dtos/plan';
import UpdatePlanDTO from '@/dtos/plan/UpdatePlanDTO';

class PlansServices {
  async list(search: SearchQuery): Promise<PageDTO<PlanDTO>> {
    const { data } = await api.get<PageDTO<PlanDTO>>('/plans', {
      params: search.query,
    });
    return data;
  }
  async create(plan: CreatePlanParams): Promise<PlanDTO> {
    const { data } = await api.post<PlanDTO>('/plans', plan);
    return data;
  }
  async update(plans: UpdatePlanDTO): Promise<PlanDTO> {
    const { data } = await api.put<PlanDTO>(`/plans/${plans.id}`, plans);
    return data;
  }
  async getById(id: string): Promise<PlanDTO> {
    const { data } = await api.get<PlanDTO>(`/plans/${id}`);
    return data;
  }
}

const plansService = new PlansServices();
export default plansService;
