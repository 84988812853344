import styled from 'styled-components';
import logo from '@/assets/images/logo-loader.svg';

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  z-index: 99999;
`;

export const LoaderImage = styled.img.attrs({
  src: logo,
})`
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
`;
