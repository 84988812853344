type Opertators = ':' | '!' | '>' | '<' | '~' | string;

export type Query = {
  search: string;
  page: number;
  sort: string;
};

export type FilterParam = {
  key: string;
  operator: Opertators;
  value: string;
};

export class SearchQuery {
  public params: FilterParam[];

  public sorts: string[];

  public page: number;

  static SearchQuery: SearchQuery;

  constructor() {
    this.params = [];
    this.sorts = [];
    this.page = 1;
  }

  get search(): string {
    return `${this.params
      .map(({ key, operator, value }) => [key, operator, value].join(''))
      .join(',')},`;
  }

  get query(): Query {
    return {
      page: this.page,
      search: this.search,
      sort: this.sorts.join(','),
    };
  }

  setPage(page: number): this {
    this.page = page;
    return this;
  }

  removeSort(key: string): this {
    this.sorts = this.sorts.filter((item) => item.split(':')[0] !== key);
    return this;
  }

  removeSearch(key: string): this {
    this.params = this.params.filter((item) => item.key !== key);
    return this;
  }

  sortBy(key: string, sort: string): this {
    this.sorts.push(`${key}:${sort}`);
    return this;
  }

  ascBy(key: string): this {
    return this.sortBy(key, 'ASC');
  }

  descBy(key: string): this {
    return this.sortBy(key, 'DESC');
  }

  with(key: string, operator: Opertators, value: string): SearchQuery {
    this.params.push({ key, operator, value });
    return this;
  }

  notEquals(key: string, value: string): SearchQuery {
    return this.with(key, '!', value);
  }

  isNotNull(key: string): SearchQuery {
    return this.notEquals(key, 'NULL');
  }

  equals(key: string, value: string): SearchQuery {
    return this.with(key, ':', value);
  }

  like(key: string, value: string): SearchQuery {
    return this.with(key, '~', value);
  }

  isNull(key: string): SearchQuery {
    return this.equals(key, 'NULL');
  }

  greaterThan(key: string, value: string): SearchQuery {
    return this.with(key, '>', value);
  }

  lessThan(key: string, value: string): SearchQuery {
    return this.with(key, '<', value);
  }

  clone(): SearchQuery {
    return SearchQuery.from(this.query);
  }

  loadSearch(search: string): this {
    const matches = `${search || ''},`.matchAll(/(\w+?)(:|<|>|!|~|-)(.+?),/gm);
    Array.from(matches).forEach((match) => {
      this.with(match[1], match[2], match[3]);
    });
    return this;
  }

  clear(): this {
    this.params = [];
    this.sorts = [];
    this.page = 1;
    return this;
  }

  static build(): SearchQuery {
    return new SearchQuery();
  }

  static from({ page, search, sort }: Partial<Query>): SearchQuery {
    const searchQuery = SearchQuery.build();
    const matches = `${search || ''},`.matchAll(/(\w+?)(:|<|>|!|~|-)(.+?),/gm);
    Array.from(matches).forEach((match) => {
      searchQuery.with(match[1], match[2], match[3]);
    });
    const sortMatch = `${sort || ''},`.matchAll(/(\w+?)(:)(.+?),/gm);
    Array.from(sortMatch).forEach((match) => {
      searchQuery.sortBy(match[1], match[3]);
    });
    searchQuery.setPage(page || 1);
    return searchQuery;
  }
}

export default SearchQuery;
