import axios from 'axios';
import CreateAddressDTO from '@/dtos/address/CreateAddressDTO';

type AddressResonse = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: string;
};
async function getAddressByZipCode(zipCode: string): Promise<CreateAddressDTO> {
  const { data } = await axios.get<AddressResonse>(
    `https://viacep.com.br/ws/${zipCode}/json/`
  );
  if (data.erro) throw Error('NotFound');
  return {
    zipCode,
    state: data.uf,
    city: data.localidade,
    neighborhood: data.bairro,
    street: data.logradouro,
    number: '',
    complement: data.complemento,
  };
}

export default getAddressByZipCode;
