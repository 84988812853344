import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import locationPositionProvider from '@/lib/locationPositions';
import { history } from '@/services';

import PageDTO from '@/dtos/generics/PageDTO';
import LocationPositionActions, { LocationPositionTypes } from './duck';
import { LocationPositionDTO } from '@/dtos/locationPosition';

type AddParams = ReturnType<typeof LocationPositionActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(locationPositionProvider.create, payload);
    yield put(LocationPositionActions.addSuccess());
    history.push('/location-positions');
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationPositionActions.addError(errors));
  }
}
type UpdateParams = ReturnType<typeof LocationPositionActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(locationPositionProvider.update, payload);
    yield put(LocationPositionActions.updateSuccess());
    history.push('/location-positions');
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationPositionActions.updateError(errors));
  }
}
type ListParams = ReturnType<typeof LocationPositionActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const result = yield call(locationPositionProvider.list, payload.filters);
    yield put(
      LocationPositionActions.listSuccess(
        result as PageDTO<LocationPositionDTO>
      )
    );
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationPositionActions.listError(errors));
  }
}
type GetParams = ReturnType<typeof LocationPositionActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(locationPositionProvider.find, payload);
    yield put(LocationPositionActions.getSuccess(data as LocationPositionDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationPositionActions.getError(errors));
  }
}

export default all([
  takeLatest(LocationPositionTypes.ADD_REQUEST, add),
  takeLatest(LocationPositionTypes.UPDATE_REQUEST, update),
  takeLatest(LocationPositionTypes.LIST_REQUEST, list),
  takeLatest(LocationPositionTypes.GET_REQUEST, get),
]);
