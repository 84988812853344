import { call, put, all, takeLatest } from 'redux-saga/effects';
import handleError from '@/adapters/httpErrors';
import AnswersActions, { AnswersTypes } from './duck';
import questionAnswersProvider from '@/lib/QuestionAnswers';
import { PageDTO } from '@/dtos/generics';
import AnswerDTO from '@/dtos/question/AnswerDTO';

type SignUpParams = ReturnType<typeof AnswersActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(questionAnswersProvider.list, payload);
    yield put(AnswersActions.listSuccess(result as PageDTO<AnswerDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(AnswersActions.listError(errors));
  }
}
export default all([takeLatest(AnswersTypes.LIST_ANSWERS_REQUEST, list)]);
