import React from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { Icon } from './styles';

const OrderBy = ({ orderByAsc, orderByDesc, clear, order }) => {
  const handleUp = (isUp) => {
    const isClear = isUp === order;
    if (isClear) {
      return clear();
    }
    if (isUp === 'ASC') {
      return orderByAsc();
    }
    return orderByDesc();
  };

  return (
    <div>
      <Icon
        as={FiArrowDown}
        onClick={() => handleUp('DESC')}
        active={order === 'DESC'}
        size="18px"
      />
      <Icon
        as={FiArrowUp}
        onClick={() => handleUp('ASC')}
        active={order === 'ASC'}
        size="18px"
      />
    </div>
  );
};

OrderBy.propTypes = {
  orderByAsc: PropTypes.func,
  orderByDesc: PropTypes.func,
  clear: PropTypes.func,
  order: PropTypes.string,
};

OrderBy.defaultProps = {
  orderByAsc: () => {},
  orderByDesc: () => {},
  clear: () => {},
  order: '',
};

export default OrderBy;
