import api from '@/services/api';
import { CreateResouceDTO, ResourceDTO } from '@/dtos/resource';
import UpdateResourceDTO from '@/dtos/resource/UpdateResourceDTO';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import {
  ToggleResourceInteractionType,
  ToggleResourceType,
  UpadeResourceDisplayStatusType,
} from '@/dtos/resource/ToggleResourceDTO';

const resourceProvider = {
  async list(
    filters: SearchQuery,
    page?: number,
    limit?: number
  ): Promise<PageDTO<ResourceDTO>> {
    const { data } = await api.get<PageDTO<ResourceDTO>>(`/resources`, {
      params: { ...filters.query, page, limit },
    });
    return data;
  },

  async listAll(filters?: SearchQuery): Promise<ResourceDTO[]> {
    const { data } = await api.get<ResourceDTO[]>(`/resources-all`, {
      params: filters?.query,
    });
    return data;
  },
  async getById(id: string): Promise<ResourceDTO> {
    const { data } = await api.get<ResourceDTO>(`/resources/${id}`);
    return data;
  },
  async create(resource: CreateResouceDTO): Promise<ResourceDTO> {
    const { data } = await api.post<ResourceDTO>('/resources', resource);
    return data;
  },
  async update(resource: UpdateResourceDTO): Promise<ResourceDTO> {
    const { data } = await api.put<ResourceDTO>(
      `/resources/${resource.id}`,
      resource
    );
    return data;
  },
  async uploadImage({
    resourceId,
    imageBuffer,
  }: {
    resourceId: string;
    imageBuffer: string;
  }): Promise<ResourceDTO> {
    const { data } = await api.post<ResourceDTO>(
      `/upload-resource-image/${resourceId}`,
      { imageBuffer }
    );
    return data;
  },
  async toggleResourceState({
    name,
    value,
    id,
  }: ToggleResourceType): Promise<ResourceDTO> {
    const { data } = await api.put<ResourceDTO>(`/resources/toggle/${id}`, {
      name,
      value,
    });
    return data;
  },
  async toggleResourceInteraction({
    responsiveEmails,
    multipleInteractions,
    canInteractMessage,
    id,
  }: ToggleResourceInteractionType): Promise<ResourceDTO> {
    const { data } = await api.put<ResourceDTO>(
      `/resources-toggle-interaction/${id}`,
      {
        multipleInteractions,
        canInteractMessage,
        responsiveEmails,
      }
    );
    return data;
  },

  async updateDisplayStatus({
    displayStatus,
    id,
  }: UpadeResourceDisplayStatusType): Promise<ResourceDTO> {
    const { data } = await api.put<ResourceDTO>(
      `/resources-display-status/${id}`,
      {
        displayStatus,
      }
    );
    return data;
  },
};

export default resourceProvider;
