enum InvoiceStatus {
  CREATION = 'creation',
  DISPATCHED = 'dispatched',
  RECEIVED = 'received',
  CANCELED = 'canceled',
  INITIAL = 'initial',
  PENDING = 'pending',
  ACCEPT = 'accept',
}

export default InvoiceStatus;
