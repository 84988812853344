import api from '@/services/api';
import { PageDTO } from '@/dtos/generics';
import { ResourceDTO } from '@/dtos/resource';

const questionAnswersProvider = {
  async list(searchParams: string): Promise<PageDTO<ResourceDTO>> {
    const { data } = await api.get<PageDTO<ResourceDTO>>(
      `/answers${searchParams}`
    );
    return data;
  },
};

export default questionAnswersProvider;
