import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { PageDTO } from '@/dtos/generics';
import {
  LicensedCodeDTO,
  CreateLicensedCodeDTO,
  UpdateLicensedCodeDTO,
} from '@/dtos/licensedCodes';
import SearchQuery from '@/utils/SearchQuery';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { LicensedCodesState } from './types';
import { CompanyDTO } from '@/dtos/company';

const INITIAL_STATE: LicensedCodesState = {
  data: [
    {
      active: false,
      marketplace: 'Amazon',
      company: { name: 'IDIP', id: 'nosahjdb' } as CompanyDTO,
      companyId: 'nosahjdb',
    } as LicensedCodeDTO,
  ],
  filters: SearchQuery.build(),
  page: 1,
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
  selected: {} as LicensedCodeDTO,
};

export enum LicensedCodesTypes {
  ADD_REQUEST = '@licensedCodes/ADD_REQUEST',
  ADD_UPDATE = '@licensedCodes/ADD_UPDATE',
  ADD_SUCCESS = '@licensedCodes/ADD_SUCCESS',
  ADD_ERROR = '@licensedCodes/ADD_ERROR',
  LIST_REQUEST = '@licensedCodes/LIST_REQUEST',
  LIST_SUCCESS = '@licensedCodes/LIST_SUCCESS',
  LIST_ERROR = '@licensedCodes/LIST_ERROR',
  GET_REQUEST = '@licensedCodes/GET_REQUEST',
  GET_SUCCESS = '@licensedCodes/GET_SUCCESS',
  GET_ERROR = '@licensedCodes/GET_ERROR',
  PRINT_REQUEST = '@licensedCodes/UPDATE_REQUEST',
  PRINT_SUCCESS = '@licensedCodes/UPDATE_SUCCESS',
  PRINT_ERROR = '@licensedCodes/PRINT_ERROR',
  CLEAR = '@licensedCodes/CLEAR',
  SET_FILTERS = '@licensedCodes/SET_FILTERS',
}

const LicensedCodesActions = {
  listRequest: (params: SearchQuery) =>
    action(LicensedCodesTypes.LIST_REQUEST, params),
  listSuccess: (page: PageDTO<LicensedCodeDTO>) =>
    action(LicensedCodesTypes.LIST_SUCCESS, page),
  listError: (error: string) =>
    action(LicensedCodesTypes.LIST_ERROR, { error }),
  getRequest: (id: string) => action(LicensedCodesTypes.GET_REQUEST, id),
  getSuccess: (licensedCode: LicensedCodeDTO) =>
    action(LicensedCodesTypes.GET_SUCCESS, licensedCode),
  getError: (error: string) => action(LicensedCodesTypes.GET_ERROR, { error }),
  clear: () => action(LicensedCodesTypes.CLEAR),
  setFilters: (filters: SearchQuery) =>
    action(LicensedCodesTypes.SET_FILTERS, filters),
  addRequest: (data: CreateLicensedCodeDTO) =>
    action(LicensedCodesTypes.ADD_REQUEST, data),
  addUpdate: (data: UpdateLicensedCodeDTO) =>
    action(LicensedCodesTypes.ADD_UPDATE, data),
  addSuccess: () => action(LicensedCodesTypes.ADD_SUCCESS),
  addError: (error: string) => action(LicensedCodesTypes.ADD_ERROR, { error }),
};

export default LicensedCodesActions;

export type LicensedCodesReducer<Action extends AnyAction> = Reducer<
  LicensedCodesState,
  Action
>;

const getSucess: LicensedCodesReducer<
  ReturnType<typeof LicensedCodesActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const getLicensedCodesSuccess: LicensedCodesReducer<
  ReturnType<typeof LicensedCodesActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const clear: LicensedCodesReducer<
  ReturnType<typeof LicensedCodesActions.clear>
> = () => INITIAL_STATE;

const setFiltersLicensedCodes: LicensedCodesReducer<
  ReturnType<typeof LicensedCodesActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload,
});

export const reducer = createReducer<LicensedCodesState>(INITIAL_STATE)
  .handleAction(LicensedCodesTypes.LIST_REQUEST, genericRequest)
  .handleAction(LicensedCodesTypes.LIST_SUCCESS, getLicensedCodesSuccess)
  .handleAction(LicensedCodesTypes.LIST_ERROR, genericError)
  .handleAction(LicensedCodesTypes.GET_REQUEST, genericRequest)
  .handleAction(LicensedCodesTypes.GET_SUCCESS, getSucess)
  .handleAction(LicensedCodesTypes.GET_ERROR, genericError)
  .handleAction(LicensedCodesTypes.PRINT_REQUEST, genericRequest)
  .handleAction(LicensedCodesTypes.PRINT_SUCCESS, genericSuccess)
  .handleAction(LicensedCodesTypes.PRINT_ERROR, genericError)
  .handleAction(LicensedCodesTypes.SET_FILTERS, setFiltersLicensedCodes)
  .handleAction(LicensedCodesTypes.ADD_REQUEST, genericRequest)
  .handleAction(LicensedCodesTypes.ADD_UPDATE, genericRequest)
  .handleAction(LicensedCodesTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(LicensedCodesTypes.CLEAR, clear)
  .handleAction(LicensedCodesTypes.SET_FILTERS, setFiltersLicensedCodes);
