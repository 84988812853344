import styled from 'styled-components';

import { IconButton } from '@/components/Button';

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  align-items: end;

  input {
    border-radius: ${({ theme }) =>
      [theme.sizes.borderRadius, 0, 0, theme.sizes.borderRadius].join(' ')};
    border-right: none;
    height: 2.8rem;
  }
`;

export const ClearSearch = styled(IconButton)`
  width: 2.8rem;
  height: 2.8rem;
  background-color: ${({ theme }) => theme.colors.gray.base};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 0;
  transition: all 0.2s;
  &:hover > div svg {
    color: ${(props) => props.theme.colors.error};
  }
`;

export const SearchButton = styled(IconButton)`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0 100px 100px 0;
  border-radius: ${({ theme }) =>
    [0, theme.sizes.borderRadius, theme.sizes.borderRadius, 0].join(' ')};
  background-color: ${({ theme }) => theme.colors.gray.base};
  color: ${({ theme }) => theme.colors.black};
  transition: background-color 0.3s, border 0.3s;

  &:hover > div svg {
    color: ${({ theme }) => theme.colors.primary.base};
  }
`;
