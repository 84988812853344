import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import { genericRequest, genericError } from '../utils';
import { AnswerState } from './types';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import AnswerDTO from '@/dtos/question/AnswerDTO';

const INITIAL_STATE: AnswerState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
};

export enum AnswersTypes {
  SET_LOADING = '@answers/SET_LOADING',
  LIST_ANSWERS_REQUEST = '@answers/LIST_ANSWERS_REQUEST',
  LIST_ANSWERS_SUCCESS = '@answers/LIST_ANSWERS_SUCCESS',
  LIST_ANSWERS_ERROR = '@answers/LIST_ALL_ERROR',

  CLEAR = '@answers/CLEAR',
}

const AnswersActions = {
  setLoading: (loading: boolean) =>
    action(AnswersTypes.SET_LOADING, { loading }),
  listRequest: (searchFilters: string) =>
    action(AnswersTypes.LIST_ANSWERS_REQUEST, searchFilters),
  listSuccess: (page: PageDTO<AnswerDTO>) =>
    action(AnswersTypes.LIST_ANSWERS_SUCCESS, page),
  listError: (error: string) =>
    action(AnswersTypes.LIST_ANSWERS_ERROR, { error }),

  clear: () => action(AnswersTypes.CLEAR),
};
export default AnswersActions;

type AnswersReducer<Action extends AnyAction> = Reducer<AnswerState, Action>;

const setLoading: AnswersReducer<
  ReturnType<typeof AnswersActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});

const listSuccess: AnswersReducer<
  ReturnType<typeof AnswersActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    data: payload.data,
    currentPage: payload.currentPage,
    next: payload.next,
    prev: payload.prev,
    total: payload.total,
    loading: false,
  };
};
const clear: AnswersReducer<ReturnType<typeof AnswersActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<AnswerState>(INITIAL_STATE)
  .handleAction(AnswersTypes.SET_LOADING, setLoading)
  .handleAction(AnswersTypes.LIST_ANSWERS_REQUEST, genericRequest)
  .handleAction(AnswersTypes.LIST_ANSWERS_SUCCESS, listSuccess)
  .handleAction(AnswersTypes.LIST_ANSWERS_ERROR, genericError)

  .handleAction(AnswersTypes.CLEAR, clear);
