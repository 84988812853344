export default {
  colors: {
    primaryLight1: '#E5383B',
    primaryLight2: '#F9474A',
    primaryLight3: '#FF5659',
    primaryLight4: '#F6B6E',
    primaryDark1: '#1A4242',
    primaryDark2: '#154142',
    primaryDark3: '#0F3233',
    primaryDark4: '#053233',
    grayScale: {
      base: '#222324',
      variation1: '#303233',
      variation2: '#414345',
      variation3: '#5E6163',
      variation4: '#8A8D91',
      variation5: '#B6BABF',
      variation6: '#CED2D9',
      variation7: '#DADFE5',
      variation8: '#E9EEF5',
      variation9: '#F0F3F7',
      variation10: '#F5F8FC',
    },
    white: '#FAFAFA',
    black: '#222324',
    shadow: 'rgba(194, 197, 204, 0.25)',
    error: '#B00020',
    warning: '#E1C844',
    primary: {
      dark: '#DE383B',
      base: '#E5383B',
      light: '#E5383B30',
    },
    gray: {
      dark: '#CED2D9',
      base: '#DADFE5',
      light: '#F7F7F7',
    },
    title: '#212121',
    text: '#424242',
    background: '#ffffff',
    contentBackground: '#FAFAFA',
    border: '#e8e8e8',
    page: '#F0F3F7',
    chart: [
      '#840061',
      '#00ff3c',
      '#9138ff',
      '#6ae500',
      '#7b42ff',
      '#f5ff00',
      '#8a38f7',
      '#00ba00',
      '#fb00e5',
      '#00e65b',
      '#f300dc',
      '#00c836',
      '#ad00cd',
      '#79ff69',
      '#ff25ca',
      '#00b94b',
      '#c51bb7',
      '#cbed39',
      '#002db2',
      '#c9d400',
      '#005adb',
      '#ffd01a',
      '#00197d',
      '#d7b400',
      '#007dff',
      '#c6a700',
      '#6767e9',
      '#00c35d',
      '#d872ff',
      '#007700',
      '#ff7eff',
      '#00a242',
      '#ff83ff',
      '#007100',
      '#ff90ff',
      '#086e00',
      '#560075',
      '#ffe262',
      '#000059',
      '#f9d34c',
      '#002181',
      '#b9a914',
      '#004fc1',
      '#9b9b00',
      '#007bf3',
      '#ea2600',
      '#00ffff',
      '#db0000',
      '#00ffff',
      '#ff003b',
      '#00fff3',
      '#ff292d',
      '#00f8ff',
      '#ff2541',
      '#00f7ff',
      '#bd0000',
      '#00f6ff',
      '#b20000',
      '#00d3ff',
      '#ae0000',
      '#008aff',
      '#ff4c37',
      '#008bff',
      '#867d00',
      '#338bff',
      '#407300',
      '#b96cdb',
      '#2baa4e',
      '#ff2862',
      '#00cebb',
      '#e00061',
      '#00ba92',
      '#cb002c',
      '#00d8de',
      '#88000a',
      '#00c0ff',
      '#86000a',
      '#00b7ff',
      '#ff794b',
      '#0084ed',
      '#ff944f',
      '#0059c1',
      '#ffc76e',
      '#00064f',
      '#e2ce72',
      '#00308c',
      '#005d00',
      '#c791ff',
      '#015800',
      '#c996ff',
      '#005300',
      '#ff7ecd',
      '#005300',
      '#be006c',
      '#4ba75f',
      '#6a0064',
      '#005506',
      '#fcb2ff',
      '#004500',
      '#9670d0',
      '#215300',
      '#007ae3',
      '#ff8c56',
      '#0082e8',
      '#953300',
      '#009dff',
      '#ff7255',
      '#0096f3',
      '#874300',
      '#00b0ff',
      '#6e0000',
      '#00c0f3',
      '#860016',
      '#00b3cd',
      '#df3964',
      '#006532',
      '#c41e6b',
      '#004500',
      '#3248ab',
      '#ffde9a',
      '#280038',
      '#ffefb6',
      '#190030',
      '#b1d7ae',
      '#550055',
      '#5fa47c',
      '#ff5f74',
      '#00abac',
      '#600000',
      '#91dfff',
      '#5e0000',
      '#61b7ff',
      '#ff8a5e',
      '#006dca',
      '#ff9a6e',
      '#003a89',
      '#4e4e00',
      '#679dff',
      '#6e4800',
      '#5568c6',
      '#003600',
      '#ffc6ff',
      '#003100',
      '#ffc7ff',
      '#002c00',
      '#d1b4ff',
      '#2b4b0f',
      '#87bdff',
      '#500000',
      '#b9e7ff',
      '#4e0000',
      '#00abe3',
      '#6b0019',
      '#81c4ff',
      '#470000',
      '#cde6ff',
      '#001d00',
      '#ffe4ff',
      '#131f00',
      '#ffdbfc',
      '#1c2d00',
      '#ffacc9',
      '#004c30',
      '#ff8999',
      '#005a3c',
      '#ff9c8e',
      '#003372',
      '#cfab82',
      '#00417c',
      '#ffc4b8',
      '#003663',
      '#e9d1d6',
      '#580032',
      '#c0d1ce',
      '#600025',
      '#a3c8f0',
      '#501e0b',
      '#008cb0',
      '#89303d',
      '#0083ad',
      '#67471b',
      '#c893cf',
      '#00583f',
      '#c39ab1',
      '#002f40',
      '#bb9985',
      '#003453',
      '#82763b',
      '#004d79',
      '#9c8668',
      '#004a73',
      '#76a189',
      '#2e3c53',
      '#82a18a',
      '#7473a2',
      '#005047',
      '#007aa1',
      '#746c67',
      '#006f95',
      '#6b6070',
    ],
  },
  fonts: {
    title: "'Roboto', sans-serif",
    text: "'Roboto', sans-serif",
  },
  sizes: {
    title: '24px',
    subTitle: '18px',
    text: '14px',
    header: '26px',
    sideBar: '230px',
    borderRadius: '10px',
    shadow: '0px 1px 3px 1px',
    zIndexes: {
      menus: {
        arrow: 101,
        floatContainer: 102,
      },
      floatingButtons: 60,
    },
  },
  shadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
};
