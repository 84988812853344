import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { CreateResouceDTO, ResourceDTO } from '@/dtos/resource';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { ResourceState } from './types';
import UpdateResourceDTO from '@/dtos/resource/UpdateResourceDTO';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import SearchQuery from '@/utils/SearchQuery';
import {
  ToggleResourceInteractionType,
  ToggleResourceType,
  UpadeResourceDisplayStatusType,
} from '@/dtos/resource/ToggleResourceDTO';

const INITIAL_STATE: ResourceState = {
  allResources: [],
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as ResourceDTO,
  fields: [],
};

export enum ResourceTypes {
  SET_LOADING = '@resources/SET_LOADING',
  SET_FILTERS = '@resources/SET_FILTERS',
  LIST_REQUEST = '@resources/LIST_REQUEST',
  LIST_SUCCESS = '@resources/LIST_SUCCESS',
  LIST_ERROR = '@resources/LIST_ERROR',
  LIST_ALL_REQUEST = '@resources/LIST_ALL_REQUEST',
  LIST_ALL_SUCCESS = '@resources/LIST_ALL_SUCCESS',
  LIST_ALL_ERROR = '@resources/LIST_ALL_ERROR',
  UPDATE_REQUEST = '@resources/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@resources/UPDATE_SUCCESS',
  UPDATE_ERROR = '@resources/UPDATE_ERROR',

  UPLOAD_IMAGE_REQUEST = '@resources/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS = '@resources/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_ERROR = '@resources/UPLOAD_IMAGE_ERROR',

  TOGGLE_VISIBILITY_REQUEST = '@resources/TOGGLE_VISIBILITY_REQUEST',
  TOGGLE_VISIBILITY_SUCCESS = '@resources/TOGGLE_VISIBILITY_SUCCESS',
  TOGGLE_VISIBILITY_ERROR = '@resources/TOGGLE_VISIBILITY_ERROR',

  TOGGLE_INTERACTION_REQUEST = '@resources/TOGGLE_INTERACTION_REQUEST',
  TOGGLE_INTERACTION_SUCCESS = '@resources/TOGGLE_INTERACTION_SUCCESS',
  TOGGLE_INTERACTION_ERROR = '@resources/TOGGLE_INTERACTION_ERROR',

  UPDATE_DISPLAY_STATUS_REQUEST = '@resources/UPDATE_DISPLAY_STATUS_REQUEST',
  UPDATE_DISPLAY_STATUS_SUCCESS = '@resources/UPDATE_DISPLAY_STATUS_SUCCESS',
  UPDATE_DISPLAY_STATUS_ERROR = '@resources/UPDATE_DISPLAY_STATUS_ERROR',

  GET_REQUEST = '@resources/GET_REQUEST',
  GET_SUCCESS = '@resources/GET_SUCCESS',
  GET_ERROR = '@resources/GET_ERROR',
  ADD_REQUEST = '@resources/ADD_REQUEST',
  ADD_SUCCESS = '@resources/ADD_SUCCESS',
  ADD_ERROR = '@resources/ADD_ERROR',
  CLEAR = '@resources/CLEAR',
  GET_LIST_FIELDS_REQUEST = 'GET_LIST_FIELDS_REQUEST',
  GET_LIST_FIELDS_SUCCESS = 'GET_LIST_FIELDS_SUCCESS',
  GET_LIST_FIELDS_ERROR = 'GET_LIST_FIELDS_ERROR',
}

const ResourcesActions = {
  setLoading: (loading: boolean) =>
    action(ResourceTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(ResourceTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(ResourceTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<ResourceDTO>) =>
    action(ResourceTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(ResourceTypes.LIST_ERROR, { error }),

  listAllRequest: () => action(ResourceTypes.LIST_ALL_REQUEST, {}),
  listAllSuccess: (resources: ResourceDTO[]) =>
    action(ResourceTypes.LIST_ALL_SUCCESS, resources),
  listAllError: (error: string) =>
    action(ResourceTypes.LIST_ALL_ERROR, { error }),

  updateRequest: (resources: UpdateResourceDTO) =>
    action(ResourceTypes.UPDATE_REQUEST, resources),
  updateSuccess: () => action(ResourceTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(ResourceTypes.UPDATE_ERROR, { error }),

  uploadImageRequest: (resourceId: string, imageBuffer: string) =>
    action(ResourceTypes.UPLOAD_IMAGE_REQUEST, { resourceId, imageBuffer }),
  uploadImageSuccess: () => action(ResourceTypes.UPLOAD_IMAGE_SUCCESS),
  uploadImageError: (error: string) =>
    action(ResourceTypes.UPLOAD_IMAGE_ERROR, { error }),

  toggleVisibilityRequest: (resources: ToggleResourceType) =>
    action(ResourceTypes.TOGGLE_VISIBILITY_REQUEST, resources),
  toggleVisibilitySuccess: () =>
    action(ResourceTypes.TOGGLE_VISIBILITY_SUCCESS),
  toggleVisibilityError: (error: string) =>
    action(ResourceTypes.TOGGLE_VISIBILITY_ERROR, { error }),

  toggleInteractionRequest: (resources: ToggleResourceInteractionType) =>
    action(ResourceTypes.TOGGLE_INTERACTION_REQUEST, resources),
  toggleInteractionSuccess: () =>
    action(ResourceTypes.TOGGLE_INTERACTION_SUCCESS),
  toggleInteractionError: (error: string) =>
    action(ResourceTypes.TOGGLE_INTERACTION_ERROR, { error }),

  updateResourceDisplayStatusRequest: (
    resources: UpadeResourceDisplayStatusType
  ) => action(ResourceTypes.UPDATE_DISPLAY_STATUS_REQUEST, resources),
  updateDisplayStatusSuccess: () =>
    action(ResourceTypes.UPDATE_DISPLAY_STATUS_SUCCESS),
  updateDisplayStatusError: (error: string) =>
    action(ResourceTypes.UPDATE_DISPLAY_STATUS_ERROR, { error }),

  getRequest: (id: string) => action(ResourceTypes.GET_REQUEST, id),
  getSuccess: (resource: ResourceDTO) =>
    action(ResourceTypes.GET_SUCCESS, resource),
  getError: (error: string) => action(ResourceTypes.GET_ERROR, { error }),

  getListFieldsRequest: (filters: SearchQuery) =>
    action(ResourceTypes.GET_LIST_FIELDS_REQUEST, { filters }),
  getListFieldsSuccess: (page: ResourceFieldDTO[]) =>
    action(ResourceTypes.GET_LIST_FIELDS_SUCCESS, page),
  getListFieldsError: (error: string) =>
    action(ResourceTypes.GET_LIST_FIELDS_ERROR, { error }),

  addRequest: (data: CreateResouceDTO, redirect = true) =>
    action(ResourceTypes.ADD_REQUEST, { data, redirect }),
  addSuccess: () => action(ResourceTypes.ADD_SUCCESS),
  addError: (error: string) => action(ResourceTypes.ADD_ERROR, { error }),

  clear: () => action(ResourceTypes.CLEAR),
};
export default ResourcesActions;

type ResourcesReducer<Action extends AnyAction> = Reducer<
  ResourceState,
  Action
>;

const setLoading: ResourcesReducer<
  ReturnType<typeof ResourcesActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: ResourcesReducer<
  ReturnType<typeof ResourcesActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    data: payload.data,
    currentPage: payload.currentPage,
    next: payload.next,
    prev: payload.prev,
    total: payload.total,
    loading: false,
  };
};
const listAllSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.listAllSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    allResources: payload,
    loading: false,
  };
};

const getSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    selected: payload,
    loading: false,
  };
};

const getListFieldsSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.getListFieldsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fields: payload,
  loading: false,
});

const clear: ResourcesReducer<ReturnType<typeof ResourcesActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<ResourceState>(INITIAL_STATE)
  .handleAction(ResourceTypes.SET_LOADING, setLoading)
  .handleAction(ResourceTypes.SET_FILTERS, setFilters)
  .handleAction(ResourceTypes.LIST_REQUEST, genericRequest)
  .handleAction(ResourceTypes.LIST_SUCCESS, listSuccess)
  .handleAction(ResourceTypes.LIST_ERROR, genericError)
  .handleAction(ResourceTypes.LIST_ALL_REQUEST, genericRequest)
  .handleAction(ResourceTypes.LIST_ALL_SUCCESS, listAllSuccess)
  .handleAction(ResourceTypes.LIST_ALL_ERROR, genericError)
  .handleAction(ResourceTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(ResourceTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.UPDATE_ERROR, genericError)

  .handleAction(ResourceTypes.UPLOAD_IMAGE_REQUEST, genericRequest)
  .handleAction(ResourceTypes.UPLOAD_IMAGE_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.UPLOAD_IMAGE_ERROR, genericError)

  .handleAction(ResourceTypes.TOGGLE_VISIBILITY_REQUEST, genericRequest)
  .handleAction(ResourceTypes.TOGGLE_VISIBILITY_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.TOGGLE_VISIBILITY_ERROR, genericError)

  .handleAction(ResourceTypes.TOGGLE_INTERACTION_REQUEST, genericRequest)
  .handleAction(ResourceTypes.TOGGLE_INTERACTION_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.TOGGLE_INTERACTION_ERROR, genericError)

  .handleAction(ResourceTypes.UPDATE_DISPLAY_STATUS_REQUEST, genericRequest)
  .handleAction(ResourceTypes.UPDATE_DISPLAY_STATUS_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.UPDATE_DISPLAY_STATUS_ERROR, genericError)

  .handleAction(ResourceTypes.GET_REQUEST, genericRequest)
  .handleAction(ResourceTypes.GET_SUCCESS, getSuccess)
  .handleAction(ResourceTypes.GET_ERROR, genericError)
  .handleAction(ResourceTypes.ADD_REQUEST, genericRequest)
  .handleAction(ResourceTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.ADD_ERROR, genericError)
  .handleAction(ResourceTypes.GET_LIST_FIELDS_REQUEST, genericRequest)
  .handleAction(ResourceTypes.GET_LIST_FIELDS_SUCCESS, getListFieldsSuccess)
  .handleAction(ResourceTypes.GET_LIST_FIELDS_ERROR, genericError)
  .handleAction(ResourceTypes.CLEAR, clear);
