import PermissionDTO from '../dtos/roles/PermissionDTO';

type CheckParams = {
  permissions: PermissionDTO[];
  permissionsVerify: string;
};

export default function checkPermission({
  permissions,
  permissionsVerify,
}: CheckParams): boolean {
  if (!permissions) return false;
  if (!permissionsVerify) return true;

  return permissions.some(
    (permission) => permissionsVerify === permission.name
  );
}
