import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import { FeatureDTO } from '@/dtos/feature';

class FeaturesServices {
  async list(search: SearchQuery): Promise<PageDTO<FeatureDTO>> {
    const { data } = await api.get<PageDTO<FeatureDTO>>('/features', {
      params: search.query,
    });
    return data;
  }
}

const featuresService = new FeaturesServices();
export default featuresService;
