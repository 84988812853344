import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { CreateResourceFieldDTO } from '@/dtos/resourceField';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { ResourceFieldState } from './types';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import SearchQuery from '@/utils/SearchQuery';
import UpdateResourceFieldDTO from '@/dtos/resourceField/ UpdateResourceFieldDTO';
import { ToggleResourceType } from '@/dtos/resource/ToggleResourceDTO';

const INITIAL_STATE: ResourceFieldState = {
  data: [],
  loading: false,
  error: '',
  selected: {} as ResourceFieldDTO,
};

export enum ResourceFieldTypes {
  SET_LOADING = '@resourceField/SET_LOADING',
  SET_FILTERS = '@resourceField/SET_FILTERS',
  ADD_REQUEST = '@resourceField/ADD_REQUEST',
  ADD_SUCCESS = '@resourceField/ADD_SUCCESS',
  ADD_ERROR = '@resourceField/ADD_ERROR',
  LIST_REQUEST = '@resourceField/LIST_REQUEST',
  LIST_SUCCESS = '@resourceField/LIST_SUCCESS',
  LIST_ERROR = '@resourceField/LIST_ERROR',
  UPDATE_REQUEST = '@resourceField/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@resourceField/UPDATE_SUCCESS',
  UPDATE_ERROR = '@resourceField/UPDATE_ERROR',
  TOGGLE_VISIBILITY_REQUEST = '@resourceField/TOGGLE_VISIBILITY_REQUEST',
  TOGGLE_VISIBILITY_SUCCESS = '@resourceField/TOGGLE_VISIBILITY_SUCCESS',
  TOGGLE_VISIBILITY_ERROR = '@resourceField/TOGGLE_VISIBILITY_ERROR',
  GET_REQUEST = '@resourceField/GET_REQUEST',
  GET_SUCCESS = '@resourceField/GET_SUCCESS',
  GET_ERROR = '@resourceField/GET_ERROR',
  CLEAR = '@resourceField/CLEAR',
}

const ResourceFieldActions = {
  setLoading: (loading: boolean) =>
    action(ResourceFieldTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(ResourceFieldTypes.SET_FILTERS, { filters }),

  addRequest: (data: CreateResourceFieldDTO) =>
    action(ResourceFieldTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(ResourceFieldTypes.ADD_SUCCESS),
  addError: (error: string) => action(ResourceFieldTypes.ADD_ERROR, { error }),

  listRequest: (filters: SearchQuery) =>
    action(ResourceFieldTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: ResourceFieldDTO[]) =>
    action(ResourceFieldTypes.LIST_SUCCESS, page),
  listError: (error: string) =>
    action(ResourceFieldTypes.LIST_ERROR, { error }),

  updateRequest: (resourceField: UpdateResourceFieldDTO) =>
    action(ResourceFieldTypes.UPDATE_REQUEST, resourceField),
  updateSuccess: () => action(ResourceFieldTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(ResourceFieldTypes.UPDATE_ERROR, { error }),

  toggleVisibilityRequest: (resources: ToggleResourceType) =>
    action(ResourceFieldTypes.TOGGLE_VISIBILITY_REQUEST, resources),
  toggleVisibilitySuccess: () =>
    action(ResourceFieldTypes.TOGGLE_VISIBILITY_SUCCESS),
  toggleVisibilityError: (error: string) =>
    action(ResourceFieldTypes.TOGGLE_VISIBILITY_ERROR, { error }),

  getRequest: (id: string) => action(ResourceFieldTypes.GET_REQUEST, id),
  getSuccess: (resourceField: ResourceFieldDTO) =>
    action(ResourceFieldTypes.GET_SUCCESS, resourceField),
  getError: (error: string) => action(ResourceFieldTypes.GET_ERROR, { error }),

  clear: () => action(ResourceFieldTypes.CLEAR),
};
export default ResourceFieldActions;

type ResourcesReducer<Action extends AnyAction> = Reducer<
  ResourceFieldState,
  Action
>;

const setLoading: ResourcesReducer<
  ReturnType<typeof ResourceFieldActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});

const setFilters: ResourcesReducer<
  ReturnType<typeof ResourceFieldActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: ResourcesReducer<
  ReturnType<typeof ResourceFieldActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload,
  loading: false,
});

const getSuccess: ResourcesReducer<
  ReturnType<typeof ResourceFieldActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    selected: payload,
    loading: false,
  };
};
const clear: ResourcesReducer<
  ReturnType<typeof ResourceFieldActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<ResourceFieldState>(INITIAL_STATE)
  .handleAction(ResourceFieldTypes.SET_LOADING, setLoading)
  .handleAction(ResourceFieldTypes.SET_FILTERS, setFilters)
  .handleAction(ResourceFieldTypes.ADD_REQUEST, genericRequest)
  .handleAction(ResourceFieldTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(ResourceFieldTypes.ADD_ERROR, genericError)
  .handleAction(ResourceFieldTypes.LIST_REQUEST, genericRequest)
  .handleAction(ResourceFieldTypes.LIST_SUCCESS, listSuccess)
  .handleAction(ResourceFieldTypes.LIST_ERROR, genericError)
  .handleAction(ResourceFieldTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(ResourceFieldTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(ResourceFieldTypes.UPDATE_ERROR, genericError)
  .handleAction(ResourceFieldTypes.TOGGLE_VISIBILITY_REQUEST, genericRequest)
  .handleAction(ResourceFieldTypes.TOGGLE_VISIBILITY_SUCCESS, getSuccess)
  .handleAction(ResourceFieldTypes.TOGGLE_VISIBILITY_ERROR, genericError)
  .handleAction(ResourceFieldTypes.GET_REQUEST, genericRequest)
  .handleAction(ResourceFieldTypes.GET_SUCCESS, getSuccess)
  .handleAction(ResourceFieldTypes.GET_ERROR, genericError)
  .handleAction(ResourceFieldTypes.CLEAR, clear);
