export type Param = {
  [key: string]: string | number | boolean | Date | undefined;
};

export default function removeUndefined<T extends Param>(data: T): {} {
  return Object.keys(data).reduce((accumulator, item) => {
    const filterValue = data[item];
    if (filterValue === undefined) return accumulator;
    return { ...accumulator, [item]: data[item] };
  }, {});
}
