import React from 'react';
import { useTranslation } from 'react-i18next';

import { portugueseFlag, englishFlag, spanishFlag } from '@/assets/images';

import { Text } from '@/components/Text';
import { FlagIcon, Option } from './styles';

const I18n: React.FC = () => {
  const { i18n, t: translation } = useTranslation();
  const ptBrLabel = translation('Label.ptBR');
  const enUsLabel = translation('Label.enUS');
  const esEsLabel = translation('Label.esES');

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Option
        onClick={() => handleChangeLanguage('pt-BR')}
        active={i18n.language === 'pt-BR'}
      >
        <FlagIcon src={portugueseFlag} alt="português" />
        <Text title={ptBrLabel}>{ptBrLabel}</Text>
      </Option>
      <Option
        onClick={() => handleChangeLanguage('en-US')}
        active={i18n.language === 'en-US'}
      >
        <FlagIcon src={englishFlag} alt="english" />
        <Text title={enUsLabel}>{enUsLabel}</Text>
      </Option>
      <Option
        onClick={() => handleChangeLanguage('es-ES')}
        active={i18n.language === 'es-ES'}
      >
        <FlagIcon src={spanishFlag} alt="español" />
        <Text title={esEsLabel}>{esEsLabel}</Text>
      </Option>
    </>
  );
};

export default I18n;
