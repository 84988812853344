import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

import { genericError, genericRequest } from '../utils';
import { InteractionState } from './types';
import { InteractionDTO } from '@/dtos/interaction';
import InteractionCountDTO from '@/dtos/interaction/InteractionCountDTO';

const INITIAL_STATE: InteractionState = {
  data: [],
  interactionCount: [],
  next: undefined,
  filters: SearchQuery.build(),
  selected: {} as InteractionDTO,
  currentPage: 1,
  prev: undefined,
  total: 0,
  error: '',
  loading: false,
};

export enum InteractionTypes {
  SET_LOADING = '@interaction/SET_LOADING',
  SET_FILTERS = '@interaction/SET_FILTERS',

  LIST_REQUEST = '@interaction/LIST_REQUEST',
  LIST_SUCCESS = '@interaction/LIST_SUCCESS',
  LIST_ERROR = '@interaction/LIST_ERROR',

  LIST_INTERACTION_COUNT_REQUEST = '@interaction/LIST_INTERACTION_COUNT_REQUEST',
  LIST_INTERACTION_COUNT_SUCCESS = '@interaction/LIST_INTERACTION_COUNT_SUCCESS',
  LIST_INTERACTION_COUNT_ERROR = '@interaction/LIST_INTERACTION_COUNT_ERROR',

  GET_REQUEST = '@interaction/GET_REQUEST',
  GET_SUCCESS = '@interaction/GET_SUCCESS',
  GET_ERROR = '@interaction/GET_ERROR',

  CLEAR = '@interaction/CLEAR',
}

const InteractionActions = {
  setLoading: (loading: boolean) =>
    action(InteractionTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(InteractionTypes.SET_FILTERS, { filters }),

  listRequest: (searchFilters: string) =>
    action(InteractionTypes.LIST_REQUEST, searchFilters),
  listSuccess: (page: PageDTO<InteractionDTO>) =>
    action(InteractionTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(InteractionTypes.LIST_ERROR, { error }),

  listInteractionCountRequest: (searchFilters: string) =>
    action(InteractionTypes.LIST_INTERACTION_COUNT_REQUEST, searchFilters),
  listInteractionCountSuccess: (page: PageDTO<InteractionCountDTO>) =>
    action(InteractionTypes.LIST_INTERACTION_COUNT_SUCCESS, page),
  listInteractionCountError: (error: string) =>
    action(InteractionTypes.LIST_INTERACTION_COUNT_ERROR, { error }),

  getRequest: (id: string) => action(InteractionTypes.GET_REQUEST, id),
  getSuccess: (resource: InteractionDTO) =>
    action(InteractionTypes.GET_SUCCESS, resource),
  getError: (error: string) => action(InteractionTypes.GET_ERROR, { error }),

  clear: () => action(InteractionTypes.CLEAR),
};
export default InteractionActions;

type InteractionReducer<Action extends AnyAction> = Reducer<
  InteractionState,
  Action
>;

const setLoading: InteractionReducer<
  ReturnType<typeof InteractionActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: InteractionReducer<
  ReturnType<typeof InteractionActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: InteractionReducer<
  ReturnType<typeof InteractionActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});
const listInteractionCountSuccess: InteractionReducer<
  ReturnType<typeof InteractionActions.listInteractionCountSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  interactionCount: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: InteractionReducer<
  ReturnType<typeof InteractionActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: InteractionReducer<
  ReturnType<typeof InteractionActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<InteractionState>(INITIAL_STATE)
  .handleAction(InteractionTypes.SET_LOADING, setLoading)
  .handleAction(InteractionTypes.SET_FILTERS, setFilters)

  .handleAction(InteractionTypes.LIST_REQUEST, genericRequest)
  .handleAction(InteractionTypes.LIST_SUCCESS, listSuccess)
  .handleAction(InteractionTypes.LIST_ERROR, genericError)

  .handleAction(InteractionTypes.LIST_INTERACTION_COUNT_REQUEST, genericRequest)
  .handleAction(
    InteractionTypes.LIST_INTERACTION_COUNT_SUCCESS,
    listInteractionCountSuccess
  )
  .handleAction(InteractionTypes.LIST_INTERACTION_COUNT_ERROR, genericError)

  .handleAction(InteractionTypes.GET_REQUEST, genericRequest)
  .handleAction(InteractionTypes.GET_SUCCESS, getSuccess)
  .handleAction(InteractionTypes.GET_ERROR, genericError)
  .handleAction(InteractionTypes.CLEAR, clear);
