import api from '@/services/api';
import { PermissionDTO } from '@/dtos/roles';

class PermissionServices {
  async list(): Promise<PermissionDTO[]> {
    const { data } = await api.get<PermissionDTO[]>('/permissions');
    return data;
  }
}

const permissionService = new PermissionServices();
export default permissionService;
