import axios from 'axios';

import { PrinterDTO } from '@/dtos/printers';

const sleep = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

class PrintService {
  static makeLayout(code: string): string {
    return `
      ^XA
      ^CM Top section with logo, name and address.
      ^CF0,60
      ^FO45,50^FDMeta Solucoes em Seguranca^FS
      ^CF0,30
      ^FO200,125^FDRUA RODRIGUES LEME, 81, SALA 03^FS
      ^FO250,165^FDMORUNGABA, SP, BRASIL^FS
      ^FO50,200^GB700,1,3^FS
      ^FO200,290^FDAcesse: http://m87.me/${code}^FS
      ^FX Third section with barcode.
      ^FO280,350
      ^BQN,5,10
      ^FD,A http://m87.me/${code}^FS
      ^XZ
    `;
  }

  static async print(printer: PrinterDTO, code: string): Promise<void> {
    try {
      const message = PrintService.makeLayout(code);
      const { port, url } = printer;
      await axios.post(`http://${url}:${port}`, message, {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
    } catch (err) {
      return undefined;
    }
  }

  static async printMultiple(
    printer: PrinterDTO,
    codes: string[]
  ): Promise<void> {
    for (const code of codes) {
      await PrintService.print(printer, code);
      await sleep(400);
    }
  }
}

export default PrintService;
