import api from '@/services/api';
import {} from '@/dtos/printers';
import { PageDTO } from '@/dtos/generics';
import { InteractionDTO } from '@/dtos/interaction';
import InteractionCountDTO from '@/dtos/interaction/InteractionCountDTO';

const interactionProvider = {
  async list(searchParams: string): Promise<PageDTO<InteractionDTO>> {
    const { data } = await api.get<PageDTO<InteractionDTO>>(
      `/interactions${searchParams}`
    );
    return data;
  },
  async fetchInteractionsCount(
    searchParams: string
  ): Promise<PageDTO<InteractionCountDTO>> {
    const { data } = await api.get<PageDTO<InteractionCountDTO>>(
      `/interactions-counts${searchParams}`
    );
    return data;
  },
};

export default interactionProvider;
