import { CreateStatusFieldDTO, StatusFieldDTO } from '@/dtos/statusField';
import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';

class StatusFieldServices {
  async create(status: CreateStatusFieldDTO): Promise<StatusFieldDTO> {
    const { data } = await api.post<StatusFieldDTO>('/status-fields', status);
    return data;
  }
  async update(status: StatusFieldDTO): Promise<StatusFieldDTO> {
    const { data } = await api.put<StatusFieldDTO>(
      `/status-fields/${status.id}`,
      status
    );
    return data;
  }
  async list(filters: SearchQuery): Promise<StatusFieldDTO[]> {
    const { data } = await api.get<StatusFieldDTO[]>('/status-fields', {
      params: filters.query,
    });
    return data;
  }
  async get(id: string): Promise<StatusFieldDTO> {
    const { data } = await api.get<StatusFieldDTO>(`/status-fields/${id}`);
    return data;
  }
}

const statusFieldServices = new StatusFieldServices();

export default statusFieldServices;
