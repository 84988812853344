import PageDTO from '@/dtos/generics/PageDTO';
import CodeDTO from '@/dtos/solicitations/CodeDTO';
import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';

class CodeServices {
  async findBySolicitation(solicitationId: string): Promise<string[]> {
    const { data } = await api.get<string[]>(
      `/solicitations/${solicitationId}/codes`,
      {
        params: { type: 'CREATION' },
      }
    );
    return data;
  }
  async list(params: SearchQuery): Promise<PageDTO<CodeDTO>> {
    const { data } = await api.get<PageDTO<CodeDTO>>('/codes', {
      params: params.query,
    });
    return data;
  }

  async find(id: string): Promise<CodeDTO> {
    const { data } = await api.get<CodeDTO>(`/codes/${id}`);
    return data;
  }
}

const codeServices = new CodeServices();

export default codeServices;
