import { AnyAction, Reducer } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { action, createReducer } from 'typesafe-actions';

import UserDTO from '@/dtos/user/UserDTO';
import AuthenticateParamsDTO from '@/dtos/user/AuthenticateParamsDTO';
import { UserState } from './types';
import { genericError, genericRequest } from '../utils';

export enum UserTypes {
  SET_USER = '@account/setUser',
  CLEAR_ERROR = '@account/clearError',
  LOGIN_REQUEST = '@account/loginRequest',
  LOGIN_SUCCESS = '@account/loginSuccess',
  LOGIN_ERROR = '@account/loginError',
  GET_PROFILE_REQUEST = '@account/getProfileRequest',
  GET_PROFILE_SUCCESS = '@account/getProfileSuccess',
  GET_PROFILE_ERROR = '@account/getProfileError',
  UPDATE_PROFILE_REQUEST = '@account/updateProfileRequest',
  UPDATE_PROFILE_SUCCESS = '@account/updateProfileSuccess',
  UPDATE_PROFILE_ERROR = '@account/updateProfileError',
  SEND_PASSWORD_RECOVERY_LINK_REQUEST = '@account/sendPasswordRecoveryLinkRequest',
  SEND_PASSWORD_RECOVERY_LINK_SUCCESS = '@account/sendPasswordRecoveryLinkSuccess',
  SEND_PASSWORD_RECOVERY_LINK_ERROR = '@account/sendPasswordRecoveryLinkError',
  RESEND_PASSWORD_RECOVERY_LINK_REQUEST = '@account/resendPasswordRecoveryLinkRequest',
  RESEND_PASSWORD_RECOVERY_LINK_SUCCESS = '@account/resendPasswordRecoveryLinkSuccess',
  RESEND_PASSWORD_RECOVERY_LINK_ERROR = '@account/resendPasswordRecoveryLinkError',
  PASSWORD_RECOVERY_REQUEST = '@account/passwordRecoveryRequest',
  PASSWORD_RECOVERY_SUCCESS = '@account/passwordRecoverySuccess',
  PASSWORD_RECOVERY_ERROR = '@account/passwordRecoveryError',
  LOGOUT_REQUEST = '@account/logoutRequest',
  LOGOUT_SUCCESS = '@account/logoutSuccess',
}

const UserActions = {
  setToken: (user: UserState) => action(REHYDRATE, { user }),
  setUser: (data: UserDTO) => action(UserTypes.SET_USER, { data }),
  clearError: () => action(UserTypes.CLEAR_ERROR),
  loginRequest: (data: AuthenticateParamsDTO) =>
    action(UserTypes.LOGIN_REQUEST, { data }),
  loginSuccess: (user: UserDTO, token: string, sessionDue: string) =>
    action(UserTypes.LOGIN_SUCCESS, { token, user, sessionDue }),
  loginError: (error: string) => action(UserTypes.LOGIN_ERROR, { error }),
  getProfileRequest: () => action(UserTypes.GET_PROFILE_REQUEST),
  getProfileSuccess: (data: UserDTO) =>
    action(UserTypes.GET_PROFILE_SUCCESS, { data }),
  getProfileError: (error: string) =>
    action(UserTypes.GET_PROFILE_ERROR, { error }),
  updateProfileRequest: (data: UserDTO) =>
    action(UserTypes.UPDATE_PROFILE_REQUEST, { data }),
  updateProfileSuccess: (data: UserDTO) =>
    action(UserTypes.UPDATE_PROFILE_SUCCESS, { data }),
  updateProfileError: (error: string) =>
    action(UserTypes.UPDATE_PROFILE_ERROR, { error }),
  sendPasswordRecoveryLinkRequest: (email: string) =>
    action(UserTypes.SEND_PASSWORD_RECOVERY_LINK_REQUEST, { email }),
  sendPasswordRecoveryLinkSuccess: () =>
    action(UserTypes.SEND_PASSWORD_RECOVERY_LINK_SUCCESS),
  sendPasswordRecoveryLinkError: (error: string) =>
    action(UserTypes.SEND_PASSWORD_RECOVERY_LINK_ERROR, { error }),
  resendPasswordRecoveryLinkRequest: (code: string) =>
    action(UserTypes.RESEND_PASSWORD_RECOVERY_LINK_REQUEST, { code }),
  resendPasswordRecoveryLinkSuccess: () =>
    action(UserTypes.RESEND_PASSWORD_RECOVERY_LINK_SUCCESS),
  resendPasswordRecoveryLinkError: (error: string) =>
    action(UserTypes.RESEND_PASSWORD_RECOVERY_LINK_ERROR, { error }),
  passwordRecoveryRequest: (code: string, password: string) =>
    action(UserTypes.PASSWORD_RECOVERY_REQUEST, { code, password }),
  passwordRecoverySuccess: () => action(UserTypes.PASSWORD_RECOVERY_SUCCESS),
  passwordRecoveryError: (error: string) =>
    action(UserTypes.PASSWORD_RECOVERY_ERROR, { error }),
  logoutRequest: () => action(UserTypes.LOGOUT_REQUEST),
  logoutSuccess: () => action(UserTypes.LOGOUT_SUCCESS),
};
export default UserActions;

const INITIAL_STATE: UserState = {
  token: '',
  data: {} as UserDTO,
  loading: false,
  send: false,
  error: '',
  sessionDue: '',
};

type UserReducer<Action extends AnyAction> = Reducer<UserState, Action>;

const setUser: UserReducer<ReturnType<typeof UserActions.setUser>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.data,
});

const clearError: UserReducer<ReturnType<typeof UserActions.clearError>> = (
  state = INITIAL_STATE
) => {
  return { ...state, error: '', loading: false, send: false };
};

const loginSuccess: UserReducer<ReturnType<typeof UserActions.loginSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.user,
  token: payload.token,
  error: '',
  loading: false,
  sessionDue: payload.sessionDue,
});

const getProfileSuccess: UserReducer<
  ReturnType<typeof UserActions.getProfileSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  error: '',
  loading: false,
});

const updateProfileSuccess: UserReducer<
  ReturnType<typeof UserActions.updateProfileSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  error: '',
  loading: false,
});

const genericSendSuccess: UserReducer<
  ReturnType<typeof UserActions.sendPasswordRecoveryLinkSuccess>
> = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
  send: true,
});

const logoutSucess: UserReducer<ReturnType<typeof UserActions.loginSuccess>> =
  () => INITIAL_STATE;

export const reducer = createReducer<UserState>(INITIAL_STATE)
  .handleAction(UserTypes.SET_USER, setUser)
  .handleAction(UserTypes.CLEAR_ERROR, clearError)
  .handleAction(UserTypes.LOGIN_REQUEST, genericRequest)
  .handleAction(UserTypes.LOGIN_SUCCESS, loginSuccess)
  .handleAction(UserTypes.LOGIN_ERROR, genericError)
  .handleAction(UserTypes.GET_PROFILE_REQUEST, genericRequest)
  .handleAction(UserTypes.GET_PROFILE_SUCCESS, getProfileSuccess)
  .handleAction(UserTypes.GET_PROFILE_ERROR, genericError)
  .handleAction(UserTypes.UPDATE_PROFILE_REQUEST, genericRequest)
  .handleAction(UserTypes.UPDATE_PROFILE_SUCCESS, updateProfileSuccess)
  .handleAction(UserTypes.UPDATE_PROFILE_ERROR, genericError)
  .handleAction(UserTypes.SEND_PASSWORD_RECOVERY_LINK_REQUEST, genericRequest)
  .handleAction(
    UserTypes.SEND_PASSWORD_RECOVERY_LINK_SUCCESS,
    genericSendSuccess
  )
  .handleAction(UserTypes.SEND_PASSWORD_RECOVERY_LINK_ERROR, genericError)
  .handleAction(UserTypes.RESEND_PASSWORD_RECOVERY_LINK_REQUEST, genericRequest)
  .handleAction(
    UserTypes.RESEND_PASSWORD_RECOVERY_LINK_SUCCESS,
    genericSendSuccess
  )
  .handleAction(UserTypes.RESEND_PASSWORD_RECOVERY_LINK_ERROR, genericError)
  .handleAction(UserTypes.PASSWORD_RECOVERY_REQUEST, genericRequest)
  .handleAction(UserTypes.PASSWORD_RECOVERY_SUCCESS, genericSendSuccess)
  .handleAction(UserTypes.PASSWORD_RECOVERY_ERROR, genericError)
  .handleAction(UserTypes.LOGOUT_REQUEST, genericRequest)
  .handleAction(UserTypes.LOGOUT_SUCCESS, logoutSucess);
