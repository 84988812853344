import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import SvgIcon from './components/SvgIcon';
import { IconProps } from './types';
import { Container } from './styles';

const Icon: React.FC<IconProps> = ({
  name,
  size,
  color,
  colorOnHover,
  title,
  active,
  onClick,
}) => {
  const { t: translation } = useTranslation();
  const theme = useTheme();

  return (
    <Container
      colorOnHover={colorOnHover || theme.colors.primary.base}
      title={title ? translation(`accessibility.iconTitle.${title}`) : ''}
      active={active}
      onClick={onClick}
      color={color || theme.colors.black}
    >
      <SvgIcon name={name} size={size} />
    </Container>
  );
};

export default Icon;
