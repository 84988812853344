import styled, { css } from 'styled-components';

export const Container = styled.label`
  outline: none;
  /* width: 100%; */
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  top: 1em;
  left: 0.6rem;
  position: absolute;
  padding: 0 5px;
  cursor: text;
  box-sizing: padding-box;
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};
  border-radius: 4px;
  transition: 0.1s ease-in-out;

  ${(props) =>
    props.type === 'checkbox' && 'cursor: pointer; user-select: none;'};
  ${(props) => props.checkBox === 'true' && 'font-weight: bold'};
`;

export const Field = styled.input`
  width: 100%;
  height: 100%;
  height: 2.8rem;
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.primary.base};
  border-right: ${(props) => props.toClipboard && 'none'};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  border-top-right-radius: ${(props) => props.toClipboard && 0};
  border-bottom-right-radius: ${(props) => props.toClipboard && 0};
  padding: 0.6rem;
  box-sizing: border-box;
  outline: none;
  &[type='checkbox'] {
    width: auto;
    height: min-content;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }

    + span {
      position: unset;
    }
  }

  ${(props) =>
    props.required &&
    css`
      & + span::after {
        content: ' *';
        font-weight: bold;
        color: ${props.theme.colors.error};
      }
    `}

  &:focus:not(:read-only) {
    border: 2px solid
      ${(props) =>
        props.error ? props.theme.colors.error : props.theme.colors.primary.base};
  }
  &:focus,
  &:not(:placeholder-shown) {
    &:not([type='checkbox']) {
      outline: none;
      + span {
        background-color: #fff;
        color: ${(props) => props.theme.colors.primary.base};
        font-size: 0.8rem;
        top: -7px;
      }
    }
  }
`;
