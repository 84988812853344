import React, { useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  handler: (event: MouseEvent) => void
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent): void => {
      if (!ref.current || ref.current.contains(event.target as Node)) return;
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
