import { all, takeLatest, call, put } from 'redux-saga/effects';

import plansServices from '@/lib/plans';
import handleError from '@/adapters/httpErrors';
import { PageDTO } from '@/dtos/generics';
import { PlanDTO } from '@/dtos/plan';
import history from '@/services/history';
import PlansActions, { PlansTypes } from './duck';
import plansService from '@/lib/plans';

type ListParams = ReturnType<typeof PlansActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(plansServices.list, payload.filters);
    yield put(PlansActions.listSuccess(data as PageDTO<PlanDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(PlansActions.listError(error));
  }
}

type AddParams = ReturnType<typeof PlansActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    const data = yield call(plansServices.create, payload);
    yield put(PlansActions.addSuccess(data as PlanDTO));
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(PlansActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof PlansActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(plansService.update, payload);
    yield put(PlansActions.updateSuccess());
    history.push('/plans');
  } catch (err) {
    const errors = handleError(err);
    yield put(PlansActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof PlansActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(plansService.getById, payload);
    yield put(PlansActions.getSuccess(data as PlanDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(PlansActions.getError(errors));
  }
}

export default all([
  takeLatest(PlansTypes.LIST_REQUEST, list),
  takeLatest(PlansTypes.ADD_REQUEST, add),
  takeLatest(PlansTypes.GET_REQUEST, get),
  takeLatest(PlansTypes.UPDATE_REQUEST, update),
]);
