import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import createFile from '@/utils/createFile';
import eventServices from '@/lib/events';
import EventErrorDTO from '@/dtos/events/EventErrorDTO';
import EventHandler from '@/lib/EventBuilder';
import EventDTO from '@/dtos/events/EventDTO';
import { PageDTO } from '@/dtos/generics';
import handleError from '@/adapters/httpErrors';

import EventsActions, { EventsTypes } from './duck';

const moveError = i18next.t('Success.moveError');
const moveSuccess = i18next.t('Success.moveSuccess');
const erro = i18next.t('Success.error');
const inCode = i18next.t('Success.inCode');

type EntranceParams = ReturnType<typeof EventsActions.entranceRequest>;
export function* entrance({ payload }: EntranceParams): Generator {
  try {
    yield call(eventServices.entrance, payload);
    yield put(EventsActions.entranceSuccess());
    toast.success(moveSuccess);
  } catch (err) {
    yield put(EventsActions.entranceError(err as string[]));
  }
}

type AddEventParams = ReturnType<typeof EventsActions.addEventRequest>;
export function* addEvent({ payload }: AddEventParams): Generator {
  try {
    const { codes } = payload;
    const result = yield call(
      eventServices.create,
      EventHandler.build().from(payload.data).events
    );
    const data = result as EventErrorDTO[];

    if (data.length) {
      toast.error(moveError);
      const errors = data.map(
        (err) => `${erro} ${i18next.t(err.code)} ${inCode} ${codes[err.line]}`
      );

      createFile(errors.join('\n'));
      yield put(EventsActions.addEventError(errors));
      return;
    }

    yield put(EventsActions.addEventSuccess());
    toast.success(moveSuccess);
  } catch (err) {
    // const error = handleError(err);
    yield put(EventsActions.addEventError(err as string[]));
  }
}

type ListParams = ReturnType<typeof EventsActions.getByCodeRequest>;
export function* getByCode({ payload }: ListParams): Generator {
  try {
    const data = yield call(eventServices.findByCode, payload);
    yield put(EventsActions.listSuccess(data as PageDTO<EventDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(EventsActions.listError(error));
  }
}

type ListAllParams = ReturnType<typeof EventsActions.listRequest>;
export function* list({ payload }: ListAllParams): Generator {
  try {
    const data = yield call(eventServices.list, payload);
    yield put(EventsActions.listSuccess(data as PageDTO<EventDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(EventsActions.listError(error));
  }
}

export default all([
  takeLatest(EventsTypes.ADD_EVENT_REQUEST, addEvent),
  takeLatest(EventsTypes.LIST_REQUEST, list),
  takeLatest(EventsTypes.GET_BY_CODE_REQUEST, getByCode),
  takeLatest(EventsTypes.ENTRANCE_REQUEST, entrance),
]);
