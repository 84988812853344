export default {
  translations: {
    Site: {
      initialMessage:
        'Con el sistema de seguimiento MetaTrack, tiene mayor estabilidad, inteligencia y facilidad para administrar y mover sus productos.',
      accountActive: 'Activa tu cuenta',
      passwordRecovery: '¿Olvidó su contraseña?',
      passwordRecoveryTitle: 'Recupera tu contraseña',
      newPasswordTitle: 'Establezca su nueva contraseña',
      login: 'Entra',
    },
    Label: {
      rollAmount: 'Cantidad de paquetes',
      codeAmount: 'Cantidad de códigos por paquete',
      ptBR: 'Portugués',
      enUS: 'Inglés',
      esES: 'Español',
      password: 'Contraseña',
      email: 'Correo electrónico',
      codeState: 'Generar informe de estado del código',
      dateInit: 'Data de início',
      dateEnd: 'Data de fim',
      confirmYourPassword: 'Confirma tu contraseña',
      confirmPassword: 'Confirmar contraseña',
      code: 'Código',
      packageCode: 'Código de embalaje',
      region: 'Región',
      trade: 'Denominación',
      companyName: 'Nombre de la empresa',
      municipalRegistration: 'Registro municipal',
      municipalExempt: 'Exento',
      stateRegistration: 'Registro estatal',
      stateExempt: 'Exento',
      state: 'Estado',
      city: 'Ciudad',
      address: 'Dirección',
      neighborhood: 'Bairro',
      complement: 'Complemento',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      cep: 'Código postal',
      name: 'Nombre completo',
      phone: 'Teléfono',
      office: 'Cargo',
      contactType: 'Sector',
      ownFleet: 'Flota propria',
      driverName: 'Nombre del controlador',
      destiny: 'Destino',
      licensePlate: 'Placa del vehículo',
      observation: 'Observación',
      aggregateCode: 'Código de volumen / producto',
      aggregationCode: 'Código de volumen',
      nickname: 'Nombre',
      url: 'URL',
      port: 'Puerto TCP',
      productImage: 'Imagen del producto',
      amount: 'Cantidad de cajas',
      amountVolume: 'Cantidad de volumen',
      innerBoxQuantity: 'Cantidad cajas interiores',
      amountPartitions: 'Número de colmenas',
      partitions: 'Colmeias',
      pce: 'PCE',
      country: 'País',
      factory: 'Fábrica',
      weight: 'Peso',
      unit: 'Unidad de medida',
      ean: 'EAN-13',
      dun: 'DUN-14',
      stackingLimit: 'Límite de apilamiento',
      productIdentifier: 'PCE',
      warning: 'Advertencia',
      onuNumber: 'Número ONU',
      usefulInformation: 'Informaciones importantes',
      expirationMonths: 'validez en meses',
      expirationDays: 'validez en dias',
      description: 'Descripción',
      perishable: 'Perecedero',
      oldPassword: 'Contraseña anterior',
      newPassword: 'Nueva contraseña',
      shippingCompanyName: 'Nombre o nombre de la empresa',
      productCode: 'Código de producto',
      solicitationDescription: 'Descripción de la solicitud',
      manufacturing: 'Fecha de fabricación',
      due: 'Fecha de vencimiento',
      lot: 'Lote',
      intrinsicMarking: 'Marcado intrínseco',
      partitionsAmount: 'Cantidad de colmenas',
      abbrev: 'Abreviatura',
      un: 'Número ONU',
      netWeight: 'Peso neto',
      netUnit: 'Unidad (Kg, g, ...)',
      GrossWeight: 'Peso bruto',
      GrossUnit: 'Unidad (Kg, g, ...)',
      productSearch: 'Buscar por nombre o PCE',
      employeeSearch: 'Buscar por nombre o correo electrónico',
      invoiceSearch: 'Buscar por nombre',
      searchPrinter: 'Buscar por nombre o url',
      carriersSearch: 'Buscar por nombre, CNPJ o CPF',
      activitySearch: 'Pega el código aquí',
      volumesLabelSearch: 'Buscar por código o estado',
      tr: 'TR',
      store: 'Tienda',
      clientSearch: 'Buscar por nombre o CNPJ',
      nullDate: 'Qualquier Data',
      nullLot: 'Qualquier Lote',
    },
    Button: {
      confirm: 'Confirmar',
      add: 'Adicionar',
      cancel: 'Cancelar',
      submit: 'Enviar',
      update: 'Actualizar',
      transfer: 'Transferir',
      register: 'Registrarse',
      import: 'Importar',
      addCode: 'Agregar código',
      filter: 'Filtro',
      order: 'Ordenar',
      reprint: 'Reimpresión',
      generate: 'Generate codes',
      previous: 'Anterior',
      next: 'Siguiente',
      newRequest: 'Nueva solicitud',
      downloadCodes: 'Códigos de descarga',
      requestPartitionCodes: 'Solicitar códigos de partición',
      aggregateNewPartition: 'Agregar nueva partición',
      printRequests: 'Solicitudes de impresión',
      printPartitionRequests: 'Solicitudes de partición de impresión',
      makeRequest: 'Haga una solicitud',
      makeAggregation: 'Agregado',
      print: 'Imprimir',
      printedRequests: 'Impreso',
      unprintedRequests: 'Sin imprimir',
      product: 'Produto',
      volume: 'Volumen',
      cleanFilters: 'Filtros claros',
      olderRequests: 'Solicitudes más antiguas',
      latestRequests: 'Ultimas solicitudes',
      backToDefault: 'Volver al valor predeterminado',
      dProduction: 'Producción diaria',
      choosePrinter: 'Elija una impresora',
      linkInvoice: 'Enlace NF-e',
      transferInvoice: 'Custodia de transferencia',
      download: 'Descargar',
      edit: 'Editar',
      delete: 'Eliminar',
      details: 'Detalles',
      addRep: 'Añadir delegado',
      moreOptions: 'Más opciones',
      reSendToken: 'Reenviar token',
      goBack: 'Vuelve',
    },
    SolicitationPage: {
      title: 'Solicitudes de código',
      products: 'Productos',
      partitions: 'Particiones',
      volumes: 'Volúmenes',
      impressPrint: 'Impresión',
      reprint: {
        title: 'Reimpresión de etiquetas',
      },
      tableProduct: {
        requestByProduct: 'Solicitud por producto',
        favorites: 'Favoritos',
        products: 'Productos',
        available: 'Disponible',
        print: 'Imprimir',
        generateCodes: 'Generar códigos',
        more: 'Mas',
      },
      tableVolume: {
        volumeRequest: 'Solicitud de volumen',
        amount: 'Cantidad:',
      },
      addSolicitation: {
        subTitle: 'Nueva solicitud',
        cardTitleProduct: 'Generar más código para',
        cardTitlePartition: 'Nueva solicitud de colmena',
        labelProduct: 'Producto',
        labelVolume: 'Volumen',
        selectProduct: 'Seleccionar producto ...',
        selectVolume: 'Seleccione el volumen ...',
        noProductsFound: 'No se encontraron productos',
        noVolumeFound: 'No se encontraron volúmenes',
        yup: {
          noObservation: 'Ingrese la descripción de la solicitud.',
          required: 'Ingrese el número de códigos a generar.',
          amount: 'Ingrese un número para el número de códigos de volumen.',
        },
      },
      addSolicitationModal: {
        title: 'Hacer nueva solicitud a ',
      },
      partitionAggregationModal: {
        title: 'Agregación de colmenas',
        yup: {
          volumeRequired: 'Introduzca un código de volumen.',
          productRequired: 'Introduzca un código de producto',
        },
      },
      addSolicitationPartitionModal: {
        title: 'Solicitud de partición',
      },
      print: {
        selectPrinter: 'Seleccionar impresora ...',
        noPrinterFound: 'No se encontró impresora.',
        noCodeAdded: 'No se agregó código.',
        productPrinting: 'Impresión de producto',
        partitionPrinting: 'Impresión de colmena',
        volumePrinting: 'Impresión de volumen',
        tagPrinting: 'Impresión de etiquetas',
        Disponible: 'disponible',
        volumeOf: 'Volumen de',
        availableCode: 'Código (s) disponible (s)',
        yupProduct: {
          codeRequired: 'Ingrese algún código por favor.',
          requiredLot: 'Ingrese el lote.',
          requiredObservation: 'Informar a la observación de esta operación.',
          amountRequired: 'Ingrese la cantidad de cajas a imprimir.',
          manufacturingRequired:
            'Ingrese la fecha de fabricación del producto.',
          dueRequired:
            'El producto es perecedero, informar la fecha de vencimiento.',
          intrinsicMarkingRequired: 'Ingrese el marcado intrínseco.',
          printerRequired: 'Elija una impresora.',
        },
        error: {
          description: 'Debes escribir código.',
          codeLimit: 'No puede exceder el límite de agregación.',
          thisCodeOne: 'El Código',
          thisCodeTwo: 'ya está en la lista de códigos.',
          notBelongProduct: 'Este producto no pertenece a este volumen.',
          notBelongVolume: 'Ese volumen no pertenece a este volumen.',
          exceedingLimitCodes: 'La cantidad excede el límite de',
        },
      },
      filterModal: {
        title: 'Filtrar solicitudes',
        subTitleOne: 'Filtrar por estado de impresión',
        subTitleTwo: 'Filtrar por tipo',
        subTitleThree: 'Filtrar por fecha',
      },
      orderModal: {
        title: 'Ordenar solicitudes',
        subTitleOne: 'Ordenar por nombre',
        subTitleTwo: 'Ordenar por fecha',
      },
      RePrintSolicitation: {
        headerTitle: 'Reimpresión de etiquetas',
        pageTitle: 'Reimprimir etiqueta',
      },
    },

    Volume: {
      headerTitle: 'Volúmenes',
      title: 'Volúmenes',
      total: 'Total: ',
      selectVolumeContent: 'Seleccionar contenido de volumen',
      all: 'Todos',
      addVolumes: {
        add: 'Agregar',
        edit: 'Detalles',
        cardTitle: 'Agregar un nuevo volumen',
        products: 'Productos',
        volumes: 'Volúmenes',
        selectProduct: 'Seleccionar producto ...',
        selectVolume: 'Seleccione el volumen ...',
        selectBarcode: 'Seleccione el tipo de código de barras ...',
      },
      name: 'Nombre',
      abbrev: 'Abreviatura',
      amount: 'Cantidad',
      codes: 'Códigos disponibles',
      content: 'Contenido',
      date: 'Fecha',
      actions: 'Más opciones',
      options: {
        details: 'Detalles',
        printBox: 'Imprimir',
      },
      PrintVolume: {
        headerTitle: 'Impresión',
        printCardTitle: 'Imprimir',
        internalItems: 'Elementos agregados/Elementos totales',
        availableCodes: 'códigos disponibles',
      },
    },
    Locale: {
      role: 'Reglas',
      employee: 'Empleados',
      company: 'Empresa',
      license: 'Licencias',
      shippingCompany: 'Transportista',
      solicitation: 'Solicitación',
      code: 'Código',
      invoice: 'Nota Fiscal',
      volume: 'Volumen',
      event: 'Movimiento',
      product: 'Producto',
      printer: 'Impresora',
      report: 'Informe',
      testPrintProduct: 'Prueba de impresión',
      dailyProduction: 'Producción diaria',
    },
    Error: {
      register: 'Registrarse',
      previous: 'Anterior',
      next: 'Siguiente',
      dProduction: 'Producción diaria',
      linkInvoice: 'Enlace NF-e',
      transferInvoice: 'Transferir custodia',
      productPermission: 'No tienes permiso para agregar un producto.',
      noDataSolicitation: 'No se encontraron solicitudes.',
      noDataVolume: 'No se encontraron volúmenes',
      noDataProduct: 'No se encontraron productos',
      locationPermission: 'Debes permitir el acceso a tu ubicación.',
      minorDate:
        'La fecha de vencimiento no puede ser menor que la fecha de fabricación.',
      noExistCode: 'Este código no existe en el sistema.',
      unableCode: 'Este código no se puede imprimir.',
      codeNeverPrinted: 'Este código nunca se imprimió.',
      choosePrinter: 'Elija una impresora',
      employeeAddPermission: 'No tienes permiso para registrar un empleado.',
      volumeAddPermission: 'No tienes permiso para agregar un volumen.',
      addShippingCompanyAddPermission:
        'No tienes permiso para registrar un transportista.',
      dailyProductionPermission:
        'No se le permite generar un informe sobre la producción diaria.',
      editShippingCompanyAddPermission:
        'No tienes permiso para registrar un transportista.',
      clientAddPermission: 'No tiene permiso para agregar un cliente.',
      representativeAddPermission: 'No tiene permiso para agregar un delegado.',
      clientEditPermission: 'No tiene permiso para editar un cliente.',
      representativeEditPermission:
        'No tienes permiso para editar un delegado.',
      volumeEditPermission: 'No tienes permiso para editar un volumen.',
      noPermissionToEditPrinter: 'No tiene permiso para editar una impresora.',
      employeeEditPermission: 'No tienes permiso para editar un empleado.',
      typePackageProdcut: 'Informar el tipo de embalaje',
      noBarCode: 'Ingrese el tipo de código de barras.',
      existProductIdentifier: 'Ya existe un producto con ese identificador.',
      amountProductSolicitation: 'El monto solicitado no está disponible',
      notFoundBarcode: 'No se encontró ningún tipo de código de barras.',
      serverCommunication: 'Hubo un error al comunicarse con el servidor.',
      noAddress: 'Dirección no encontrada',
      noState: 'No se encontró ningún estado',
      noCity: 'No se encontraron ciudades',
      oneField: 'Por favor, complete al menos un campo.',
      deletePermission: 'No tienes permiso para eliminar este elemento.',
      errorPassword:
        'La contraseña debe tener una letra minúscula, una letra mayúscula, un número y un carácter especial.',
      codesAmount: 'No tengo suficientes códigos',
      noPermissionToImportInvoice:
        'No tiene permiso para importar una factura.',
      noInvoiceFileAdded: 'Ingrese al menos una factura.',
      noRoleName: 'Nombra esta función.',
      noPermissionToEnableEmployee:
        'No tiene permiso para habilitar o deshabilitar un usuario.',
      accessDenied: 'Acceso denegado.',
      pageNotFound: 'Página no encontrada.',
      insufficientCodes: 'Códigos insuficientes.',
      enterCode: 'Introduzca el código.',
      codeLimitReached: 'Se alcanzó el límite de código.',
      codeAlreadyAdded: 'Código ya agregado',
      productNotBelongAggregator:
        'El código de producto agregado no pertenece a este volumen agregado.',
      volumeNotBelongAggregator:
        'El código del volumen agregado no pertenece a este volumen agregado.',
      productCodeIncompatible:
        'Este código pertenece a un producto. Agregue códigos solo para el volumen interno de este volumen agregado.',
      volumeCodeIncompatible:
        'Este código pertenece a un volumen. Agregue códigos solo para el producto interno de este volumen agregado.',
    },
    Select: {
      loadingMessage: 'Cargando...',
      function: 'Seleccionar ocupación ...',
      statesList: 'Seleccione el estado ...',
      solicitationSearch: 'Buscar por descripción',
      employeeSearch: 'Buscar por nombre o correo electrónico',
      noFunction: 'No se encontró ocupación.',
      select: 'Seleccionar ...',
      selectPacking: 'Seleccionar el paquete',
      selectBarCode: 'Seleccione el tipo de código de barras ...',
      selectCity: 'Seleccione la ciudad ...',
      noState: 'No se encontraron Estados.',
      noCity: 'No se encontraron ciudades.',
      noEventsFound: 'No se encontraron eventos.',
      selectTagType: 'Seleccione el tipo de etiqueta',
      noPrintersFound: 'No se encontraron impresoras.',
      labelTypesSelect: {
        placeholder: 'Seleccione el tipo de etiqueta.',
        noOptions: 'No se encontró ningún tipo de etiqueta.',
        optionsLabels: {
          product: 'Producto',
          volume: 'Volumen',
        },
      },
      printersSelect: {
        placeholder: 'Seleccione una impresora ...',
        noOptions: 'No se encontró ninguna impresora.',
      },
    },
    Navbar: {
      settings: 'Configuración',
      exit: 'Salir',
    },
    Yup: {
      empty: 'Este campo no puede estar vacío',
      noName: 'Ingrese el nombre',
      noEmail: 'Ingrese su correo electrónico',
      validEmail: 'El correcto electrónico debe ser válido',
      noStore: 'Ingrese la loja.',
      noPhone: 'Ingrese el teléfono.',
      noObservation: 'Ingrese una nota para esta operación.',
      noCode: 'Informar el código de enlace.',
      noAmount: 'Ingrese la cantidad.',
      noEan: 'Informar el EAN-13 del producto.',
      noDun: 'Informar al DUN-14 del embalaje.',
      noPositive: 'Este número debe ser positivo',
      noInteger: 'Este número debe ser un entero',
      noWarning: 'Ingrese la advertencia para este volumen.',
      noStackingLimit: 'Ingrese el límite de apilamiento para este volumen.',
      noDivisible:
        'La cantidad de productos debe ser divisible por la cantidad de colmenas.',
      noBarCode: 'Ingrese el tipo de código de barras.',
      noPartitions: 'Ingrese el número de colmenas.',
      noPacking: 'Informar el tipo de embalaje.',
      noCountry: 'Ingrese el código del país.',
      noFactory: 'Ingrese el código de fábrica.',
      noWeight: 'Informar la masa del producto.',
      noUnity: 'Ingrese la unidad de medida de masa.',
      noId: 'Ingrese el ID del producto',
      noUrl: 'Ingrese la URL de la impresora',
      noPort: 'Ingrese el puerto de la impresora.',
      noAbbrev: 'La abreviatura es obligatoria.',
      noNetWeight: 'Ingrese el peso neto.',
      noGrossWeight: 'Ingrese el peso bruto.',
      noProduct: 'Elija el producto.',
      noVolume: 'Elija el volumen.',
      minFixed3: 'El tamaño debe ser de 3 caracteres.',
      minFixed4: 'La longitud debe ser de 4 caracteres.',
      minFixed5: 'El tamaño debe ser de 5 caracteres.',
      min4: 'La longitud mínima debe ser de 4 caracteres',
      min5: 'El tamaño mínimo debe ser de 5 caracteres',
      max5: 'La longitud máxima debe ser de 5 caracteres',
      min8: 'La longitud mínima debe ser de 8 caracteres',
      maxFixed15: 'El número ingresado excede el límite de 15 caracteres.',
      netUnit: 'Introduzca la unidad de medida del peso neto.',
      grossUnit: 'Introduzca la unidad de medida del peso bruto.',
      noCnpj: 'Informar al CNPJ.',
      noCpf: 'Informar al CPF.',
      sizeCnpj: 'CNPJ no válido, vuelva a escribir.',
      sizeCPF: 'CPF no válido, vuelva a escribir',
      noMunicipalRegistration: 'Informar al padrón municipal.',
      noStateRegistration: 'Informar al registro estatal.',
      noCep: 'Informar al CEP',
      noTargetUf: 'Informar al Estado',
      noTargetCity: 'Informar a la ciudad.',
      noNeighborhood: 'Informar al vecindario.',
      noAddress: 'Ingrese la dirección.',
      noOffice: 'Informar la posición',
      noContactType: 'Informar al sector',
      noPassword: 'Ingrese una contraseña',
      confirmPassword: 'Las contraseñas no coinciden',
      minPassword: 'La contraseña debe tener al menos 8 dígitos',
      matchesPassword:
        'La contraseña debe tener al menos una letra minúscula, una letra, mayúscula, un número y un carácter especial.',
      requiredField: 'Campo obligatorio',
      noTrade: 'Ingrese su nombre elegante.',
      companyName: 'Introduzca el nombre de su empresa.',
      oldPassword: 'Ingrese la contraseña anterior',
      noDriver: 'Introduzca el nombre del conductor.',
      errorEAN: 'No se permiten letras en un EAN.',
      driverCPF: 'Informar al CPF del conductor.',
      errorCPF: 'CPF no válido, vuelva a escribir.',
      noDestiny: 'Informar el destino.',
      noPlate: 'Informar a la matrícula.',
      noLocation: 'Debes permitir el acceso a tu ubicación.',
      noPce: 'Informar al PCE del producto',
      codeRequest: 'Ingrese el código.',
      printerRequest: 'Elija una impresora.',
      noInnerBoxQuantity: 'Ingrese el número de artículos',
      manufacturingRequired: 'Ingrese la fecha de fabricación.',
      amountRequired: 'Ingrese una cantidad.',
      printerRequired: 'Elija una impresora.',
    },
    AddMoviment: {
      title: 'Mover código',
      noCode: 'No se agregó código.',
      options: {
        deaggregation: 'Desglose de volumen',
        location: 'Ubicación',
        cancel: 'Cancelado',
        lost: 'Perdido',
        damaged: 'Dañado',
        unlinked: 'Desvinculado de NFe',
        partition: 'Cancelar Commeia',
      },
      CodeList: {
        elementsTitle: {
          removeCode: 'Eliminar código',
        },
      },
    },
    Company: {
      title: 'Su empresa',
      infoTitle: 'Información de la empresa',
      updateTitle: 'Actualizar datos de la empresa',
    },
    Employee: {
      title: 'Empleados',
      noEmployee: 'No se encontraron empleados',
      occupation: 'Ocupación:',
      office: 'Cargo',
      user: 'Permisos de usuario',
      actions: 'Más opciones',
      total: 'Total',
      type: 'Tipo',
      register: 'Registrarse',
      addEmployee: 'Registrar un nuevo empleado',
      edit: 'Editar',
      activeTotal: 'Activo',
      disabledTotal: 'No activo',
      name: 'nombre',
      email: 'Correo electrónico',
      activeEmployee: 'Activar empleado',
      deactiveEmployee: 'Desactivar empleado',
      editEmployee: 'Editar empleado',
      disable: {
        part1: '¿Estás seguro de que quieres?',
        active: 'activar',
        deactive: 'deshabilitar',
        activation: 'activación',
        deactivation: 'desactivación',
        part2: 'este empleado?',
        part3: 'Esta es una acción de',
        part4: 'riesgo',
        part5: 'Al confirmar',
        part6: ', esta acción',
        part7:
          'tuvo efectos directos en el uso del sistema por parte del empleado',
      },
      roleLabel: 'Posición (en el sistema)',
    },
    Invoice: {
      title: 'Facturas',
      total: 'Total:',
      noMove: 'Sin movimientos',
      aggregated: 'Vinculado',
      transfered: 'Transferencia de custodia',
      nfe: 'NFe',
      selectCarrier: 'Seleccione el transportista',
      number: 'No. de productos',
      state: 'Estado:',
      date: 'Fecha',
      actions: 'Más opciones',
      linkInvoice: 'Enlace NF-e',
      details: 'Detalles',
      importTitle: 'Importación NF-e',
      noData: 'No NF-e importado.',
      aggregation: 'notas agregadas',
      trow: 'Arrastra facturas o haz clic aquí',
      noMovimets: 'Sin movimientos',
      linked: 'Vinculado',
      transfer: 'Custodia transferida',
      added: 'Agregado en',
      productsAmount: 'N ° de productos:',
      creat: 'Creado el',
      receiver: 'Destinatario',
      locality: 'Localidad',
      items: 'Artículos',
      ean: 'EAN',
      amount: 'Cantidad',
      unitsAmount: 'Cantidad de unidades interiores',
      productAmount: 'Cantidad',
      volumesAmount: 'Cantidad',
      price: 'Precio',
      ncm: 'NCM',
      unity: 'Unidad',
      noProduct: 'No se encontraron productos.',
      driverInfo: 'Información del conductor',
      info: 'Información de operación',
      importedSucesfully: 'Notas importadas correctamente.',
      InvoiceDetails: {
        CustodyTransfer: {
          invoiceNotAllowed: 'La factura debe estar vinculada o transferida.',
          selectShippingCompany: 'Seleccione el transportista...',
          noShippingCompanyFound: 'No se encontraron portadores',
        },
      },
      CustodyTransfer: {
        selectShippingCompany: 'Seleccione el transportista...',
        noShippingCompanyFound: 'No se encontraron portadores',
      },
      deleteModal: {
        part1: 'Eliminar',
        part2: 'factura',
        part3: 'Esta es una acción de',
        part4: 'riesgo',
        part5: '¿Estás seguro de que quieres',
        part6: 'eliminar',
        part7: 'esta',
        part8: 'factura?',
      },
      dailyProductionModal: {
        title: 'Producción diaria',
        text: '¿Desea descargar el archivo XML de producción diaria? (nfes exportadas en el día)',
      },
      noInvoiceAdded: 'No se agregó factura a la cola.',
    },
    Products: {
      title: 'Productos',
      filter: 'Filtrar por fecha de registro',
      addProducts: 'Registrar un nuevo producto',
      productsCodes: 'Códigos disponibles',
      register: 'Registrarse',
      noBarCode: 'Sin código de barras',
      addImage: 'Elija un archivo',
      total: 'Total:',
      details: 'Detalles',
      date: 'Fecha',
      noData: 'No hay productos registrados.',
      noPacking: 'No packaging found.',
      name: 'nombre',
      product: 'Producto',
      amount: 'Cantidad',
      actions: 'Más opciones',
      options: {
        details: 'Detalles',
        print: 'Imprimir',
      },
      info: 'Detalles',
      volumes: {
        drum: 'Tambor',
        barrel: 'Barril',
        bombona: 'Bombona',
        box: 'Caja',
        bag: 'Bolsa',
        compositePackaging: 'Embalaje compuesto',
      },
      elementsTitle: {
        deleteImageButton: 'Quita la imagen',
      },
    },
    Printers: {
      title: 'Impresoras',
      total: 'Total:',
      date: 'Fecha:',
      name: 'nombre',
      port: 'Dirección IP',
      actions: 'Más opciones',
      noData: 'No se encontró impresora.',
      add: 'Agregar',
      addTitle: 'Agregar una nueva impresora',
      select: 'Seleccionar si es una impresora USB',
      noPrinter: 'No se encontró impresora usb.',
      printer: 'Impresora',
      localPrinter: 'Impresora local',
      delete: {
        part1: '¿Estás seguro de que quieres',
        part2: 'eliminar',
        part3: 'esta impresora?',
        part4: 'Esta es una acción de',
        part5: 'riesgo',
        part6: 'Al confirmar la eliminación, esta acción',
        part7: 'no se puede deshacer',
      },
    },
    Safety: {
      title: 'Seguridad',
      list: 'Funciones registradas',
      details: 'Ver detalles de la función',
      edit: 'Editar rol',
      register: 'Registrar un nuevo rol',
      choosePermission: 'Elija permisos para este rol',
      select: 'Elegir permisos ...',
      noPermission: 'No se encontraron permisos.',
      permissionsTypesLabels: {
        role: 'Normas',
        company: 'Empresa',
        solicitation: 'Peticiones',
        product: 'Productos',
        employee: 'Empleados',
        volume: 'Volúmenes',
        invoice: 'Facturas',
        printer: 'Impresoras',
        client: 'Clientes',
        shippingCompany: 'Transportadores',
        event: 'Movimientos',
        testPrintProduct: 'Prueba de impresión',
        report: 'Reporte',
        dailyProduction: 'Producción diaria',
        code: 'Codigos',
      },
      EditRole: {
        headerTitle: 'Ocupación',
        infoCardTitle: 'Información para esta ocupación',
        roleNameLabel: 'Nombre',
        updateCardTitle: 'Rol de actualización',
      },
    },
    Permissions: {
      nothing: 'Ninguno',
      index: 'Leer',
      store: 'Crear',
      indexStore: 'Leer y crear',
      indexUpdate: 'Leer, actualizar',
      update: 'Leer, actualizar y eliminar',
      delete: 'Leer, crear, actualizar y eliminar',
    },
    Settings: {
      title: 'Configuración',
      subtitle: 'Actualice sus datos',
    },
    Report: {
      title: 'La generación del informe',
      dateInit: 'Fecha de inicio',
      dateEnd: 'Fecha de finalización',
      all: 'todo',
      codeState: 'Seleccionar para generar informe de estado del código',
      report: 'Generar informe',
      reportGenerate: 'Ejecute un informe para mostrar los datos',
      PDFGenerate: 'Generar PDF',
      daily: 'Producción diaria',
      XMLtitle: 'Informe XML',
    },
    ShippingCompanies: {
      title: 'Transportistas',
      all: 'todo',
      register: 'Registrarse',
      total: 'Total:',
      type: 'Tipo:',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      date: 'Fecha:',
      details: 'Detalles',
      noCarriers: 'No hay transportistas registrados.',
      newCarrier: 'Registrar un nuevo transportista',
      company: 'Persona Jurídica',
      selfEmployee: 'Persona Física',
      edit: 'Editar',
      companyName: 'Nombre / Nombre de la empresa',
      document: 'CNPJ / CPF',
      actions: 'Más opciones',
    },
    VolumesLabel: {
      code: 'Codigo',
      status: 'Estado',
      headerTitle: 'Etiquetas',
      titlePage: 'Impresión de codigos',
      all: 'Todas',
      options: {
        printBox: 'Imprimir',
        cancel: 'Cancelar embalaje',
      },
      print: {
        name: 'Nombre',
        id: 'Identificador',
        amount: 'Cantidad de productos',
        partitions: 'Cantidad de colmeias',
        productPrinting: 'Impresión de etiqueta de producto',
      },
      total: 'Total',
      type: 'Tipo',
      date: 'Fecha',
      range: 'Abarcar',
      details: 'Detalles',
      noCarriers: 'No se encontró ningún código.',
      company: 'Persona Jurídica',
      selfEmployee: 'Persona física',
      edit: 'Para editar',
      actions: 'Mas opciones',
      printed: 'Impreso',
      unprinted: 'No impreso',
      cancelled: 'Cancelado',
      production: 'Producción',
      partialCancelled: 'Parcialmente cancelado',
      search: 'Buscar por código o estado',
      PrintVolumeLabel: {
        headerTitle: 'Impresión',
        print: {
          title: 'Imprimir etiqueta',
          name: 'Nombre',
          id: 'Identificador',
          amount: 'Cantidad de productos',
          partitions: 'Cantidad de colmeias',
          productPrinting: 'Impresión de etiqueta de producto',
          printerSelect: {
            placeholder: 'Elige una impresora',
            noOptions: 'No se encontraron impresoras.',
          },
        },
        yup: {
          printerRequired: 'Elige una impresora.',
        },
      },
      CancelVolumeLabel: {
        headerTitle: 'Cancelar etiqueta',
        pageTitle: 'Cancelar etiqueta',
      },
      FilterStatusModal: {
        title: 'Filtrar por estado',
        selectStatusPlaceholder: 'Seleccionar estado',
        selectStatusNoOptions: 'No se encontró ningún estado',
      },
    },
    Tracking: {
      title: 'Código de pista',
      noData: 'No hubo movimientos recientes.',
      FilterModal: {
        title: 'Filtrar movimientos',
        buttons: {
          withoutFilter: 'Todos los eventos',
          writeEventsFilter: 'Escribiendo',
          readEventsFilter: 'Leer',
        },
      },
      OrderModal: {
        title: 'Ordenar movimientos',
        buttons: {
          orderByCreationDate: 'Últimos movimientos',
        },
      },
    },
    Clients: {
      title: 'Clientes',
      add: 'Añadir',
      addClient: 'Registrar un nuevo cliente',
      registerRepresentative: 'Registrar un representante',
      representative: 'Representante',
      all: 'Todo',
      noData: 'Sin clientes registrados',
      date: 'Fecha: ',
      name: 'Relación social/nombre',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      edit: 'Editar',
      total: 'Total: ',
      details: 'Detalles',
      addRepresentative: 'Añadir delegado',
      editRepresentative: 'Editar representante',
      noRepresentative: 'No añadido',
    },
    BackEndErrors: {
      'A product with this Identifier already registered.':
        'Un producto con este identificador ya existe.',
      'Insufficient partition codes.': 'Códigos de partición insuficientes.',
      'Insufficient volume codes.': 'Códigos de volumen insuficientes.',
      'Insufficient product codes.': 'Códigos de producto insuficientes.',
      'Internal server error.': 'Error interno del servidor',
      'Your license expired. Please, upgrade your plan to get access again.':
        'Su licencia ha caducado, actualice su plan de acceso.',
      'Your license offline time expired. Please, connect you server in internet to get access again.':
        'Su licencia sin conexión ha caducado, conéctese a Internet para volver a acceder.',
      'Access denied.': 'Acceso denegado.',
      'Validation error.': 'Error de validación.',
      "Passwords don't check.": 'Las contraseñas no coinciden.',
      'confirmPassword is a required field': 'Confirme la contraseña.',
      'Old and new password are the same.':
        'La nueva contraseña y la antigua son las mismas',
      'Company not found.': 'Compañía no encontrada.',
      'You do not have permission to enable or disable a employee.':
        'No tiene permitido activar o desactivar a un empleado.',

      'The company must be activated in order to register a employee.':
        'La empresa debe estar activada para dar de alta a un empleado.',
      'E-mail already registered.':
        'El correo electrónico ya ha sido registrado.',
      'You have exceeded the limit of active employees, upgrade your plan to register more.':
        'Has excedido el límite de empleados activos, actualiza tu plan para registrarte más.',
      'The company exceeded the activate employees limit. Please, upgrade your plan.':
        'La empresa ha superado el límite de empleados activos. Actualice su plan. ',
      'Employee with this email already registered.':
        'El empleado con este correo electrónico ya existe.',
      'Employee not found.': 'Empleado no encontrado.',
      "You can't change your password without inform your old password.":
        'No puede cambiar su contraseña sin ingresar su contraseña anterior.',
      'Incorrect password.': 'Contraseña incorrecta.',
      'Token already used.': 'Token ya usado.',
      'Token expired.': 'Token expired.',
      'Token not found.': 'Token no encontrado.',

      'Employee disabled.': 'Empleado discapacitado.',
      'No invoice aggregate with this code.':
        'No hay factura vinculada a este código.',
      'It is not possible to delete an invoice already aggregated.':
        'No puede eliminar una factura que ya está agregada.',
      'Invoice not found.': 'Factura no encontrada.',
      'Printer not found': 'Impresora no encontrada.',
      'A product with this PCE has already been registered.':
        'Ya se ha registrado un producto con este PCE.',
      'A product with this EAN-13 has already been registered.':
        'Un producto con este ean ya está registrado.',

      'Product not found.': 'Producto no encontrado.',
      'Permission not found.': 'Permiso no encontrado.',
      'A rule with this name has already been created.':
        'Ya se ha creado una regla con este nombre.',
      'Role not found.': 'Regla no encontrada.',
      'There is already a shipping company with this CNPJ.':
        'Ya existe un transportista con este CNPJ.',
      'There is already a shipping company with this CPF.':
        'Ya existe un transportista con este CPF.',
      'Shipping company not found.': 'Transportista no encontrado.',
      'Amount not avaliable.': 'Cantidad no disponible.',
      'This code is already aggregated to an invoice.':
        'Este código ya se ha agregado a una factura.',
      'Code not found.': 'Código no encontrado.',
      'Invalid code.': 'Código no válido',
      'The code does not belong to a volume.':
        'El código no pertenece a un volumen.',

      'Codes not found.': 'Códigos no encontrados.',
      'Only products solicitations allowed':
        'Solo se permiten solicitudes de productos.',
      'Solicitation already printed.': 'Solicitud ya impresa.',
      'Solicitation not found': 'Solicitud no encontrada',
      'Solicitation not printed.': 'Solicitud no impresa.',
      'A volume with this abbreviation has already been created.':
        'Ya se ha creado un volumen con esta abreviatura.',
      'Volume not found.': 'Volumen no encontrado.',
      'A volume with this has already been created.':
        'Ya se ha creado un volumen con este DUN-14.',
      'No files uploaded': 'Los archivos no fueron cargados.',
      'Unknown permission.': 'Permiso desconocido.',
      'This invoice was already transferred.':
        'Esta factura ya ha sido transferida.',
      'User not found.': 'Empleado no encontrado.',
      'Old password is incorrect.': 'Contraseña incorrecta.',
      'Only products requests allowed':
        'Solo se permiten solicitudes de productos',
      'You cannot change your password without informing your old password.':
        'No puede cambiar su contraseña sin informar su contraseña antigua.',
      'The company has exceeded the activate employees limit. Please, upgrade your plan.':
        'La empresa ha superado el límite de activación de empleados. Por favor, actualice su plan.',
      'The company must be activated in order to register an employee.':
        'La empresa debe estar activada para registrar a un empleado.',
      'You do not have permission to enable or disable an employee.':
        'No tiene permiso para habilitar o deshabilitar un empleado.',
      'Your license expired. Please upgrade your plan to get access again.':
        'Su licencia expiró. Actualice su plan para obtener acceso de nuevo.',
      code_not_active: 'Código no activado',
      code_not_print: 'Código no impreso',
      no_event_found: 'Evento no encontrado',
      code_already_active: 'Código ya activo',
      code_already_active_aggregation: 'Código ya activo para agregación',
      code_not_already_active_aggregation: 'Código no activo para agregación',
      code_does_not_belong_aggregation:
        'El código no pertenece a la agregación',
      code_is_not_valid: 'El código no existe',
      code_already_marked: 'Código ya impreso',
      'Communication failure.':
        'There was a communication error with the system',
    },
    Modal: {
      employeeFilter: 'Filtrar empleados',
      employeeOrder: 'Clasificar empleados',
      nameOrder: 'Orden alfabético',
      mailOrder: 'Ordenar por correo electrónico',
      dateFilter: 'Filtrar por fecha:',
      registerFilter: 'Filtrar por fecha de registro',
    },
    PrintTest: {
      title: 'Prueba de impresión',
      text: 'Seleccione un tipo de etiqueta',
    },
    SideBar: {
      solicitationsList: 'Solicitudes de lista',
      solicitationsAdd: 'Generar solicitud',
      solicitations: 'Solicitudes',
      moviments: 'Mover código',
      tracking: 'Seguimiento de código',
      products: 'Productos',
      productsList: 'Listar todos',
      productsAdd: 'Agregar',
      volumes: 'Volúmenes',
      volumesList: 'Listar todo',
      volumesAdd: 'Agregar nuevo',
      testPrint: 'Prueba de impresión',
      shippingCompanies: 'Transportistas',
      shippingCompaniesList: 'Listar todo',
      shippingCompaniesAdd: 'Registrarse',
      invoices: 'Facturas',
      invoicesList: 'Listar todo',
      invoicesAdd: 'Importar',
      printers: 'Impresoras',
      printersList: 'Listar todo',
      printersAdd: 'Agregar',
      report: 'Informe',
      employees: 'Empleados',
      volumesLabelPrint: 'Impresión de código',
      employeesList: 'Listar todos',
      employeesAdd: 'Registrarse',
      company: 'Empresa',
      security: 'Seguridad',
      settings: 'Configuración',
      clients: 'Clientes',
      clientsAdd: 'Añadir',
      clientsList: 'Lista de todos',
      reprintLabel: 'Reimpresión de etiquetas',
      dailyProduction: 'Producción diaria',
      dailyProductionXml: 'Relatório XML',
      codesProduction: 'Códigos agregados',
      printMenuOptions: 'Impressão',
      print: 'Impressão de etiquetas',
      addNewRolls: 'Agregar paquete de código',
      AssignCompanyRoll: 'Asignar códigos',
    },
    Warning: {
      invalidSession:
        'Su sesión ha expirado, por favor inicie sesión nuevamente.',
    },
    Packing: {
      title: 'Embalaje',
      titleTwo: 'Agregador de volumen',
      volume: 'Volumen:',
      maxAggregation: 'Agregaciones máximas:',
      codesForAggregation: 'Códigos para agregación',
      aggregatorVolume: 'Agregador de volumen',
      removeCode: 'Eliminar código',
      noCodesAdd: 'No se agregó código.',
      errors: {
        noVolumeCode: 'Inserte el código de volumen.',
        invalidCode: 'Código no válido.',
        noCode: 'Inserte el volumen / código de producto.',
        toMuchAggregation: 'No puede exceder el límite de agregación.',
        codeList: {
          part1: 'El código',
          part2: 'ya está en la lista de códigos.',
        },
        invalidVolumeCode: 'Este código no se puede agregar a ese volumen',
        noLocation: 'Debes permitir el acceso a tu ubicación.',
        noCodes: 'No hay códigos para realizar el movimiento.',
        oneRequired:
          'Se requiere al menos 1 código para realizar la agregación.',
      },
    },
    Success: {
      companyUpdate: '¡Empresa actualizada correctamente!',
      completeSearch: 'La búsqueda fue exitosa',
      registerEmployee: 'Empleado registrado correctamente.',
      userUpdate: 'Usuario actualizado.',
      invoiceLinked: 'NF-e aggregado.',
      invoiceTransfered:
        'Custodia de la NF-e transferida al vehículo con matrícula',
      invoiceDelete: 'Factura eliminada.',
      moveError: 'Se produjo un error al hacer el movimiento.',
      moveSuccess: 'Mover hecho.',
      printDone: 'Impresión terminada.',
      error: 'Error',
      inCode: 'Código',
      rePrintDone: 'Reimpresión realizada.',
      codesError: 'Error en los siguientes códigos:',
      printerCreated: 'Impresora creada.',
      printerUpdate: 'Impresora actualizada.',
      printerDelete: 'Impresora eliminada.',
      productAdd: 'Producto agregado.',
      productUpdate: 'Producto actualizado.',
      newOcupation: 'Función creada.',
      updateRole: 'Reglas actualizadas con éxito.',
      carrierRegister: 'Transportista registrado.',
      carrierUpdate: 'Operador actualizado.',
      solicitationDone: 'Solicitud realizada.',
      aggregationDone: 'Aggregation done.',
      successUpdate: 'Datos actualizados correctamente.',
      recoveryEmail:
        'Se ha enviado un enlace de recuperación de contraseña a su correo electrónico.',
      passwordRecovery: 'Contraseña recuperada.',
      activeLink:
        'Se ha enviado un enlace de activación a su correo electrónico.',
      registerActive: 'Cuenta activada con éxito.',
      volumeAdd: 'Volumen agregado.',
      volumeUpdate: 'Volumen actualizado.',
      roles: {
        nothing: 'Ninguno',
        index: 'Leer',
        store: 'Leer y crear',
        indexUpdate: 'Leer y actualizar',
        update: 'Leer, actualizar y eliminar',
        delete: 'Leer, crear, actualizar y eliminar',
      },
      createClient: 'Cliente creado.',
      updateClient: 'Cliente actualizado.',
      createRepresentative: 'Responsable creado.',
      updateRepresentative: 'Responsable actualizado.',
      dailyProductionFileDownloaded: 'Archivo de producción diario descargado.',
      Sagas: {
        Moviments: {
          VolumesLabel: {
            canceled: 'Etiqueta cancelada.',
          },
        },
      },
      printSend: 'Impresión enviada.',
    },
    Solicitations: {
      title: 'Solicitudes de código',
      all: 'Listas',
      total: 'Total',
      amount: 'Cantidad',
      print: 'Impresión',
      observation: 'Descripción',
      date: 'Creación',
      actions: 'Más opciones',
      options: {
        codeGenerate: 'Generar códigos',
      },
    },
    Print: {
      headerTitle: 'Impresión de etiquetas',
      pageTitle: 'Imprimir etiquetas',
      TestPrint: {
        headerTitle: 'Prueba de impresión',
        printCardTitle: 'Realice una impresión de prueba',
      },
    },
  },
};
