import { action, createReducer } from 'typesafe-actions';

import PermissionDTO from '@/dtos/roles/PermissionDTO';
import { genericError, genericRequest } from '../utils';
import { PermissionsState } from './types';
import { AnyAction, Reducer } from 'redux';

export enum PermissionsTypes {
  GET_PERMISSIONS_REQUEST = '@permissions/getPermissionsRequest',
  GET_PERMISSIONS_SUCCESS = '@permissions/getPermissionsSuccess',
  GET_PERMISSIONS_ERROR = '@permissions/getPermissionsError',
}
const PermissionsActions = {
  getPermissionsRequest: () => action(PermissionsTypes.GET_PERMISSIONS_REQUEST),
  getPermissionsSuccess: (data: PermissionDTO[]) =>
    action(PermissionsTypes.GET_PERMISSIONS_SUCCESS, { data }),
  getPermissionsError: (error: string) =>
    action(PermissionsTypes.GET_PERMISSIONS_ERROR, { error }),
};
export default PermissionsActions;

const INITIAL_STATE: PermissionsState = {
  data: [],
  loading: false,
  error: '',
};

type PermissionsReducer<Action extends AnyAction> = Reducer<
  PermissionsState,
  Action
>;
const getPermissionsSuccess: PermissionsReducer<
  ReturnType<typeof PermissionsActions.getPermissionsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  data: payload.data,
});

export const reducer = createReducer<PermissionsState>(INITIAL_STATE)
  .handleAction(PermissionsTypes.GET_PERMISSIONS_REQUEST, genericRequest)
  .handleAction(PermissionsTypes.GET_PERMISSIONS_SUCCESS, getPermissionsSuccess)
  .handleAction(PermissionsTypes.GET_PERMISSIONS_ERROR, genericError);
