import styled, { css } from 'styled-components';

import Row from './Layout/Row';

export const FieldContainer = styled(Row)`
  height: fit-content;
  position: relative;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 0.1rem;
`;

export const CheckboxContainer = styled(Row)`
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  grid-template-rows: min-content;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 0.1rem;
  border: 1px solid #232323;
  border-radius: 8px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemContainer = styled(Row)``;

export const FocusedLabel = css`
  height: fit-content;
  color: ${(props) => props.theme.colors.primary.base};
  font-size: 0.8rem;
  top: -7px;
  pointer-events: none;
  background-color: #fff;
  border-bottom: none;
`;

const focusedField = css`
  border: 2px solid ${(props) => props.theme.colors.primary.base};
  outline: none;
`;
type FieldBaseProps = {
  focused?: boolean;
  required?: boolean;
};
export const FieldBase = css<FieldBaseProps>`
  height: 2.8rem;
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.primary.base};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  padding: 0.6rem;
  outline: none;
  box-sizing: border-box;
  transition: border 0.3s;

  ${(props) => props.focused && focusedField}
  &:focus {
    ${focusedField};
  }

  ${(props) =>
    props.required &&
    css`
      & + label::after {
        content: ' *';
        font-weight: bold;
        color: ${props.theme.colors.error};
      }
    `}
`;

export const FieldArea = styled.textarea`
  ${FieldBase}
  height: auto;
  resize: none;
`;

export const Field = styled.input`
  ${FieldBase}

  &[type='checkbox'] {
    width: auto;
    margin: 7px 5px 7px 0px;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
  }

  &[type='radio']:hover,
  &:disabled:hover {
    box-shadow: none;
  }

  &:read-only {
    background-color: ${({ theme }) => theme.colors.gray.base};

    &:hover {
      box-shadow: none;
    }
  }
`;
