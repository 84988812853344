import React, { useRef, useState, useEffect } from 'react';
import { Map as LeafletMap, heatLayer } from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat/dist/leaflet-heat.js';

import { MapProps } from './types';

const Map: React.FC<MapProps> = ({
  viewport,
  isHeatMap,
  heatItems,
  children,
}) => {
  const [map, setMap] = useState<LeafletMap | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const { latitude, longitude } = viewport;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setWidth(mapRef.current?.offsetWidth || 0);
    setHeight(mapRef.current?.offsetHeight || 0);
    setLoading(false);
  }, [mapRef]);

  useEffect(() => {
    if (!map) return;
    if (isHeatMap) {
      //@ts-ignore
      heatLayer(heatItems, {
        radius: 50,
        blur: 15,
        gradient: { 0.0: 'green', 0.5: 'orange', 1.0: 'red' },
      }).addTo(map);
    }
    map.setView([latitude, longitude], viewport.zoom);
  }, [map, latitude, longitude, viewport.zoom, heatItems, isHeatMap]);

  return (
    <div ref={mapRef}>
      {loading ? (
        <></>
      ) : (
        <MapContainer
          center={[latitude, longitude]}
          whenCreated={(map: LeafletMap) => setMap(map)}
          zoom={15}
          style={{ height, width, zIndex: 98, borderRadius: '10px' }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {children}
        </MapContainer>
      )}
    </div>
  );
};

export default Map;
