import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import SearchQuery from '@/utils/SearchQuery';
import { CreatePlanParams, PlanDTO } from '@/dtos/plan';
import { PageDTO } from '@/dtos/generics';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { PlansState } from './types';
import UpdatePlanDTO from '@/dtos/plan/UpdatePlanDTO';

export enum PlansTypes {
  LIST_REQUEST = '@plans/listRequest',
  LIST_SUCCESS = '@plans/listSuccess',
  LIST_ERROR = '@plans/listError',
  ADD_REQUEST = '@plans/addRequest',
  ADD_SUCCESS = '@plans/addSuccess',
  ADD_ERROR = '@plans/addError',
  UPDATE_REQUEST = '@plans/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@plans/UPDATE_SUCCESS',
  UPDATE_ERROR = '@plans/UPDATE_ERROR',
  GET_REQUEST = '@plans/GET_REQUEST',
  GET_SUCCESS = '@plans/GET_SUCCESS',
  GET_ERROR = '@plans/GET_ERROR',
  CLEAR = '@plans/clear',
  SET_FILTERS = '@plans/setFilters',
}

const PlansActions = {
  listRequest: (filters: SearchQuery) =>
    action(PlansTypes.LIST_REQUEST, { filters }),
  listSuccess: (data: PageDTO<PlanDTO>) =>
    action(PlansTypes.LIST_SUCCESS, data),
  listError: (error: string) => action(PlansTypes.LIST_ERROR, { error }),
  addRequest: (data: CreatePlanParams) => action(PlansTypes.ADD_REQUEST, data),
  addSuccess: (data: PlanDTO) => action(PlansTypes.ADD_SUCCESS, data),
  addError: (error: string) => action(PlansTypes.ADD_ERROR, { error }),
  updateRequest: (plans: UpdatePlanDTO) =>
    action(PlansTypes.UPDATE_REQUEST, plans),
  updateSuccess: () => action(PlansTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(PlansTypes.UPDATE_ERROR, { error }),
  getRequest: (id: string) => action(PlansTypes.GET_REQUEST, id),
  getSuccess: (plans: PlanDTO) => action(PlansTypes.GET_SUCCESS, plans),
  getError: (error: string) => action(PlansTypes.GET_ERROR, { error }),

  clear: () => action(PlansTypes.CLEAR),
  setFilters: (filters: SearchQuery) =>
    action(PlansTypes.SET_FILTERS, { filters }),
};

export default PlansActions;

const INITIAL_STATE: PlansState = {
  data: [],
  selected: {} as PlanDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: SearchQuery.build(),
};

type PlansReducer<Action extends AnyAction> = Reducer<PlansState, Action>;
const listSuccess: PlansReducer<ReturnType<typeof PlansActions.listSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
});

const clear: PlansReducer<any> = () => INITIAL_STATE;

const setFilters: PlansReducer<ReturnType<typeof PlansActions.setFilters>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  filters: payload.filters,
});

const getSuccess: PlansReducer<ReturnType<typeof PlansActions.getSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  selected: payload,
  loading: false,
});

export const reducer = createReducer<PlansState>(INITIAL_STATE)
  .handleAction(PlansTypes.LIST_REQUEST, genericRequest)
  .handleAction(PlansTypes.LIST_SUCCESS, listSuccess)
  .handleAction(PlansTypes.LIST_ERROR, genericError)
  .handleAction(PlansTypes.ADD_REQUEST, genericRequest)
  .handleAction(PlansTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(PlansTypes.ADD_ERROR, genericError)
  .handleAction(PlansTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(PlansTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(PlansTypes.UPDATE_ERROR, genericError)
  .handleAction(PlansTypes.GET_REQUEST, genericRequest)
  .handleAction(PlansTypes.GET_SUCCESS, getSuccess)
  .handleAction(PlansTypes.GET_ERROR, genericError)
  .handleAction(PlansTypes.CLEAR, clear)
  .handleAction(PlansTypes.SET_FILTERS, setFilters);
