import styled from 'styled-components';

import Row from '@/components/Layout/Row';

const FieldContainer = styled(Row)`
  height: fit-content;
  position: relative;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 0.1rem;
`;

export default FieldContainer;
