import api from '@/services/api';
import { Position } from '@/services/position';

import {
  InvoiceListResultDTO,
  AddInvoiceDTO,
  InvoiceDTO,
  InvoiceTransferCustody,
} from '@/dtos/invoices';
import EventErrorDTO from '@/dtos/events/EventErrorDTO';
import SearchQuery from '@/utils/SearchQuery';

class InvoiceServices {
  async list(params: SearchQuery): Promise<InvoiceListResultDTO> {
    const { data } = await api.get<InvoiceListResultDTO>('/invoices', {
      params,
    });
    return data;
  }
  async find(invoiceId: string): Promise<InvoiceDTO> {
    const { data } = await api.get<InvoiceDTO>(`/invoices/${invoiceId}`);
    return data;
  }
  async findByAccessKey(invoiceId: string): Promise<InvoiceDTO> {
    const { data } = await api.get<InvoiceDTO>(`/invoices/${invoiceId}`);
    return data;
  }
  async transferCustodyInvoice(
    params: InvoiceTransferCustody
  ): Promise<EventErrorDTO[]> {
    const { data } = await api.post<EventErrorDTO[]>('/events/custody', params);
    return data;
  }
  async create({
    files,
    latitude,
    longitude,
    distributorId,
  }: AddInvoiceDTO): Promise<void> {
    await api.post('/invoices/import', files, {
      params: {
        latitude,
        longitude,
        distributorId,
      },
    });
  }
  async cancelInvoice(invoiceId: string, data: Position): Promise<void> {
    await api.delete<void>(`/invoices/${invoiceId}`, {
      params: data,
    });
  }
}

const invoiceServices = new InvoiceServices();

export default invoiceServices;
