import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import invoiceServices from '@/lib/invoices';
import handleError from '@/adapters/httpErrors';
import { InvoiceDTO, InvoiceListResultDTO } from '@/dtos/invoices';
import SearchQuery from '@/utils/SearchQuery';
import { history } from '@/services';

import InvoicesActions, { InvoicesTypes } from './duck';

type GetInvoicesParams = ReturnType<typeof InvoicesActions.getInvoicesRequest>;
export function* getInvoices({ payload }: GetInvoicesParams): Generator {
  try {
    const data = yield call(invoiceServices.list, payload.filters);
    yield put(InvoicesActions.getInvoicesSuccess(data as InvoiceListResultDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.getInvoicesError(error));
  }
}

type GetInvoiceParams = ReturnType<typeof InvoicesActions.getInvoiceRequest>;
export function* getInvoice({ payload }: GetInvoiceParams): Generator {
  try {
    const data = yield call(invoiceServices.find, payload.id);
    yield put(InvoicesActions.getInvoiceSuccess(data as InvoiceDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.getInvoiceError(error));
  }
}

type GetInvoiceByAccessKeyParams = ReturnType<
  typeof InvoicesActions.getInvoiceByAccessKeyRequest
>;
export function* getInvoiceByAccessKey({
  payload,
}: GetInvoiceByAccessKeyParams): Generator {
  try {
    const data = yield call(invoiceServices.findByAccessKey, payload.accessKey);
    yield put(InvoicesActions.getInvoiceByAccessKeySuccess(data as InvoiceDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.getInvoiceByAccessKeyError(error));
  }
}

type TransferCustodyParams = ReturnType<
  typeof InvoicesActions.transferCustodyRequest
>;
export function* transferCustody({
  payload,
}: TransferCustodyParams): Generator {
  try {
    yield call(invoiceServices.transferCustodyInvoice, payload);
    yield put(InvoicesActions.transferCustodySuccess());
    yield put(InvoicesActions.getInvoicesRequest(SearchQuery.build()));
    toast.success(i18next.t('success.transferCustodySuccess'));
    history.push('/invoices');
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.transferCustodyError(error));
  }
}

type CancelInvoiceParams = ReturnType<
  typeof InvoicesActions.cancelInvoiceRequest
>;
export function* cancelInvoice({ payload }: CancelInvoiceParams): Generator {
  try {
    const { invoiceId, data, onBack } = payload;
    yield call(invoiceServices.cancelInvoice, invoiceId, data);
    yield put(InvoicesActions.cancelInvoiceSuccess());
    yield put(InvoicesActions.getInvoicesRequest(SearchQuery.build()));
    onBack();
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.cancelInvoiceError(error));
  }
}

export default all([
  takeLatest(InvoicesTypes.GET_INVOICES_REQUEST, getInvoices),
  takeLatest(InvoicesTypes.GET_INVOICE_REQUEST, getInvoice),
  takeLatest(
    InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_REQUEST,
    getInvoiceByAccessKey
  ),
  takeLatest(InvoicesTypes.TRANSFER_CUSTODY_REQUEST, transferCustody),
  takeLatest(InvoicesTypes.CANCEL_INVOICE_REQUEST, cancelInvoice),
]);
