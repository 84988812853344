import api from '@/services/api';
import QuestionDTO from '@/dtos/question/QuestionDTO';
import CreateQuestionDTO from '@/dtos/question/CreateQuestionDTO';

const questionProvider = {
  async listAllResourceQuestions({
    resourceId,
  }: {
    resourceId: string;
  }): Promise<QuestionDTO[]> {
    const { data } = await api.get<QuestionDTO[]>(`/questions/${resourceId}`);
    return data;
  },
  async delete(id: string): Promise<void> {
    await api.delete(`/questions/${id}`);
  },
  async create(question: CreateQuestionDTO): Promise<QuestionDTO> {
    const { data } = await api.post<QuestionDTO>('/questions', question);
    return data;
  },
};

export default questionProvider;
