import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import ResourceActions, { ResourceFieldTypes } from './duck';
import history from '@/services/history';
import resourceFieldProvider from '@/lib/resourceField';
import ResourceFieldActions from './duck';
import { ResourceFieldDTO } from '@/dtos/resourceField';

type AddParams = ReturnType<typeof ResourceFieldActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(resourceFieldProvider.create, payload.data);
    yield put(ResourceFieldActions.addSuccess());
    history.push('/resource-fields');
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.addError(errors));
  }
}

type SignUpParams = ReturnType<typeof ResourceFieldActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const data = yield call(resourceFieldProvider.list, payload.filters);
    yield put(ResourceFieldActions.listSuccess(data as ResourceFieldDTO[]));
  } catch (err) {
    const error = handleError(err);
    yield put(ResourceFieldActions.listError(error));
  }
}

type GetParams = ReturnType<typeof ResourceFieldActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(resourceFieldProvider.getById, payload);
    yield put(ResourceFieldActions.getSuccess(data as ResourceFieldDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceFieldActions.getError(errors));
  }
}

type UpdateParams = ReturnType<typeof ResourceFieldActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(resourceFieldProvider.update, payload);
    yield put(ResourceFieldActions.updateSuccess());
    history.push('/resource-fields');
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceFieldActions.updateError(errors));
  }
}
type ToggleParams = ReturnType<
  typeof ResourceFieldActions.toggleVisibilityRequest
>;
export function* toggleResource({ payload }: ToggleParams): Generator {
  try {
    const data = yield call(resourceFieldProvider.toggleResourceState, payload);
    yield put(ResourceFieldActions.getSuccess(data as ResourceFieldDTO));
    yield put(ResourceFieldActions.toggleVisibilitySuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceFieldActions.toggleVisibilityError(errors));
  }
}

export default all([
  takeLatest(ResourceFieldTypes.ADD_REQUEST, add),
  takeLatest(ResourceFieldTypes.LIST_REQUEST, list),
  takeLatest(ResourceFieldTypes.UPDATE_REQUEST, update),
  takeLatest(ResourceFieldTypes.TOGGLE_VISIBILITY_REQUEST, toggleResource),
  takeLatest(ResourceFieldTypes.GET_REQUEST, get),
]);
