import api from '@/services/api';
import {
  CreatePrinterDTO,
  PrinterDTO,
  UpdatePrinterDTO,
} from '@/dtos/printers';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';

const printerProvider = {
  async list(filters: SearchQuery): Promise<PageDTO<PrinterDTO>> {
    const { data } = await api.get<PageDTO<PrinterDTO>>(`/printers`, {
      params: filters.query,
    });
    return data;
  },
  async getById(id: string): Promise<PrinterDTO> {
    const { data } = await api.get<PrinterDTO>(`/printers/${id}`);
    return data;
  },
  async create(printer: CreatePrinterDTO): Promise<PrinterDTO> {
    const { data } = await api.post<PrinterDTO>('/printers', printer);
    return data;
  },
  async update(printer: UpdatePrinterDTO): Promise<PrinterDTO> {
    const { data } = await api.put<PrinterDTO>(
      `/printers/${printer.id}`,
      printer
    );
    return data;
  },
  async delete(id: string): Promise<void> {
    await api.delete(`/printers/${id}`);
  },
};

export default printerProvider;
