import { all, takeLatest, call, put } from 'redux-saga/effects';

import statusFieldServices from '@/lib/statusField';
import handleError from '@/adapters/httpErrors';
import { StatusFieldDTO } from '@/dtos/statusField';
import history from '@/services/history';

import StatusFieldActions, { StatusFieldTypes } from './duck';

type ListParams = ReturnType<typeof StatusFieldActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(statusFieldServices.list, payload);
    yield put(StatusFieldActions.listSuccess(data as StatusFieldDTO[]));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusFieldActions.listError(error));
  }
}

type GetParams = ReturnType<typeof StatusFieldActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const status = yield call(statusFieldServices.get, payload);
    yield put(StatusFieldActions.getSuccess(status as StatusFieldDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusFieldActions.getError(error));
  }
}

type AddParams = ReturnType<typeof StatusFieldActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(statusFieldServices.create, payload);
    yield put(StatusFieldActions.addSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusFieldActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof StatusFieldActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(statusFieldServices.update, payload);
    yield put(StatusFieldActions.updateSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusFieldActions.updateError(error));
  }
}

export default all([
  takeLatest(StatusFieldTypes.LIST_REQUEST, list),
  takeLatest(StatusFieldTypes.GET_REQUEST, get),
  takeLatest(StatusFieldTypes.ADD_REQUEST, add),
  takeLatest(StatusFieldTypes.UPDATE_REQUEST, update),
]);
