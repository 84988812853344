import styled from 'styled-components';
import { IconButton } from '@/components/Buttons';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;
  position: relative;

  > input:read-only {
    border-radius: ${(props) => props.theme.sizes.borderRadius} 0 0
      ${(props) => props.theme.sizes.borderRadius};
    border-right: none;
  }
`;

export const ClipButton = styled(IconButton)`
  padding: 0 1rem;
  background-color: #f0f3f7;
  border: 1px solid ${(props) => props.theme.colors.primary.base};
  border-left: none;
  border-radius: 0 ${(props) => props.theme.sizes.borderRadius}
    ${(props) => props.theme.sizes.borderRadius} 0;
  &:hover {
  }
`;
