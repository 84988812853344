import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import SearchQuery from '@/utils/SearchQuery';
import { CompanyDTO, CreateCompanyDTO, UpdateCompanyDTO } from '@/dtos/company';
import { PageDTO } from '@/dtos/generics';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { CompaniesState } from './types';

export enum CompaniesTypes {
  LIST_REQUEST = '@companies/listRequest',
  LIST_SUCCESS = '@companies/listSuccess',
  LIST_ERROR = '@companies/listError',
  ADD_REQUEST = '@companies/addRequest',
  ADD_SUCCESS = '@companies/addSuccess',
  ADD_ERROR = '@companies/addError',
  UPDATE_REQUEST = '@companies/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@companies/UPDATE_SUCCESS',
  UPDATE_ERROR = '@companies/UPDATE_ERROR',
  GET_REQUEST = '@companies/GET_REQUEST',
  GET_SUCCESS = '@companies/GET_SUCCESS',
  GET_ERROR = '@companies/GET_ERROR',
  CLEAR = '@companies/clear',
  SET_FILTERS = '@companies/setFilters',
}

const CompaniesActions = {
  listRequest: (filters: SearchQuery) =>
    action(CompaniesTypes.LIST_REQUEST, { filters }),
  listSuccess: (data: PageDTO<CompanyDTO>) =>
    action(CompaniesTypes.LIST_SUCCESS, data),
  listError: (error: string) => action(CompaniesTypes.LIST_ERROR, { error }),
  addRequest: (data: CreateCompanyDTO) =>
    action(CompaniesTypes.ADD_REQUEST, data),
  addSuccess: (data: CompanyDTO) => action(CompaniesTypes.ADD_SUCCESS, data),
  addError: (error: string) => action(CompaniesTypes.ADD_ERROR, { error }),
  updateRequest: (companies: UpdateCompanyDTO) =>
    action(CompaniesTypes.UPDATE_REQUEST, companies),
  updateSuccess: () => action(CompaniesTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(CompaniesTypes.UPDATE_ERROR, { error }),
  getRequest: (id: string) => action(CompaniesTypes.GET_REQUEST, id),
  getSuccess: (companies: CompanyDTO) =>
    action(CompaniesTypes.GET_SUCCESS, companies),
  getError: (error: string) => action(CompaniesTypes.GET_ERROR, { error }),
  clear: () => action(CompaniesTypes.CLEAR),
  setFilters: (filters: SearchQuery) =>
    action(CompaniesTypes.SET_FILTERS, { filters }),
};

export default CompaniesActions;

const INITIAL_STATE: CompaniesState = {
  data: [],
  selected: {} as CompanyDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: SearchQuery.build(),
};

type CompaniesReducer<Action extends AnyAction> = Reducer<
  CompaniesState,
  Action
>;
const listSuccess: CompaniesReducer<
  ReturnType<typeof CompaniesActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
});

const clear: CompaniesReducer<any> = () => INITIAL_STATE;

const setFilters: CompaniesReducer<
  ReturnType<typeof CompaniesActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const getSuccess: CompaniesReducer<
  ReturnType<typeof CompaniesActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

export const reducer = createReducer<CompaniesState>(INITIAL_STATE)
  .handleAction(CompaniesTypes.LIST_REQUEST, genericRequest)
  .handleAction(CompaniesTypes.LIST_SUCCESS, listSuccess)
  .handleAction(CompaniesTypes.LIST_ERROR, genericError)
  .handleAction(CompaniesTypes.ADD_REQUEST, genericRequest)
  .handleAction(CompaniesTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(CompaniesTypes.ADD_ERROR, genericError)
  .handleAction(CompaniesTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(CompaniesTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(CompaniesTypes.UPDATE_ERROR, genericError)
  .handleAction(CompaniesTypes.GET_REQUEST, genericRequest)
  .handleAction(CompaniesTypes.GET_SUCCESS, getSuccess)
  .handleAction(CompaniesTypes.GET_ERROR, genericError)
  .handleAction(CompaniesTypes.CLEAR, clear)
  .handleAction(CompaniesTypes.SET_FILTERS, setFilters);
