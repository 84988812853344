import styled, { css } from 'styled-components';

const focusedLabel = css`
  height: fit-content;
  color: ${(props) => props.theme.colors.primary.base};
  font-size: 0.8rem;
  top: -7px;
  pointer-events: none;
  background-color: #fff;
  border-bottom: none;
`;

export default styled.label`
  top: 0;
  left: 0.6rem;
  right: 0;
  width: fit-content;
  height: 2.8rem;
  display: flex;
  position: absolute;
  align-items: center;
  padding: 0 5px;
  cursor: text;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};
  border-radius: 4px;
  transition: 0.1s ease-in-out;

  ${(props) => props.focused && focusedLabel}
`;
