import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Container, Label, Field } from './styles';

const Input = forwardRef(
  ({ label, id: identify, placeholder, error, ...rest }, ref) => {
    const { t: translation } = useTranslation();
    const id = identify || uuid();
    const placeHolder = placeholder || ' ';

    return (
      <Container htmlFor={id}>
        <Field
          id={id}
          {...rest}
          ref={ref}
          placeholder={placeHolder}
          error={error}
        />
        {label && (
          <Label type={rest.type} checkBox={rest.checkBox}>
            {translation(`Label.${label}`)}
          </Label>
        )}
      </Container>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  id: '',
  placeholder: '',
  error: null,
};

export default Input;
