import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import SearchQuery from '@/utils/SearchQuery';
import { FeatureDTO } from '@/dtos/feature';
import { PageDTO } from '@/dtos/generics';
import { genericError, genericRequest } from '../utils';
import { FeaturesState } from './types';

export enum FeaturesTypes {
  LIST_REQUEST = '@features/listRequest',
  LIST_SUCCESS = '@features/listSuccess',
  LIST_ERROR = '@features/listError',
  CLEAR = '@features/clear',
  SET_FILTERS = '@features/setFilters',
}

const FeaturesActions = {
  listRequest: (filters: SearchQuery) =>
    action(FeaturesTypes.LIST_REQUEST, { filters }),
  listSuccess: (data: PageDTO<FeatureDTO>) =>
    action(FeaturesTypes.LIST_SUCCESS, data),
  listError: (error: string) => action(FeaturesTypes.LIST_ERROR, { error }),
  clear: () => action(FeaturesTypes.CLEAR),
  setFilters: (filters: SearchQuery) =>
    action(FeaturesTypes.SET_FILTERS, { filters }),
};

export default FeaturesActions;

const INITIAL_STATE: FeaturesState = {
  data: [],
  selected: {} as FeatureDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: SearchQuery.build(),
};

type FeaturesReducer<Action extends AnyAction> = Reducer<FeaturesState, Action>;
const listSuccess: FeaturesReducer<
  ReturnType<typeof FeaturesActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
});

const clear: FeaturesReducer<any> = () => INITIAL_STATE;

const setFilters: FeaturesReducer<
  ReturnType<typeof FeaturesActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<FeaturesState>(INITIAL_STATE)
  .handleAction(FeaturesTypes.LIST_REQUEST, genericRequest)
  .handleAction(FeaturesTypes.LIST_SUCCESS, listSuccess)
  .handleAction(FeaturesTypes.LIST_ERROR, genericError)
  .handleAction(FeaturesTypes.CLEAR, clear)
  .handleAction(FeaturesTypes.SET_FILTERS, setFilters);
