import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { StatusFieldDTO, CreateStatusFieldDTO } from '@/dtos/statusField';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { StatusFieldState } from './types';
import SearchQuery from '@/utils/SearchQuery';

export enum StatusFieldTypes {
  SET_FILTERS = '@statusField/setFilters',

  LIST_REQUEST = '@statusField/listRequest',
  LIST_SUCCESS = '@statusField/listSuccess',
  LIST_ERROR = '@statusField/listError',

  GET_REQUEST = '@statusField/getRequest',
  GET_SUCCESS = '@statusField/getSuccess',
  GET_ERROR = '@statusField/getError',

  ADD_REQUEST = '@statusField/addRequest',
  ADD_SUCCESS = '@statusField/addSuccess',
  ADD_ERROR = '@statusField/addError',

  UPDATE_REQUEST = '@statusField/updateRequest',
  UPDATE_SUCCESS = '@statusField/updateSuccess',
  UPDATE_ERROR = '@statusField/updateError',

  CLEAR_SELECTED = '@statusField/clearSelected',
}

const StatusFieldActions = {
  setFilters: (filters: SearchQuery) =>
    action(StatusFieldTypes.SET_FILTERS, filters),

  listRequest: (filters: SearchQuery) =>
    action(StatusFieldTypes.LIST_REQUEST, filters),
  listSuccess: (page: StatusFieldDTO[]) =>
    action(StatusFieldTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(StatusFieldTypes.LIST_ERROR, { error }),

  getRequest: (id: string) => action(StatusFieldTypes.GET_REQUEST, id),
  getSuccess: (status: StatusFieldDTO) =>
    action(StatusFieldTypes.GET_SUCCESS, status),
  getError: (error: string) => action(StatusFieldTypes.GET_ERROR, { error }),

  addRequest: (status: CreateStatusFieldDTO) =>
    action(StatusFieldTypes.ADD_REQUEST, status),
  addSuccess: () => action(StatusFieldTypes.ADD_SUCCESS),
  addError: (error: string) => action(StatusFieldTypes.ADD_ERROR, { error }),

  updateRequest: (status: StatusFieldDTO) =>
    action(StatusFieldTypes.UPDATE_REQUEST, status),
  updateSuccess: () => action(StatusFieldTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(StatusFieldTypes.UPDATE_ERROR, { error }),

  clearSelected: () => action(StatusFieldTypes.CLEAR_SELECTED),
};
export default StatusFieldActions;

const INITIAL_STATE: StatusFieldState = {
  data: [],
  selected: {} as StatusFieldDTO,
  filters: SearchQuery.build(),
  error: '',
  loading: false,
};

export type StatusReducer<Action extends AnyAction> = Reducer<
  StatusFieldState,
  Action
>;

const setFilters: StatusReducer<
  ReturnType<typeof StatusFieldActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload,
  error: '',
  loading: false,
});

const listSuccess: StatusReducer<
  ReturnType<typeof StatusFieldActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload,
  error: '',
  loading: false,
});

const getSuccess: StatusReducer<
  ReturnType<typeof StatusFieldActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  error: '',
  loading: false,
});

const clearSelected: StatusReducer<
  ReturnType<typeof StatusFieldActions.clearSelected>
> = (state = INITIAL_STATE) => ({
  ...state,
  selected: INITIAL_STATE.selected,
  error: '',
  loading: false,
});

export const reducer = createReducer<StatusFieldState>(INITIAL_STATE)
  .handleAction(StatusFieldTypes.SET_FILTERS, setFilters)
  .handleAction(StatusFieldTypes.LIST_REQUEST, genericRequest)
  .handleAction(StatusFieldTypes.LIST_SUCCESS, listSuccess)
  .handleAction(StatusFieldTypes.LIST_ERROR, genericError)
  .handleAction(StatusFieldTypes.GET_REQUEST, genericRequest)
  .handleAction(StatusFieldTypes.GET_SUCCESS, getSuccess)
  .handleAction(StatusFieldTypes.GET_ERROR, genericError)
  .handleAction(StatusFieldTypes.ADD_REQUEST, genericRequest)
  .handleAction(StatusFieldTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(StatusFieldTypes.ADD_ERROR, genericError)
  .handleAction(StatusFieldTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(StatusFieldTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(StatusFieldTypes.UPDATE_ERROR, genericError)
  .handleAction(StatusFieldTypes.CLEAR_SELECTED, clearSelected);
