import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { genericError, genericRequest } from '../utils';
import { CodesState } from './types';
import CodeDTO from '@/dtos/solicitations/CodeDTO';
import PageDTO from '@/dtos/generics/PageDTO';
import SearchQuery from '@/utils/SearchQuery';

export enum CodesTypes {
  GET_EVENTS_REQUEST = '@codes/getEventsRequest',
  GET_EVENTS_SUCCESS = '@codes/getEventsSuccess',
  GET_EVENTS_ERROR = '@codes/getEventsError',
  SET_FILTERS = '@codes/SET_FILTERS',
  LIST_REQUEST = '@codes/listRequest',
  LIST_SUCCESS = '@codes/listSuccess',
  LIST_ERROR = '@codes/listError',
  CLEAR_EVENTS = '@codes/clearEvents',
  SET_SOLICITATION = '@codes/setSolicitation',
  CLEAR_SELECTED = '@codes/clearSelected',
  GET_CODE_REQUEST = '@codes/getCodeRequest',
  GET_CODE_SUCCESS = '@codes/getCodeSuccess',
  GET_CODE_ERROR = '@codes/getCodeError',
}
const CodesActions = {
  setFilters: (filters: SearchQuery) =>
    action(CodesTypes.SET_FILTERS, { filters }),
  listRequest: (filters: SearchQuery) =>
    action(CodesTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<CodeDTO>) =>
    action(CodesTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(CodesTypes.LIST_ERROR, { error }),
  clearEvents: () => action(CodesTypes.CLEAR_EVENTS),
  clearSelected: () => action(CodesTypes.CLEAR_SELECTED),
  getCodeRequest: (value: string) =>
    action(CodesTypes.GET_CODE_REQUEST, { value }),
  getCodeSuccess: (code: CodeDTO) =>
    action(CodesTypes.GET_CODE_SUCCESS, { code }),
  getCodeError: (error: string) =>
    action(CodesTypes.GET_CODE_SUCCESS, { error }),
};
export default CodesActions;

const INITIAL_STATE: CodesState = {
  selectedCode: {} as CodeDTO,
  data: [],
  codes: [],
  events: [],
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  next: undefined,
  prev: undefined,
  total: 0,
  users: [],
};

export type CodesReducer<Action extends AnyAction> = Reducer<
  CodesState,
  Action
>;

const listSuccess: CodesReducer<ReturnType<typeof CodesActions.listSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const clearEvents: CodesReducer<
  ReturnType<typeof CodesActions.clearEvents>
> = () => INITIAL_STATE;

const setFilters: CodesReducer<ReturnType<typeof CodesActions.setFilters>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  filters: payload.filters,
});

const getCodeSuccess: CodesReducer<
  ReturnType<typeof CodesActions.getCodeSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedCode: payload.code,
  error: '',
  loading: false,
});

const clearSelected: CodesReducer<
  ReturnType<typeof CodesActions.clearSelected>
> = (state = INITIAL_STATE) => ({
  ...state,
  selectedCode: {} as CodeDTO,
});

export const reducer = createReducer<CodesState>(INITIAL_STATE)
  .handleAction(CodesTypes.SET_FILTERS, setFilters)
  .handleAction(CodesTypes.LIST_REQUEST, genericRequest)
  .handleAction(CodesTypes.GET_CODE_REQUEST, genericRequest)
  .handleAction(CodesTypes.GET_CODE_SUCCESS, getCodeSuccess)
  .handleAction(CodesTypes.GET_CODE_ERROR, genericError)
  .handleAction(CodesTypes.LIST_SUCCESS, listSuccess)
  .handleAction(CodesTypes.LIST_ERROR, genericError)
  .handleAction(CodesTypes.CLEAR_EVENTS, clearEvents)
  .handleAction(CodesTypes.CLEAR_SELECTED, clearSelected);
