import { ResourceDTO } from '@/dtos/resource';
import { ToggleResourceType } from '@/dtos/resource/ToggleResourceDTO';
import { CreateResourceFieldDTO } from '@/dtos/resourceField';
import UpdateResourceFieldDTO from '@/dtos/resourceField/ UpdateResourceFieldDTO';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import { api } from '@/services';
import SearchQuery from '@/utils/SearchQuery';

const resourceFieldProvider = {
  async list(filters?: SearchQuery): Promise<ResourceFieldDTO[]> {
    const { data } = await api.get<ResourceFieldDTO[]>(`/resource-fields`, {
      params: filters?.query,
    });
    return data;
  },

  async create(
    resourceField: CreateResourceFieldDTO
  ): Promise<ResourceFieldDTO> {
    const { data } = await api.post<ResourceFieldDTO>(
      '/resource-fields',
      resourceField
    );
    return data;
  },
  async getById(id: string): Promise<ResourceFieldDTO> {
    const { data } = await api.get<ResourceFieldDTO>(`/resource-fields/${id}`);
    return data;
  },
  async update(
    resourceField: UpdateResourceFieldDTO
  ): Promise<ResourceFieldDTO> {
    const { data } = await api.put<ResourceFieldDTO>(
      `/resource-fields/${resourceField.id}`,
      resourceField
    );
    return data;
  },
  async toggleResourceState({
    name,
    value,
    id,
  }: ToggleResourceType): Promise<ResourceDTO> {
    const { data } = await api.put<ResourceDTO>(
      `/resource-fields/toggle/${id}`,
      {
        name,
        value,
      }
    );
    return data;
  },
};
export default resourceFieldProvider;
