import React, { useCallback, useEffect, useState } from 'react';

import { Select, SelectOption } from '@/components';
import { StringSet } from '@/utils/setTypes';
import SearchQuery from '@/utils/SearchQuery';
import { BaseEntityDTO, PageDTO } from '@/dtos/generics';

type Entity = BaseEntityDTO & {
  name?: string;
};
type MapToOptions<T> = (item: T) => SelectOption;
type SelectEntityProps<T extends Entity> = {
  value: string;
  onChange: StringSet;
  mapToOptions?: MapToOptions<T>;
  loadItems: (filter: SearchQuery) => Promise<PageDTO<T>>;
  placeholder?: string;
  searchField?: string;
  noOptionsMessage?: () => string;
  callbackChange?: (item: string) => string;
};
const genericMap: MapToOptions<Entity> = ({ id, name }) => ({
  label: name || '',
  value: id,
});
function SelectEntity<T extends Entity>({
  value,
  onChange,
  mapToOptions,
  loadItems,
  placeholder,
  searchField = 'name',
  noOptionsMessage,
  callbackChange = (item: string) => item,
}: SelectEntityProps<T>): React.FunctionComponentElement<SelectEntityProps<T>> {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<T[]>([]);
  const options = entities.map(mapToOptions || genericMap);
  const selected = options.find((item) => item.value === value);
  const loadEntities = useCallback(
    async (text: string): Promise<void> => {
      try {
        setLoading(true);
        const { data } = await loadItems(
          !text
            ? SearchQuery.build()
            : SearchQuery.build().like(searchField, text)
        );
        setEntities(data);
        setLoading(false);
      } catch (_) {
        setLoading(false);
      }
    },
    [loadItems, searchField]
  );

  useEffect(() => {
    loadEntities(input);
  }, [loadEntities, input]);

  return (
    <Select
      isLoading={loading}
      onInputChange={(e: string) => setInput(callbackChange(e as string))}
      options={options}
      inputValue={input}
      value={selected}
      onChange={(e: SelectOption) => onChange(e?.value)}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
    />
  );
}

export default SelectEntity;
