import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ content }) => (
  <div>
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content || '' }}
    />
  </div>
);

Iframe.propTypes = {
  content: PropTypes.string,
};

Iframe.defaultProps = {
  content: '',
};

export default Iframe;
