import { all, takeLatest, call, put, StrictEffect } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18next from '@/locale';

import roleServices from '@/lib/roles';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import PageDTO from '@/dtos/generics/PageDTO';
import { RoleDTO } from '@/dtos/roles';
import RolesActions, { RolesTypes } from './duck';

const newOcupation = i18next.t('Success.newOcupation');
const updateRole = i18next.t('Success.updateRole');

export function* getRoles(): Generator {
  try {
    const data = yield call(roleServices.list);
    yield put(RolesActions.getRolesSuccess(data as PageDTO<RoleDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.getRolesError(error));
  }
}

type AddRoleParams = ReturnType<typeof RolesActions.addRoleRequest>;
export function* addRole({ payload }: AddRoleParams): Generator {
  try {
    yield call(roleServices.create, payload.data);
    toast.success(newOcupation);
    yield put(RolesActions.addRoleSuccess());
    yield put(RolesActions.getRolesRequest());
    history.push('/security');
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.addRoleError(error));
  }
}

type GetRoleParams = ReturnType<typeof RolesActions.getRoleRequest>;
export function* getRole({ payload }: GetRoleParams): Generator {
  try {
    const data = yield call(roleServices.find, payload.id);
    yield put(RolesActions.getRoleSuccess(data as RoleDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.getRoleError(error));
  }
}

type UpdateRolesParams = ReturnType<typeof RolesActions.updateRolesRequest>;
export function* updateRoles({
  payload,
}: UpdateRolesParams): Generator<StrictEffect, void, RoleDTO> {
  try {
    const data = yield call(roleServices.update, payload.data);
    yield put(RolesActions.updateRolesSuccess(data));
    toast.success(updateRole);
    history.push('/security');
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.updateRolesError(error));
  }
}

export default all([
  takeLatest(RolesTypes.GET_ROLES_REQUEST, getRoles),
  takeLatest(RolesTypes.GET_ROLE_REQUEST, getRole),
  takeLatest(RolesTypes.ADD_ROLE_REQUEST, addRole),
  takeLatest(RolesTypes.UPDATE_ROLES_REQUEST, updateRoles),
]);
