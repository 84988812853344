import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import locationProvider from '@/lib/locations';
import { history } from '@/services';

import LocationActions, { LocationTypes } from './duck';
import { PageDTO } from '@/dtos/generics';
import { LocationDTO } from '@/dtos/locations';

type AddParams = ReturnType<typeof LocationActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(locationProvider.create, payload);
    yield put(LocationActions.addSuccess());
    history.push('/locations');
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationActions.addError(errors));
  }
}
type ListParams = ReturnType<typeof LocationActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const result = yield call(locationProvider.list, payload.filters);
    yield put(LocationActions.listSuccess(result as PageDTO<LocationDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationActions.listError(errors));
  }
}
type GetParams = ReturnType<typeof LocationActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(locationProvider.find, payload);
    yield put(LocationActions.getSuccess(data as LocationDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationActions.getError(errors));
  }
}
type UpdateParams = ReturnType<typeof LocationActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(locationProvider.update, payload);
    yield put(LocationActions.updateSuccess());
    history.push('/locations');
  } catch (err) {
    const errors = handleError(err);
    yield put(LocationActions.updateError(errors));
  }
}

export default all([
  takeLatest(LocationTypes.ADD_REQUEST, add),
  takeLatest(LocationTypes.LIST_REQUEST, list),
  takeLatest(LocationTypes.UPDATE_REQUEST, update),
  takeLatest(LocationTypes.GET_REQUEST, get),
]);
