import React from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from '@/services/history';
import Auth from './Auth';
import App from './App';

function Routes() {
  const signed = useSelector(({ user }) => !!user.token);
  const RouteComponent = signed ? App : Auth;

  return (
    <Router history={history}>
      <RouteComponent />
    </Router>
  );
}

export default Routes;
