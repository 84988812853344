import { combineReducers } from 'redux';

import { reducer as user } from './user/duck';
import { reducer as users } from './users/duck';
import { reducer as companies } from './companies/duck';
import { reducer as events } from './events/duck';
import { reducer as features } from './features/duck';
import { reducer as codes } from './codes/duck';
import { reducer as roles } from './roles/duck';
import { reducer as permissions } from './permissions/duck';
import { reducer as plans } from './plans/duck';
import { reducer as sidebar } from './sidebar/duck';
import { reducer as status } from './status/duck';
import { reducer as statusField } from './statusField/duck';
import { reducer as invoices } from './invoices/duck';
import { reducer as resources } from './resources/duck';
import { reducer as resourceField } from './resourceField/duck';
import { reducer as locationPositions } from './locationPositions/duck';
import { reducer as locations } from './locations/duck';
import { reducer as printers } from './printers/duck';
import { reducer as rolls } from './rolls/duck';
import { reducer as questions } from './question/duck';
import { reducer as interactions } from './interaction/duck';
import { reducer as licensedCodes } from './licensedCodes/duck';
import { reducer as answers } from './answers/duck';
import { reducer as aggregations } from './aggregations/duck';

const reducers = combineReducers({
  answers,
  interactions,
  user,
  users,
  companies,
  events,
  features,
  codes,
  roles,
  permissions,
  sidebar,
  status,
  statusField,
  invoices,
  resources,
  resourceField,
  plans,
  locationPositions,
  locations,
  printers,
  rolls,
  questions,
  licensedCodes,
  aggregations,
});

export default reducers;
