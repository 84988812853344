import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { LocationState } from './types';

import { FilterDTO, PageDTO } from '@/dtos/generics';
import UpdateLocationDTO from '@/dtos/locations/UpdateLocationDTO';
import { LocationDTO, CreateLocationDTO } from '@/dtos/locations';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: LocationState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  total: 0,
  error: '',
  filters: SearchQuery.build(),
  selected: {
    name: '',
    userId: '',
  } as LocationDTO,
};

export enum LocationTypes {
  SET_FILTERS = '@/setFilters',

  ADD_REQUEST = '@locations/ADD_REQUEST',
  ADD_SUCCESS = '@locations/ADD_SUCCESS',
  ADD_ERROR = '@locations/ADD_ERROR',

  LIST_REQUEST = '@locations/LIST_REQUEST',
  LIST_SUCCESS = '@locations/LIST_SUCCESS',
  LIST_ERROR = '@locations/LIST_ERROR',

  GET_REQUEST = '@locations/GET_REQUEST',
  GET_SUCCESS = '@locations/GET_SUCCESS',
  GET_ERROR = '@locations/GET_ERROR',

  UPDATE_REQUEST = '@locations/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@locations/UPDATE_SUCCESS',
  UPDATE_ERROR = '@locations/UPDATE_ERROR',

  CLEAR = '@locations/CLEAR',
}

const LocationActions = {
  setFilters: (filters: SearchQuery) =>
    action(LocationTypes.SET_FILTERS, { filters }),

  addRequest: (data: CreateLocationDTO) =>
    action(LocationTypes.ADD_REQUEST, data),
  addSuccess: () => action(LocationTypes.ADD_SUCCESS),
  addError: (error: string) => action(LocationTypes.ADD_ERROR, { error }),

  listRequest: (filters: FilterDTO) =>
    action(LocationTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<LocationDTO>) =>
    action(LocationTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(LocationTypes.LIST_ERROR, { error }),

  getRequest: (id: string) => action(LocationTypes.GET_REQUEST, id),
  getSuccess: (location: LocationDTO) =>
    action(LocationTypes.GET_SUCCESS, location),
  getError: (error: string) => action(LocationTypes.GET_ERROR, { error }),

  updateRequest: (locations: UpdateLocationDTO) =>
    action(LocationTypes.UPDATE_REQUEST, locations),
  updateSuccess: () => action(LocationTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(LocationTypes.UPDATE_ERROR, { error }),

  clear: () => action(LocationTypes.CLEAR),
};
export default LocationActions;

type LocationReducer<Action extends AnyAction> = Reducer<LocationState, Action>;

const listSuccess: LocationReducer<
  ReturnType<typeof LocationActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const setFilters: LocationReducer<
  ReturnType<typeof LocationActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
  error: '',
  loading: false,
});

const getSuccess: LocationReducer<
  ReturnType<typeof LocationActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: LocationReducer<ReturnType<typeof LocationActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<LocationState>(INITIAL_STATE)
  .handleAction(LocationTypes.SET_FILTERS, setFilters)
  .handleAction(LocationTypes.ADD_REQUEST, genericRequest)
  .handleAction(LocationTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(LocationTypes.ADD_ERROR, genericError)
  .handleAction(LocationTypes.LIST_REQUEST, genericRequest)
  .handleAction(LocationTypes.LIST_SUCCESS, listSuccess)
  .handleAction(LocationTypes.LIST_ERROR, genericError)
  .handleAction(LocationTypes.GET_REQUEST, genericRequest)
  .handleAction(LocationTypes.GET_SUCCESS, getSuccess)
  .handleAction(LocationTypes.GET_ERROR, genericError)
  .handleAction(LocationTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(LocationTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(LocationTypes.UPDATE_ERROR, genericError)
  .handleAction(LocationTypes.CLEAR, clear);
