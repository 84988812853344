import styled from 'styled-components';

type AlignContent = 'start' | 'center' | 'space-between' | 'space-around';
type JustifyContent =
  | AlignContent
  | 'space-evenly'
  | 'end'
  | 'flex-end'
  | 'flex-start';
type JustifyItems = 'stretch' | 'center' | 'start' | 'end';
type RowParams = {
  columns?: string;
  gap?: number;
  justifyContent?: JustifyContent;
  marginTop?: string;
  marginBottom?: string;
  alignContent?: AlignContent;
  justifyItems?: JustifyItems;
  alignItems?: JustifyItems;
  justifySelf?: JustifyItems;
  alignSelf?: JustifyItems;
};

export default styled.div<RowParams>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${(props) => props.columns || 'auto'};
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: ${(props) => (props.gap && `${props.gap}rem`) || '1rem'};
  justify-content: ${(props) => props.justifyContent};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  align-content: ${(props) => props.alignContent};
  justify-items: ${(props) => props.justifyItems};
  align-items: ${(props) => props.alignItems || 'end'};
  justify-self: ${(props) => props.justifySelf};
  align-self: ${(props) => props.alignSelf};
`;
