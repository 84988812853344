import { call, put, takeLatest, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import handleError from '@/adapters/httpErrors';
import { PageDTO } from '@/dtos/generics';
import AggregationsActions, { AggregationTypes } from './duck';
import AggregationDTO from '@/dtos/aggregations/AggregationDTO';
import aggregationProvider from '@/lib/aggregations';

type ListParams = ReturnType<typeof AggregationsActions.listRequest>;

export function* list({ payload }: ListParams): Generator {
  try {
    const { filters, page } = payload;
    const result = (yield call(
      aggregationProvider.list,
      filters,
      page
    )) as PageDTO<AggregationDTO>;
    yield put(AggregationsActions.listSuccess(result));
  } catch (err) {
    const errors = handleError(err);
    yield put(AggregationsActions.listError(errors));
  }
}

type GetParams = ReturnType<typeof AggregationsActions.getRequest>;

export function* get({ payload }: GetParams): Generator {
  try {
    const data = (yield call(
      aggregationProvider.getById,
      payload
    )) as AggregationDTO;

    yield put(AggregationsActions.getSuccess(data));
  } catch (err) {
    const errors = handleError(err);
    yield put(AggregationsActions.getError(errors));
  }
}

type CreateParams = ReturnType<typeof AggregationsActions.createRequest>;

export function* create({ payload }: CreateParams): Generator {
  try {
    const data = (yield call(
      aggregationProvider.create,
      payload
    )) as AggregationDTO;

    yield put(AggregationsActions.createSuccess(data));
    toast.success('Aggregation created successfully');
    return data;
  } catch (err) {
    const errors = handleError(err);
    yield put(AggregationsActions.createError(errors));
    return null;
  }
}

type UpdateParams = ReturnType<typeof AggregationsActions.updateRequest>;

export function* update({ payload }: UpdateParams): Generator {
  try {
    const data = (yield call(
      aggregationProvider.update,
      payload
    )) as AggregationDTO;

    yield put(AggregationsActions.updateSuccess(data));
    toast.success('Aggregation updated successfully');
  } catch (err) {
    const errors = handleError(err);
    yield put(AggregationsActions.updateError(errors));
  }
}

type DisaggregateParams = ReturnType<
  typeof AggregationsActions.disaggregateRequest
>;

export function* disaggregate({ payload }: DisaggregateParams): Generator {
  try {
    yield call(aggregationProvider.disaggregate, payload);
    yield put(AggregationsActions.disaggregateSuccess());
    toast.success('Aggregation disaggregated successfully');
  } catch (err) {
    const errors = handleError(err);
    yield put(AggregationsActions.disaggregateError(errors));
  }
}

export default function* aggregationsSaga(): Generator {
  yield all([
    takeLatest(AggregationTypes.LIST_REQUEST, list),
    takeLatest(AggregationTypes.GET_REQUEST, get),
    takeLatest(AggregationTypes.CREATE_REQUEST, create),
    takeLatest(AggregationTypes.UPDATE_REQUEST, update),
    takeLatest(AggregationTypes.DISAGGREGATE_REQUEST, disaggregate),
  ]);
}
