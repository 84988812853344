import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import {
  LocationPositionDTO,
  CreateLocationPositionDTO,
} from '@/dtos/locationPosition';

import { FilterDTO, PageDTO } from '@/dtos/generics';
import { genericRequest, genericError, genericSuccess } from '../utils';
import { LocationPositionState } from './types';
import UpdateLocationPositionDTO from '@/dtos/locationPosition/UpdateLocationPositionDTO';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: LocationPositionState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  total: 0,
  error: '',
  filters: SearchQuery.build(),
  selected: {
    name: '',
    locationId: '',
  } as LocationPositionDTO,
};

export enum LocationPositionTypes {
  SET_FILTERS = '@locationPositions/SET_FILTERS',

  ADD_REQUEST = '@locationPositions/ADD_REQUEST',
  ADD_SUCCESS = '@locationPositions/ADD_SUCCESS',
  ADD_ERROR = '@locationPositions/ADD_ERROR',

  CLEAR = '@locationPositions/CLEAR',
  UPDATE_REQUEST = '@locationPositions/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@locationPositions/UPDATE_SUCCESS',
  UPDATE_ERROR = '@locationPositions/UPDATE_ERROR',

  LIST_REQUEST = '@locationsPositions/LIST_REQUEST',
  LIST_SUCCESS = '@locationsPositions/LIST_SUCCESS',
  LIST_ERROR = '@locationsPositions/LIST_ERROR',

  GET_SUCCESS = '@locationPositions/GET_SUCCESS',
  GET_REQUEST = '@locationPositions/GET_REQUEST',
  GET_ERROR = '@locationPositions/GET_ERROR',
}

const LocationPositionActions = {
  setFilters: (filters: SearchQuery) =>
    action(LocationPositionTypes.SET_FILTERS, { filters }),

  addRequest: (data: CreateLocationPositionDTO) =>
    action(LocationPositionTypes.ADD_REQUEST, data),
  addSuccess: () => action(LocationPositionTypes.ADD_SUCCESS),
  addError: (error: string) =>
    action(LocationPositionTypes.ADD_ERROR, { error }),

  getRequest: (locationId: string) =>
    action(LocationPositionTypes.GET_REQUEST, locationId),
  getSuccess: (locationPosition: LocationPositionDTO) =>
    action(LocationPositionTypes.GET_SUCCESS, locationPosition),
  getError: (error: string) =>
    action(LocationPositionTypes.GET_ERROR, { error }),

  listRequest: (filters: FilterDTO) =>
    action(LocationPositionTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<LocationPositionDTO>) =>
    action(LocationPositionTypes.LIST_SUCCESS, page),
  listError: (error: string) =>
    action(LocationPositionTypes.LIST_ERROR, { error }),

  updateRequest: (locationPosition: UpdateLocationPositionDTO) =>
    action(LocationPositionTypes.UPDATE_REQUEST, locationPosition),
  updateSuccess: () => action(LocationPositionTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(LocationPositionTypes.UPDATE_ERROR, { error }),

  clear: () => action(LocationPositionTypes.CLEAR),
};
export default LocationPositionActions;

type LocationPositionReducer<Action extends AnyAction> = Reducer<
  LocationPositionState,
  Action
>;

const listSuccess: LocationPositionReducer<
  ReturnType<typeof LocationPositionActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const setFilters: LocationPositionReducer<
  ReturnType<typeof LocationPositionActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
  error: '',
  loading: false,
});

const getSuccess: LocationPositionReducer<
  ReturnType<typeof LocationPositionActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: LocationPositionReducer<
  ReturnType<typeof LocationPositionActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<LocationPositionState>(INITIAL_STATE)
  .handleAction(LocationPositionTypes.SET_FILTERS, setFilters)
  .handleAction(LocationPositionTypes.ADD_REQUEST, genericRequest)
  .handleAction(LocationPositionTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(LocationPositionTypes.ADD_ERROR, genericError)
  .handleAction(LocationPositionTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(LocationPositionTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(LocationPositionTypes.UPDATE_ERROR, genericError)
  .handleAction(LocationPositionTypes.LIST_REQUEST, genericRequest)
  .handleAction(LocationPositionTypes.LIST_SUCCESS, listSuccess)
  .handleAction(LocationPositionTypes.LIST_ERROR, genericError)
  .handleAction(LocationPositionTypes.GET_REQUEST, genericRequest)
  .handleAction(LocationPositionTypes.GET_SUCCESS, getSuccess)
  .handleAction(LocationPositionTypes.GET_ERROR, genericError)
  .handleAction(LocationPositionTypes.CLEAR, clear);
