import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { REHYDRATE } from 'redux-persist';

import handleError from '@/adapters/httpErrors';
import AuthenticateResultDTO from '@/dtos/user/AuthenticateResultDTO';
import UserDTO from '@/dtos/user/UserDTO';
import history from '@/services/history';
import i18n from '@/locale';
import userServices from '@/lib/users';

import UserActions, { UserTypes } from './duck';

type SetTokenParams = ReturnType<typeof UserActions.setToken>;
export function setToken(action: SetTokenParams): void {
  const { payload } = action;
  if (!payload) return;

  const { token } = payload.user;
  if (!token) return;
  userServices.setToken(token);
}

type LoginParams = ReturnType<typeof UserActions.loginRequest>;
export function* login({ payload }: LoginParams): Generator {
  try {
    const { data } = payload;
    const response = yield call(userServices.authenticate, data);
    const { user, token, sessionDue } = response as AuthenticateResultDTO;
    userServices.setToken(token);
    yield put(UserActions.loginSuccess(user, token, sessionDue));
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.loginError(error));
  }
}

export function* getProfile(): Generator {
  try {
    const user = yield call(userServices.getProfile);
    yield put(UserActions.getProfileSuccess(user as UserDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.getProfileError(error));
  }
}

type UpdateProfileParams = ReturnType<typeof UserActions.updateProfileRequest>;
export function* updateProfile({ payload }: UpdateProfileParams): Generator {
  try {
    const data = yield call(userServices.updateProfile, payload.data);
    yield put(UserActions.getProfileSuccess(data as UserDTO));
    toast.success(i18n.t('success.updatedData'));
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.updateProfileError(error));
  }
}

type SendPasswordRecoveryLinkParams = ReturnType<
  typeof UserActions.sendPasswordRecoveryLinkRequest
>;
export function* sendPasswordRecoveryLink({
  payload,
}: SendPasswordRecoveryLinkParams): Generator {
  try {
    yield call(userServices.sendPasswordRecoveryLink, payload.email);
    yield put(UserActions.sendPasswordRecoveryLinkSuccess());
    toast.success(i18n.t('success.passwordRecoveryLinkSent'));
    history.push('/login');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.sendPasswordRecoveryLinkError(error));
  }
}

type ResendPasswordRecoveryLinkParams = ReturnType<
  typeof UserActions.resendPasswordRecoveryLinkRequest
>;
export function* resendPasswordRecoveryLink({
  payload,
}: ResendPasswordRecoveryLinkParams): Generator {
  try {
    yield call(userServices.resendPasswordRecoveryLink, payload.code);
    yield put(UserActions.resendPasswordRecoveryLinkSuccess());
    toast.success(i18n.t('success.activationCodeResent'));
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.resendPasswordRecoveryLinkError(error));
  }
}

type RecoverPasswordParams = ReturnType<
  typeof UserActions.passwordRecoveryRequest
>;
export function* recoverPassword({
  payload,
}: RecoverPasswordParams): Generator {
  try {
    yield call(userServices.recoverPassword, payload.code, payload.password);
    yield put(UserActions.passwordRecoverySuccess());
    history.push('/login');
    toast.success(i18n.t('success.passwordRecovered'));
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.passwordRecoveryError(error));
  }
}

export function* logout(): Generator {
  yield put(UserActions.logoutSuccess());
  history.push('/');
}

export default all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(UserTypes.LOGIN_REQUEST, login),
  takeLatest(UserTypes.GET_PROFILE_REQUEST, getProfile),
  takeLatest(UserTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(
    UserTypes.SEND_PASSWORD_RECOVERY_LINK_REQUEST,
    sendPasswordRecoveryLink
  ),
  takeLatest(
    UserTypes.RESEND_PASSWORD_RECOVERY_LINK_REQUEST,
    resendPasswordRecoveryLink
  ),
  takeLatest(UserTypes.PASSWORD_RECOVERY_REQUEST, recoverPassword),
  takeLatest(UserTypes.LOGOUT_REQUEST, logout),
]);
