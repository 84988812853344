import styled from 'styled-components';

export const Container = styled.label`
  outline: none;
`;

type FieldParams = {
  toClipboard?: boolean;
  error?: boolean;
  required?: boolean;
};

export const Label = styled.p`
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
`;

export const Field = styled.input<FieldParams>`
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray.base};
  color: ${({ theme }) => theme.colors.black};
  transition: 0.2s;

  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.error : theme.colors.gray.base};

  outline: none;

  &[type='checkbox'] {
    width: auto;
    height: min-content;
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
    + span {
      position: unset;
    }
  }
  &:focus:not(:read-only) {
    background-color: transparent;
    &:not([type='checkbox']) + span {
      color: ${({ error, theme }) =>
        error ? theme.colors.error : theme.colors.black};
    }
  }
  &:focus,
  &:not(:placeholder-shown) {
    &:not([type='checkbox']) {
      background-color: transparent;
    }
  }
`;

export const FieldArea = styled.textarea<FieldParams>`
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray.base};
  color: ${({ theme }) => theme.colors.black};
  transition: 0.2s;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.error : theme.colors.gray.base};
  outline: none;

  &:focus:not(:read-only) {
    background-color: transparent;
    &:not([type='checkbox']) + span {
      color: ${({ error, theme }) =>
        error ? theme.colors.error : theme.colors.black};
    }
  }
  &:focus,
  &:not(:placeholder-shown) {
    &:not([type='checkbox']) {
      background-color: transparent;
    }
  }
`;
