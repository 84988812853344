import { all, takeLatest, call, put } from 'redux-saga/effects';

import companyServices from '@/lib/companies';
import handleError from '@/adapters/httpErrors';
import { PageDTO } from '@/dtos/generics';
import { CompanyDTO } from '@/dtos/company';
import history from '@/services/history';
import CompaniesActions, { CompaniesTypes } from './duck';

type ListParams = ReturnType<typeof CompaniesActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(companyServices.list, payload.filters);
    yield put(CompaniesActions.listSuccess(data as PageDTO<CompanyDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(CompaniesActions.listError(error));
  }
}

type AddParams = ReturnType<typeof CompaniesActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    const data = yield call(companyServices.create, payload);
    yield put(CompaniesActions.addSuccess(data as CompanyDTO));
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(CompaniesActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof CompaniesActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(companyServices.update, payload);
    yield put(CompaniesActions.updateSuccess());
    history.push('/companies');
  } catch (err) {
    const errors = handleError(err);
    yield put(CompaniesActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof CompaniesActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(companyServices.getById, payload);
    yield put(CompaniesActions.getSuccess(data as CompanyDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(CompaniesActions.getError(errors));
  }
}

export default all([
  takeLatest(CompaniesTypes.LIST_REQUEST, list),
  takeLatest(CompaniesTypes.ADD_REQUEST, add),
  takeLatest(CompaniesTypes.UPDATE_REQUEST, update),
  takeLatest(CompaniesTypes.GET_REQUEST, get),
]);
