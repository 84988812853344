import api from '@/services/api';
import { CreateLocationDTO, LocationDTO } from '@/dtos/locations';
import { FilterDTO, PageDTO } from '@/dtos/generics';
import UpdateLocationDTO from '@/dtos/locations/UpdateLocationDTO';

const resourceProvider = {
  async create(locations: CreateLocationDTO): Promise<LocationDTO> {
    const { data } = await api.post<LocationDTO>('/locations', locations);
    return data;
  },
  async list(params: FilterDTO): Promise<PageDTO<LocationDTO>> {
    const { data } = await api.get<PageDTO<LocationDTO>>(`/locations`, {
      params,
    });
    return data;
  },
  async update(locations: UpdateLocationDTO): Promise<LocationDTO> {
    const { data } = await api.put<LocationDTO>(
      `/locations/${locations.id}`,
      locations
    );
    return data;
  },
  async find(locationId: string): Promise<LocationDTO> {
    const { data } = await api.get<LocationDTO>(`/locations/${locationId}`);
    return data;
  },
};

export default resourceProvider;
