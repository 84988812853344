import PageDTO from '@/dtos/generics/PageDTO';
import { RoleDTO, AddRoleDTO, UpdateRoleDTO } from '@/dtos/roles';
import api from '@/services/api';

class RoleServices {
  async create(role: AddRoleDTO): Promise<RoleDTO> {
    const { data } = await api.post<RoleDTO>('/roles', role);
    return data;
  }
  async update(role: UpdateRoleDTO): Promise<RoleDTO> {
    const { data } = await api.put<RoleDTO>(`/roles/${role.id}`, role);
    return data;
  }
  async list(): Promise<PageDTO<RoleDTO>> {
    const { data } = await api.get<PageDTO<RoleDTO>>('/roles');
    return data;
  }
  async find(roleId: string): Promise<RoleDTO> {
    const { data } = await api.get<RoleDTO>(`/roles/${roleId}`);
    return data;
  }
}

const roleServices = new RoleServices();

export default roleServices;
