import React, { useEffect, useState } from 'react';
import SelectEntity from '@/components/SelectEntity';
import resourcesProvider from '@/lib/resources';
import SearchQuery from '@/utils/SearchQuery';
import { ApplicationState } from '@/store/types';
import { ResourceDTO } from '@/dtos/resource';
import { StringSet } from '@/utils/setTypes';
import { PageDTO } from '@/dtos/generics';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

type ResourceFieldProps = {
  value: string;
  onChange: StringSet;
  filter?: SearchQuery;
};

type Entity = ResourceDTO & { label: string; value: string };

const ResourceField: React.FC<ResourceFieldProps> = ({
  value,
  onChange,
  filter,
}) => {
  const { t: translation } = useTranslation();
  const [page, setPage] = useState<PageDTO<Entity>>({
    data: [],
    total: 0,
    currentPage: 1,
  });

  const userCompanyId = useSelector(
    (state: ApplicationState) => state.user.data.companyId
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchResources = async () => {
      try {
        const query = filter
          ? filter.equals('companyId', userCompanyId).equals('active', 'true')
          : new SearchQuery()
              .equals('companyId', userCompanyId)
              .equals('active', 'true');

        const response: PageDTO<ResourceDTO> = await resourcesProvider.list(
          query
        );
        const entities = response.data.map((resource) => ({
          ...resource,
          label: resource.name,
          value: resource.id.toString(),
        }));
        setPage({
          data: entities,
          total: response.total,
          currentPage: response.currentPage,
        });
      } catch (error) {
        toast.error('Erro ao carregar recursos. Por favor, tente novamente.');
      }
    };

    fetchResources();
  }, [filter, userCompanyId]);

  return (
    <SelectEntity
      loadItems={() => Promise.resolve(page)}
      onChange={onChange}
      value={value}
      placeholder={translation('select.placeholder.selectId')}
    />
  );
};

export default ResourceField;
