import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';

import QuestionsActions, { QuestionTypes } from './duck';
import QuestionDTO from '@/dtos/question/QuestionDTO';
import questionProvider from '@/lib/questions';
import i18next from 'i18next';
import { toast } from 'react-toastify';

type ListAllResourceQuestionsParams = ReturnType<
  typeof QuestionsActions.listAllResourceQuestionsRequest
>;
export function* listAllResourceQuestions({
  payload,
}: ListAllResourceQuestionsParams): Generator {
  try {
    const result: any = yield call(
      questionProvider.listAllResourceQuestions,
      payload
    );
    yield put(
      QuestionsActions.listAllResourceQuestionsSuccess(
        result?.questions as QuestionDTO[]
      )
    );
  } catch (err) {
    const errors = handleError(err);
    yield put(QuestionsActions.listAllResourceQuestionsError(errors));
  }
}

type DeleteParams = ReturnType<typeof QuestionsActions.deleteRequest>;
export function* deleteQuestion({ payload }: DeleteParams): Generator {
  try {
    yield call(questionProvider.delete, payload.questionId);
    yield put(QuestionsActions.deleteSuccess());
    yield put(
      QuestionsActions.listAllResourceQuestionsRequest({
        resourceId: payload.resourceId,
      })
    );
    toast.success(i18next.t('Success.questionDelete'));
  } catch (err) {
    const error = handleError(err);
    yield put(QuestionsActions.deleteError(error));
  }
}

type AddParams = ReturnType<typeof QuestionsActions.addRequest>;
export function* addQuestion({ payload }: AddParams): Generator {
  try {
    yield call(questionProvider.create, payload.data);
    yield put(QuestionsActions.addSuccess());
    yield put(
      QuestionsActions.listAllResourceQuestionsRequest({
        resourceId: payload.data.resourceId,
      })
    );
    toast.success(i18next.t('Success.questionCreated'));
  } catch (err) {
    const error = handleError(err);
    yield put(QuestionsActions.addError(error));
  }
}

export default all([
  takeLatest(
    QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_REQUEST,
    listAllResourceQuestions
  ),
  takeLatest(QuestionTypes.DELETE_REQUEST, deleteQuestion),
  takeLatest(QuestionTypes.ADD_REQUEST, addQuestion),
]);
