import styled, { css } from 'styled-components';
import { Link as link } from 'react-router-dom';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: ${({ theme }) => theme.sizes.title};
  color: ${({ theme }) => theme.colors.title};
`;

export const SubTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: ${({ theme }) => theme.sizes.subTitle};
  color: ${({ theme }) => theme.colors.title};
`;

const withEllipsis = css`
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type TextProps = {
  size?: string;
  bold?: boolean;
  wordBreak?: string;
  padding?: string;
  ellipsis?: boolean;
};
export const Text = styled.p<TextProps>`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ size, theme }) => size || theme.sizes.text};
  color: ${({ color, theme }) => color || theme.colors.black};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  word-break: ${({ wordBreak }) => wordBreak || 'normal'};
  padding-right: ${({ padding }) => padding};
  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
  ${({ ellipsis }) => ellipsis && withEllipsis}
`;

type LinkProps = {
  align?: string;
};
export const Link = styled(link)<LinkProps>`
  color: ${({ theme }) => theme.colors.primary.base};
  text-align: ${({ align }) => align || 'left'};
  text-decoration: none;
`;

type SpanProps = {
  family: string;
  size: string;
  weight: string;
  ellipsis: boolean;
};
export const Span = styled.span<SpanProps>`
  font-family: ${({ family }) => family};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  ${({ ellipsis }) => ellipsis && withEllipsis}
`;
