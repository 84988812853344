import React, { KeyboardEventHandler, memo } from 'react';
import { useTheme } from 'styled-components';

import { Modal, Icon, Input } from '@/components';
import { ModalContent } from '@/components/Modal/styles';
import { InputProps } from '@/components/Input';
import { Container, ClearSearch, SearchButton } from './styles';

interface SearchParams extends InputProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onSubmit: () => void;
  label: string;
  clearSearch: () => void;
  title: string;
}
const Search: React.FC<SearchParams> = ({
  isOpen,
  setOpen,
  onSubmit,
  label,
  clearSearch,
  title,
  ...rest
}) => {
  const theme = useTheme();

  const handleSubmit = (): void => {
    onSubmit();
    setOpen(false);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (e): void => {
    if (e.key === 'Enter') handleSubmit();
    if (e.key === 'Escape') clearSearch();
  };

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} title={title}>
      <ModalContent>
        <Container>
          <Input
            type="text"
            onKeyUp={handleKeyUp}
            label={`search.${label}`}
            {...rest}
          />
          <ClearSearch onClick={clearSearch} className="clear-button">
            <Icon
              name="x"
              color={theme.colors.black}
              colorOnHover={theme.colors.error}
              size={18}
              title="clearSearch"
            />
          </ClearSearch>
          <SearchButton onClick={handleSubmit}>
            <Icon
              name="search"
              color={theme.colors.black}
              colorOnHover={theme.colors.white}
              size={18}
              title="search"
            />
          </SearchButton>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default memo(Search);
