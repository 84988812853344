import { all, takeLatest, call, put } from 'redux-saga/effects';

import featuresServices from '@/lib/features';
import handleError from '@/adapters/httpErrors';
import { PageDTO } from '@/dtos/generics';
import { FeatureDTO } from '@/dtos/feature';
import FeaturesActions, { FeaturesTypes } from './duck';

type ListParams = ReturnType<typeof FeaturesActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(featuresServices.list, payload.filters);
    yield put(FeaturesActions.listSuccess(data as PageDTO<FeatureDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(FeaturesActions.listError(error));
  }
}

export default all([takeLatest(FeaturesTypes.LIST_REQUEST, list)]);
