import FilterDTO from '@/dtos/generics/FilterDTO';
import PageDTO from '@/dtos/generics/PageDTO';
import {
  CreateLocationPositionDTO,
  LocationPositionDTO,
} from '@/dtos/locationPosition';
import UpdateLocationPositionDTO from '@/dtos/locationPosition/UpdateLocationPositionDTO';
import api from '@/services/api';

const locationProvider = {
  async create(
    locationPosition: CreateLocationPositionDTO
  ): Promise<LocationPositionDTO> {
    const { data } = await api.post<LocationPositionDTO>(
      '/location-positions',
      locationPosition
    );
    return data;
  },
  async list(params: FilterDTO): Promise<PageDTO<LocationPositionDTO>> {
    const { data } = await api.get<PageDTO<LocationPositionDTO>>(
      `/location-positions`,
      {
        params,
      }
    );
    return data;
  },
  async update(
    locationPosition: UpdateLocationPositionDTO
  ): Promise<LocationPositionDTO> {
    const { data } = await api.put<LocationPositionDTO>(
      `/location-positions/${locationPosition.id}`,
      locationPosition
    );
    return data;
  },
  async find(locationId: string): Promise<LocationPositionDTO> {
    const { data } = await api.get<LocationPositionDTO>(
      `/location-positions/${locationId}`
    );
    return data;
  },
};
export default locationProvider;
