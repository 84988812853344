import { css } from 'styled-components';

const focusedField = css`
  border: 2px solid ${(props) => props.theme.colors.primary.base};
  outline: none;

  + label {
    height: fit-content;
    color: ${(props) => props.theme.colors.primary.base};
    font-size: 0.8rem;
    top: -7px;
    pointer-events: none;
    background-color: #fff;
    border-bottom: none;
  }
`;

export default css`
  height: 2.8rem;
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};
  border: 1px solid ${(props) => props.theme.colors.primary.base};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  padding: 0.6rem;
  outline: none;
  box-sizing: border-box;
  transition: border 0.3s;

  ${(props) => props.focused && focusedField}
  &:focus {
    ${focusedField};
  }

  ${(props) =>
    props.required &&
    css`
      & + label::after {
        content: ' *';
        font-weight: bold;
        color: ${props.theme.colors.error};
      }
    `}
`;
