import styled from 'styled-components';
import { darken } from 'polished';

import ButtonBase from './ButtonBase';

export default styled(ButtonBase)`
  width: 100%;
  height: 2.8rem;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: 0.6rem;
  padding: 0 0.8rem;
  background-color: ${(props) =>
    props.secondary === 'true' ? '#FFFFFF' : props.theme.colors.primary.base};
  color: ${(props) =>
    props.secondary === 'true' ? props.theme.colors.primary.base : '#FFFFFF'};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  transition: color 0.3s, background 0.5s;

  &:hover {
    color: ${(props) => props.secondary === 'true' && '#FFFFFF'};
    background-color: ${(props) =>
      props.secondary === 'true'
        ? props.theme.colors.primary.base
        : darken(0.1, props.theme.colors.primary.base)};
  }

  &:disabled {
    background-color: #ced2d9;
    color: ${(props) => props.theme.colors.primary.base};
    cursor: not-allowed;

    &:hover {
      background-color: #ced2d9;
      color: ${(props) => props.theme.colors.primary.base};
    }
  }
`;
