import { FeatureDTO } from '@/dtos/feature';

class ToggleRoute {
  private features: Map<string, boolean>;

  constructor() {
    this.features = new Map();
  }

  setFeature(feature: string, value: boolean): void {
    this.features.set(feature, value);
  }

  featureIsEnabled(...features: string[]): boolean {
    return features.every((feature) => !!this.features.get(feature));
  }

  static from(features: FeatureDTO[]): ToggleRoute {
    const toggle = new ToggleRoute();
    features.forEach((feature) => {
      toggle.setFeature(feature.name, true);
    });
    return toggle;
  }
}

export default ToggleRoute;
