import { all } from 'redux-saga/effects';

import user from './user/sagas';
import users from './users/sagas';
import companies from './companies/sagas';
import events from './events/sagas';
import features from './features/sagas';
import codes from './codes/sagas';
import roles from './roles/sagas';
import plans from './plans/sagas';
import permissions from './permissions/sagas';
import status from './status/sagas';
import statusField from './statusField/sagas';
import invoices from './invoices/sagas';
import resources from './resources/sagas';
import locationPositions from './locationPositions/sagas';
import locations from './locations/sagas';
import resourceField from './resourceField/sagas';
import printers from './printers/sagas';
import rolls from './rolls/sagas';
import questions from './question/sagas';
import interactions from './interaction/sagas';
import licensedCodes from './licensedCodes/sagas';
import answers from './answers/sagas';
import aggregations from './aggregations/sagas';

export default function* rootSaga(): Generator {
  yield all([
    user,
    users,
    companies,
    events,
    features,
    codes,
    roles,
    permissions,
    status,
    statusField,
    invoices,
    resources,
    plans,
    resourceField,
    locationPositions,
    locations,
    printers,
    rolls,
    questions,
    interactions,
    licensedCodes,
    answers,
    aggregations,
  ]);
}
