import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 100vh;
  grid-template-columns: minmax(auto, ${(props) => props.theme.sizes.sideBar}) 1fr;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const Main = styled.main`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  background-color: ${(props) => props.theme.colors.contentBackground};
`;

export const Content = styled.article`
  overflow-y: auto;
`;
