import { all, call, put, takeLatest } from 'redux-saga/effects';
import handleError from '@/adapters/httpErrors';
import interactionProvider from '@/lib/interactions';
import { PageDTO } from '@/dtos/generics';

import InteractionActions, { InteractionTypes } from './duck';
import { InteractionDTO } from '@/dtos/interaction';
import InteractionCountDTO from '@/dtos/interaction/InteractionCountDTO';

type ListParams = ReturnType<typeof InteractionActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(interactionProvider.list, payload);
    yield put(InteractionActions.listSuccess(data as PageDTO<InteractionDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(InteractionActions.listError(error));
  }
}
type ListInteractionCountParams = ReturnType<
  typeof InteractionActions.listInteractionCountRequest
>;
export function* listInteractionCount({
  payload,
}: ListInteractionCountParams): Generator {
  try {
    const data = yield call(
      interactionProvider.fetchInteractionsCount,
      payload
    );
    yield put(
      InteractionActions.listInteractionCountSuccess(
        data as PageDTO<InteractionCountDTO>
      )
    );
  } catch (err) {
    const error = handleError(err);
    yield put(InteractionActions.listInteractionCountError(error));
  }
}

export default all([
  takeLatest(InteractionTypes.LIST_REQUEST, list),
  takeLatest(
    InteractionTypes.LIST_INTERACTION_COUNT_REQUEST,
    listInteractionCount
  ),
]);
