import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

const Login = lazy(() => import('@/pages/Login'));
const PasswordRecovery = lazy(() => import('@/pages/PasswordRecovery'));
const PasswordUpdate = lazy(() => import('@/pages/PasswordUpdate'));
const ActivateAccount = lazy(() => import('@/pages/ActivateAccount'));

const AuthRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/recovery" component={PasswordRecovery} />
    <Route exact path="/recovery/:code" component={PasswordUpdate} />
    <Route exact path="/activate/:code" component={ActivateAccount} />
    {/* <Route component={() => <Redirect to="/" />} /> */}
  </Switch>
);

export default AuthRoutes;
