import api from '@/services/api';
import {
  CreateLicensedCodeDTO,
  LicensedCodeDTO,
  UpdateLicensedCodeDTO,
} from '@/dtos/licensedCodes';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';

const path = `/licensed-qrcodes`;

const licensedCodesProvider = {
  async list(filters: SearchQuery): Promise<PageDTO<LicensedCodeDTO>> {
    const { data } = await api.get<PageDTO<LicensedCodeDTO>>(path, {
      params: filters.query,
    });
    return data;
  },
  async getById(id: string): Promise<LicensedCodeDTO> {
    const { data } = await api.get<LicensedCodeDTO>(`${path}/${id}`);
    return data;
  },
  async getCodesByLicensedCodeId(id: string): Promise<string[]> {
    const { data } = await api.get<string[]>(`${path}/${id}/codes`);
    return data;
  },
  async create(licensedCode: CreateLicensedCodeDTO): Promise<LicensedCodeDTO> {
    const { data } = await api.post<LicensedCodeDTO>(path, licensedCode);
    return data;
  },
  async update(licensedCode: UpdateLicensedCodeDTO): Promise<LicensedCodeDTO> {
    const { data } = await api.put<LicensedCodeDTO>(
      `${path}/${licensedCode.id}`,
      { active: licensedCode.active }
    );
    return data;
  },
};

export default licensedCodesProvider;
