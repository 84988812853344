import { DocumentTypes } from '@/enums';

const getNumbers = (value: string): string[] => value.match(/\d/g) || [];

export const municipalRegistration = (value: string): string => {
  const valueArray = getNumbers(value);
  if (valueArray.length < 2) return valueArray.join('');
  const last = valueArray.pop();
  const first = valueArray.join('');
  const result = `${first}-${last}`;
  return result;
};

export const phone = (value: string): string => {
  const valueArray = getNumbers(value.slice(0, 15));
  const numbers = valueArray.join('');
  let result = numbers.replace(/^(\d{2})(\d)/, '($1) $2');
  result = result.replace(/(\d{5})(\d{1,4})$/, '$1-$2');
  return result;
};

export const cep = (value: string): string => {
  const valueArray = getNumbers(value);
  const numbers = valueArray.join('');
  const result = numbers.replace(/^(\d{5})(\d)/, '$1-$2');
  return result;
};

export const padLeftZero = (value: string, max: number): string => {
  const leftZeros = /^0+/;
  const digits = value.replace(leftZeros, '');
  if (digits.length === max) return digits;
  if (digits.length > max) return digits.slice(0, max);
  return digits.padStart(max, '0');
};

export const cpf = (value: string): string => {
  const valueArray = getNumbers(value);
  let result = value;
  if (valueArray.length > 10) {
    result = valueArray.join('');
    result = result.slice(0, 11);
  }
  // Coloca ponto entre o terceiro e o quarto dígito
  result = result.replace(/^(\d{3})(\d)/, '$1.$2');
  // Coloca ponto entre o sexto e o sétimo dígitos
  result = result.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
  // Coloca uma hífen entre o nono e o decimo dígitos
  result = result.replace(/\.(\d{3})(\d)/, '.$1-$2');
  return result;
};

export const cnpj = (value: string): string => {
  const valueArray = getNumbers(value);
  let result = value;
  if (valueArray.length > 14) {
    result = valueArray.join('');
    result = result.slice(0, 14);
  }
  // Coloca ponto entre o segundo e o terceiro dígito
  result = result.replace(/^(\d{2})(\d)/, '$1.$2');
  // Coloca ponto entre o quinto e o sexto dígitos
  result = result.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  // Coloca uma barra entre o oitavo e o nono dígitos
  result = result.replace(/\.(\d{3})(\d)/, '.$1/$2');
  // Coloca um hífen depois do bloco de quatro dígitos
  result = result.replace(/(\d{4})(\d)/, '$1-$2');
  return result;
};

export const document = {
  [DocumentTypes.CPF]: cpf,
  [DocumentTypes.CNPJ]: cnpj,
};
