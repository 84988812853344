import { all, takeLatest, call, put } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import rollsProvider from '@/lib/rolls';
import { PageDTO } from '@/dtos/generics';
import { RollDTO } from '@/dtos/rolls';
import printerProvider from '@/lib/printers';
import { PrinterDTO } from '@/dtos/printers';
import printerService from '@/services/printer';
import history from '@/services/history';

import RollsActions, { RollsTypes } from './duck';
import { createFile } from '@/utils';

type ListParams = ReturnType<typeof RollsActions.listRequest>;
export function* getRolls({ payload }: ListParams): Generator {
  try {
    const data = yield call(rollsProvider.list, payload);
    yield put(RollsActions.listSuccess(data as PageDTO<RollDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(RollsActions.listError(error));
  }
}

type GetParams = ReturnType<typeof RollsActions.getRequest>;
export function* detail({ payload }: GetParams): Generator {
  try {
    const data = yield call(rollsProvider.getById, payload);
    yield put(RollsActions.getSuccess(data as RollDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(RollsActions.getError(error));
  }
}

type PrintParams = ReturnType<typeof RollsActions.printRequest>;
export function* print({ payload }: PrintParams): Generator {
  try {
    const printerResult = yield call(
      printerProvider.getById,
      payload.printerId
    );
    const printer = printerResult as PrinterDTO;
    const codesResult = yield call(
      rollsProvider.getCodesByRollId,
      payload.rollId
    );
    const codes = codesResult as string[];
    const aggregatorCodes = codes.slice(0, payload.aggregatorCodeAmount);
    const fileCodes = codes.slice(payload.aggregatorCodeAmount);
    yield call(printerService.printMultiple, printer, aggregatorCodes);
    const fileLines = fileCodes
      .map((item) => [`https://m87.me/${item}`, item].join(';'))
      .join('\n');
    createFile(fileLines, 'codes.csv');
    yield put(RollsActions.printSuccess());
  } catch (err) {
    const { log } = console;
    log({ err });
    const error = handleError(err);
    yield put(RollsActions.getError(error));
  }
}

type AddParams = ReturnType<typeof RollsActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(rollsProvider.create, payload);
    yield put(RollsActions.addSuccess());
    history.push('/rolls');
  } catch (err) {
    const error = handleError(err);
    yield put(RollsActions.addError(error));
  }
}

export default all([
  takeLatest(RollsTypes.LIST_REQUEST, getRolls),
  takeLatest(RollsTypes.GET_REQUEST, detail),
  takeLatest(RollsTypes.PRINT_REQUEST, print),
  takeLatest(RollsTypes.ADD_REQUEST, add),
]);
