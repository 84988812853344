import styled from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active, theme, color }) =>
    active ? theme.colors.primary.base : color};
  > svg {
    transition: color 0.3s;
    &:hover {
      color: ${(props) => props.colorOnHover};
    }
  }
`;
