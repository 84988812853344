import React from 'react';

import I18n from '@/components/I18n';

import { Menu } from './styles';

export type LanguageMenuParams = {
  isLangMenuOpen: boolean;
  languageMenuRef: React.Ref<HTMLDivElement>;
};
const LanguageMenu: React.FC<LanguageMenuParams> = ({
  isLangMenuOpen,
  languageMenuRef,
}) => {
  return (
    <Menu show={isLangMenuOpen} ref={languageMenuRef}>
      <I18n />
    </Menu>
  );
};

export default LanguageMenu;
