export default {
  translations: {
    Site: {
      initialMessage:
        'With the MetaTrack tracking system you have greater stability, intelligence and ease in managing and moving your products.',
      accountActive: 'Activate your account',
      passwordRecovery: 'Forgot password?',
      passwordRecoveryTitle: 'Recover your password',
      newPasswordTitle: 'Defina sua nova senha',
      login: 'Log in',
    },
    Label: {
      rollAmount: 'Amount of packages',
      codeAmount: 'Amount of codes per package',
      ptBR: 'Portuguese',
      enUS: 'English',
      esES: 'Spanish',
      password: 'Password',
      email: 'E-mail',
      codeState: 'Generate code status report',
      dateInit: 'Data de início',
      dateEnd: 'Data de fim',
      confirmYourPassword: 'Confirm your password',
      confirmPassword: 'Confirm the password',
      code: 'Code',
      packageCode: 'Package code',
      region: 'Region',
      trade: 'Trading Name',
      companyName: 'Company name',
      municipalRegistration: 'Municipal registration',
      municipalExempt: 'exempt',
      stateRegistration: 'State registration',
      stateExempt: 'exempt',
      state: 'State',
      city: 'City',
      address: 'Address',
      neighborhood: 'Neighborhood',
      complement: 'Complement',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      cep: 'CEP',
      name: 'Full name',
      phone: 'Phone',
      office: 'Office',
      contactType: 'Department',
      ownFleet: 'Own fleet',
      driverName: 'Driver name',
      destiny: 'Destiny',
      licensePlate: 'Vehicle plate',
      observation: 'Observation',
      aggregateCode: 'Volume / product code',
      aggregationCode: 'Volume code',
      nickname: 'Nickname',
      url: 'URL',
      port: 'TCP port',
      productImage: 'Product image',
      amount: 'Product amount',
      unitsAmount: 'Intern units amount',
      productAmount: 'amount',
      volumesAmount: 'Volumes amount',
      amountVolume: 'Volume quantity',
      innerBoxQuantity: 'Quantity inner boxes',
      amountPartitions: 'Partitions amount',
      partitions: 'Partitions',
      pce: 'PCE',
      country: 'Country',
      factory: 'factory',
      weight: 'Weight',
      unit: 'Unit of measurement',
      ean: 'EAN-13',
      dun: 'DUN-14',
      stackingLimit: 'Stacking limit',
      warning: 'Warning',
      onuNumber: 'UN number',
      usefulInformation: 'Useful information',
      expirationMonths: 'expiration in months',
      expirationDays: 'expiration in days',
      productIdentifier: 'PCE',
      description: 'Description',
      perishable: 'Perishable',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      shippingCompanyName: 'Company name or nickname',
      productCode: 'Product code',
      solicitationDescription: 'Request description',
      manufacturing: 'Manufacturing date',
      due: 'Due date',
      lot: 'allotment',
      intrinsicMarking: 'Intrinsic Marking',
      partitionsAmount: 'Number of partitions',
      abbrev: 'Abbreviation',
      un: 'UN number',
      netWeight: 'Net weight',
      netUnit: 'Unit of measurement (Kg, g, ...)',
      grossWeight: 'Gross weight',
      grossUnit: 'Unit of measurement  (Kg, g, ...)',
      productSearch: 'Search by name or PCE',
      employeeSearch: 'Search by name or E-mail',
      invoiceSearch: 'Search by name',
      searchPrinter: 'Search by name or url',
      carriersSearch: 'Search by name, CNPJ or CPF',
      activitySearch: 'Paste the code here',
      volumesLabelSearch: 'Search by code or status',
      tr: 'TR',
      store: 'Store',
      clientSearch: 'Search by name or CNPJ',
      nullDate: 'Any date',
      nullLot: 'Any Lot',
    },
    Button: {
      confirm: 'Confirm',
      add: 'Add',
      cancel: 'Cancel',
      submit: 'Submit',
      update: 'Update',
      transfer: 'Transfer',
      import: 'Import',
      register: 'Register',
      addCode: 'Add code',
      generate: 'Generar códigos',
      filter: 'Filter',
      order: 'Order',
      previous: 'Previous',
      next: 'Next',
      reprint: 'Reprint',
      newRequest: 'New request',
      downloadCodes: 'Download codes',
      requestPartitionCodes: 'Request partition codes',
      aggregateNewPartition: 'Aggregate new partition',
      printRequests: 'Print requests',
      printPartitionRequests: 'print partition requests',
      makeRequest: 'Make a request',
      makeAggregation: 'Make aggregation',
      print: 'Print',
      printedRequests: 'Printed requests',
      unprintedRequests: 'unprinted requests',
      product: 'Produto',
      volume: 'Volume',
      cleanFilters: 'Clean filters',
      olderRequests: 'Older requests',
      latestRequests: 'Latest requests',
      backToDefault: 'Back to default',
      dProduction: 'Daily production',
      linkInvoice: 'Link invoice',
      transferInvoice: 'Transfer custody',
      choosePrinter: 'Choose a printer.',
      download: 'Download',
      edit: 'Edit',
      delete: 'Delete',
      details: 'Details',
      addRep: 'Add rep',
      moreOptions: 'More options',
      reSendToken: 'Resend token',
      goBack: 'Return',
    },
    SolicitationPage: {
      title: 'Request codes',
      products: 'Products',
      partitions: 'Partitions',
      volumes: 'Volumes',
      impressPrint: 'ImpressPrint',
      reprint: {
        title: 'Label reprint',
      },
      tableProduct: {
        requestByProduct: 'Request by product',
        favorites: 'Favorites',
        products: 'Products',
        available: 'Available',
        print: 'Print',
        generateCodes: 'Generate codes',
        more: 'More',
      },
      tableVolume: {
        volumeRequest: 'Volume Request',
        amount: 'Amount: ',
      },
      addSolicitation: {
        subTitle: 'New request',
        cardTitleProduct: 'Generate more codes for',
        cardTitlePartition: 'New partition request ',
        labelProduct: 'Product',
        labelVolume: 'Volume',
        selectProduct: 'Select the product ...',
        selectVolume: 'Select the volume ...',
        noProductsFound: 'No products found',
        noVolumeFound: 'No volume found',
        yup: {
          noObservation: 'Enter the description of the request.',
          required: 'Enter the number of codes to generate.',
          amount: 'Enter a number for the number of volume codes.',
        },
      },
      addSolicitationModal: {
        title: 'Make a new request for ',
      },
      partitionAggregationModal: {
        title: 'Partition aggregation',
        yup: {
          volumeRequired: 'Enter a volume code.',
          productRequired: 'Enter a product code.',
        },
      },
      addSolicitationPartitionModal: {
        title: 'Partition request',
      },
      print: {
        selectPrinter: 'Select printer...',
        noPrinterFound: 'Not printer found.',
        noCodeAdded: 'No code added.',
        productPrinting: 'Product printing ',
        tagPrinting: 'Label printing',
        partitionPrinting: 'Partition printing ',
        volumePrinting: 'Volume printing ',
        available: 'Available',
        volumeOf: 'Volume of',
        availableCode: ' Available code(s)',
        yupProduct: {
          codeRequired: 'Please enter a code please.',
          requiredLot: 'Enter the batch.',
          requiredObservation: 'Inform the observation of this operation.',
          amountRequired: 'Inform the quantity of box to be printed.',
          manufacturingRequired:
            'Inform the date of manufacture of the product.',
          dueRequired: 'The product is perishable, inform the expiration date.',
          intrinsicMarkingRequired: 'Enter the intrinsic markup.',
          printerRequired: 'Choose a printer.',
        },
        error: {
          description: 'You must write code.',
          codeLimit: 'You cannot exceed the aggregate limit.',
          thisCodeOne: 'The code',
          thisCodeTwo: 'is already in the code list.',
          notBelongProduct: 'This product does not belong to this volume.',
          notBelongVolume: 'That volume does not belong to this volume.',
          exceedingLimitCodes: 'Quantity is exceeding the limit of',
        },
      },
      filterModal: {
        title: 'Filter requests',
        subTitleOne: 'Filter by print status',
        subTitleTwo: 'Filter by type',
        subTitleThree: 'Filter by date',
      },
      orderModal: {
        title: 'Sort requests',
        subTitleOne: 'Sort by name',
        subTitleTwo: 'Sort by date',
      },
      RePrintSolicitation: {
        headerTitle: 'Label reprint',
        pageTitle: 'Reprint label',
      },
    },

    Volume: {
      headerTitle: 'Volumes',
      title: 'Volumes',
      selectVolumeContent: 'Select volume content',
      total: 'Total: ',
      all: 'All',
      addVolumes: {
        add: 'Add',
        edit: 'Details',
        cardTitle: 'Add a new volume',
        products: 'Products',
        volumes: 'Volumes',
        selectProduct: 'Select the product...',
        selectVolume: 'Select the volume...',
        selectBarcode: 'Select the type of barcode...',
      },
      name: 'Name',
      abbrev: 'Abbreviation',
      amount: 'Quantity',
      codes: 'Available codes',
      content: 'Content',
      date: 'Date',
      actions: 'More options',
      options: {
        details: 'Details',
        printBox: 'Print volume',
      },
      PrintVolume: {
        headerTitle: 'Print',
        printCardTitle: 'Print',
        internalItems: 'Added items/Total items',
        availableCodes: 'available codes',
      },
    },
    Locale: {
      role: 'Rules',
      employee: 'Employees',
      company: 'Company',
      license: 'Licenses',
      shippingCompany: 'Carrier',
      solicitation: 'Solicitation',
      code: 'Code',
      invoice: 'Invoice',
      volume: 'Volume',
      event: 'Moviment',
      product: 'Product',
      printer: 'Printer',
      report: 'Report',
      testPrintProduct: 'Test print',
      dailyProduction: 'Daily production',
    },
    Error: {
      noDataSolicitation: 'No resquest found.',
      noDataVolume: 'No volume found',
      noDataProduct: 'No produc found',
      locationPermission: 'Access to your location is required.',
      minorDate:
        'The expiration date may not be less than the manufacturing date.',
      noExistCode: 'This code does not exist in the system.',
      unableCode: 'This code is unable to be printed.',
      codeNeverPrinted: 'This code was never printed.',
      choosePrinter: 'Choose a printer.',
      register: 'Register',
      previous: 'Previous',
      next: 'Next',
      dProduction: 'Daily production',
      linkInvoice: 'Link invoice',
      transferInvoice: 'Transfer custody',
      productPermission: 'You are not allowed to add a product.',
      employeeAddPermission:
        'You do not have permission to register an employee.',
      volumeAddPermission: 'You are not allowed to add a volume.',
      addShippingCompanyAddPermission:
        'You do not have permission to register a carrier.',
      dailyProductionPermission:
        'You are not allowed to generate a report on daily production.',
      editShippingCompanyAddPermission:
        'You do not have permission to register a carrier.',
      clientAddPermission: 'You do not have permission to add a client.',
      representativeAddPermission:
        'You do not have permission to add a delegate.',
      clientEditPermission: 'You do not have permission to edit a client.',
      representativeEditPermission:
        'You do not have permission to edit a delegate.',
      volumeEditPermission: 'You are not allowed to edit a volume.',
      noPermissionToEditPrinter:
        'You do not have permission to edit a printer.',
      employeeEditPermission: 'You do not have permission to edit an employee.',
      typePackageProdcut: 'Inform the type of packaging',
      noBarCode: 'Inform the type of the barcode.',
      existProductIdentifier: 'A product with this identifier already exists.',
      amountProductSolicitation: 'The requested quantity is not available',
      notFoundBarcode: 'No barcode type found.',
      serverCommunication: 'There was an error communicating with the server.',
      noAddress: 'Address not found',
      noState: 'State not found',
      noCity: 'City not found',
      oneField: 'Please fill at least one field.',
      deletePermission: 'You do not have permission to delete this item.',
      errorPassword:
        'The password must have a lowercase letter, a capital letter, a number and a special character.',
      codesAmount: "Don't have enough codes",
      noPermissionToImportInvoice:
        'You do not have permission to import an invoice.',
      noInvoiceFileAdded: 'Enter at least one invoice.',
      noRoleName: 'Name this function.',
      noPermissionToEnableEmployee:
        'You do not have permission to enable or disable a user.',
      accessDenied: 'Access denied.',
      pageNotFound: 'Page not found.',
      insufficientCodes: 'Insufficient codes.',
      enterCode: 'Enter the code.',
      codeLimitReached: 'Code limit reached.',
      codeAlreadyAdded: 'Code already added',
      productNotBelongAggregator:
        'The product code added does not belong to this aggregating volume.',
      volumeNotBelongAggregator:
        'The code of the added volume does not belong to this aggregating volume.',
      productCodeIncompatible:
        'This code belongs to a product. Add codes only for the internal volume of this aggregating volume.',
      volumeCodeIncompatible:
        'This code belongs to a volume. Add codes only for the internal product of this aggregating volume.',
    },
    Select: {
      loadingMessage: 'Loading...',
      statesList: 'Select the state...',
      solicitationSearch: 'Search by description',
      employeeSearch: 'Search by name or E-mail',
      function: 'Select occupation...',
      noFunction: 'No occupation found.',
      select: 'Select...',
      selectPacking: 'Select the packaging',
      selectBarCode: 'Select the type of barcode...',
      selectCity: 'Select the city...',
      noState: 'No state found.',
      noCity: 'No cities found.',
      selectTagType: 'Select the type of tag.',
      noEventsFound: 'No events found.',
      noPrintersFound: 'No printers found.',
      labelTypesSelect: {
        placeholder: 'Select the type of label.',
        noOptions: 'No label type found.',
        optionsLabels: {
          product: 'Product',
          volume: 'Volume',
        },
      },
      printersSelect: {
        placeholder: 'Select a printer...',
        noOptions: 'No printer found.',
      },
    },
    Navbar: {
      settings: 'Settings',
      exit: 'Exit',
    },
    Yup: {
      empty: 'This field cannot be empty',
      noName: 'Enter the name',
      noEmail: 'Enter the e-mail',
      validEmail: 'Email must be a valid email',
      noStore: 'Enter the store.',
      noPhone: 'Enter the phone.',
      noAmount: 'Inform the quantity.',
      noObservation: 'Enter a note for this operation.',
      noCode: 'Enter the link code.',
      noEan: 'Inform the products EAN-13.',
      noDun: 'Inform the DUN-14 of the packaging.',
      noPositive: 'This number must be positive.',
      noWarning: 'Enter the warning for this volume.',
      noStackingLimit: 'Enter the stacking limit for this volume.',
      noInteger: 'This number must be an integer.',
      noDivisible:
        'The quantity of products must be divisible by the quantity of partitions.',
      noBarCode: 'Enter the type of the barcode.',
      noPartitions: 'Enter the number of partitions.',
      noPacking: 'Inform the type of packaging.',
      noCountry: 'Enter the country code.',
      noFactory: 'Enter the factory code.',
      noWeight: 'Inform the mass of the product.',
      noUnity: 'Enter the unit of measure of mass.',
      noId: 'Enter the product id',
      noUrl: 'Enter the printers url.',
      noPort: 'Enter the printer port.',
      noAbbrev: 'The abbreviation is mandatory.',
      noNetWeight: 'Enter the net weight.',
      noGrossWeight: 'Enter the gross weight.',
      noProduct: 'Choose the product.',
      noVolume: 'Choose the volume.',
      minFixed3: 'the length must be 3 characters',
      minFixed4: 'the length must be 4 characters.',
      minFixed5: 'the length must be 5 characters',
      min4: 'the length must be at least 4 characters.',
      min5: 'the length must be at least 5 characters',
      max5: 'the length must be a maximum of 5 characters',
      min8: 'the length must be at least 8 characters',
      maxFixed15: 'The number entered exceeds the 15 character limit.',
      amountProductSolicitation: 'The requested quantity is not available',
      notFoundBarcode: 'No type of barcode found.',
      noCnpj: 'Inform the CNPJ.',
      noCpf: 'Inform the CPF.',
      sizeCnpj: 'CNPJ invalid, type again.',
      sizeCPF: 'CPF invalid, type again.',
      noMunicipalRegistration: 'Inform the municipal registry.',
      noStateRegistration: 'Inform the state registry.',
      noCep: 'Inform the zip code',
      noTargetUf: 'Inform the State',
      noTargetCity: 'Inform the city.',
      noNeighborhood: 'Inform the neighborhood.',
      noAddress: 'Enter the address.',
      noOffice: 'Inform the position',
      noContactType: 'Inform the sector',
      noPassword: 'enter a password',
      confirmPassword: 'Confirm the password',
      passwordsDontCheck: "Passwords don't check",
      minPassword: 'Password must be at least 8 digits',
      matchesPassword:
        'Password must be at least a lowercase letter, a capital letter, a number and a special character.',
      requiredField: 'Required field',
      noTrade: 'Enter your fancy name.',
      companyName: 'Inform the company name.',
      oldPassword: 'Informe the old password',
      noDriver: 'Enter the name of the driver.',
      errorEAN: 'Letters are not allowed in an EAN.',
      driverCPF: "Inform the driver's CPF.",
      errorCPF: 'Invalid CPF, type again.',
      noDestiny: 'Inform the destination.',
      noPlate: 'Inform the license plate.',
      noLocation: 'You must allow access to your location.',
      noPce: "Inform the product's PCE",
      codeRequest: 'Enter the code. ',
      printerRequest: 'Choose a printer.',
      noInnerBoxQuantity: 'Enter the number of itens',
      manufacturingRequired: 'Enter the date of manufacture.',
      amountRequired: 'Enter a quantity.',
      printerRequired: 'Choose a printer.',
    },
    AddMoviment: {
      title: 'Codes moviments',
      noCode: 'No code added.',
      options: {
        deaggregation: 'Volume breakdown',
        location: 'Location',
        cancel: 'Canceled',
        lost: 'Lost',
        damaged: 'Damaged',
        unlinked: 'Unlinked from Invoice',
        partition: 'Cancel partitions',
      },
      CodeList: {
        elementsTitle: {
          removeCode: 'Remove code',
        },
      },
    },
    Company: {
      title: 'Your company',
      infoTitle: 'Company information',
      updateTitle: 'Update business data',
    },
    Employee: {
      title: 'Employees',
      noEmployee: 'No employees found',
      occupation: 'Occupation:',
      office: 'Office',
      user: 'User rules',
      actions: 'More options',
      total: 'Total',
      type: 'Type',
      register: 'Register',
      addEmployee: 'Register a new employee',
      edit: 'Edit',
      activeTotal: 'Actives',
      disabledTotal: 'Disabled',
      name: 'Name',
      email: 'E-mail',
      activeEmployee: 'Activate employee',
      deactiveEmployee: 'Deactivate employee',
      editEmployee: 'Edit employee',
      disable: {
        part1: 'Are you sure you want to',
        active: 'activate',
        deactive: 'disable',
        activation: 'activation',
        deactivation: 'deactivation',
        part2: 'this employee?',
        part3: ' This is a',
        part4: 'risk action',
        part5: ' Upon confirming the ',
        part6: ', this action',
        part7: "had direct effects on the employee's use of the system",
      },
      roleLabel: 'Role (in the system)',
    },
    Invoice: {
      title: 'Invoices',
      total: 'Total:',
      noMove: 'No moves',
      aggregated: 'Linked',
      transfered: 'Custody Transfer',
      nfe: 'Invoice',
      number: 'Product amount',
      state: 'State',
      date: 'Date',
      actions: 'More options',
      linkInvoice: 'Link invoice',
      details: 'Detalhes',
      importTitle: 'Import of NF-e',
      noData: 'No NF-e imported.',
      aggregation: 'aggregate notes',
      trow: 'Drag invoices or click here',
      noMovimets: 'No moves',
      linked: 'Linked',
      transfer: 'Custody transferred',
      added: 'Added on ',
      productsAmount: 'N ° of products:',
      creat: 'Created at ',
      receiver: 'Receiver',
      locality: 'Locality',
      items: 'Items',
      ean: 'EAN',
      amount: 'Amount',
      price: 'Price',
      ncm: 'NCM',
      unity: 'Unity',
      noProduct: 'No products found.',
      driverInfo: 'Driver information',
      info: 'Operation information',
      importedSucesfully: 'Notes imported successfully.',
      InvoiceDetails: {
        CustodyTransfer: {
          invoiceNotAllowed: 'The invoice must be linked or transferred.',
          selectShippingCompany: 'Select shipping company...',
          noShippingCompanyFound: 'No shipping companies found',
        },
      },
      CustodyTransfer: {
        selectShippingCompany: 'Select shipping company...',
        noShippingCompanyFound: 'No shipping companies found',
      },
      deleteModal: {
        part1: 'Delete',
        part2: 'invoice',
        part3: 'This is an',
        part4: 'risk action',
        part5: 'Are you sure you want to',
        part6: 'delete',
        part7: 'this',
        part8: 'invoice?',
      },
      dailyProductionModal: {
        title: 'Daily production',
        text: 'Do you want to download the daily production XML file? (NFes exported on the day)',
      },
      noInvoiceAdded: 'No invoice added to queue.',
    },
    Products: {
      title: 'Products',
      filter: 'Filter by registration date',
      addProducts: 'Register a new product',
      productsCodes: 'Available codes',
      register: 'Register',
      noBarCode: 'No barcode',
      addImage: 'Choose a file ',
      total: 'Total: ',
      date: 'Date',
      details: 'Details',
      noData: 'No registered products.',
      noPacking: 'No packaging found.',
      name: 'Name',
      product: 'Product',
      amount: 'Amount',
      actions: 'More options',
      options: {
        details: 'Details',
        print: 'Print',
      },
      info: 'Details',
      volumes: {
        drum: 'Drum',
        barrel: 'Barrel',
        bombona: 'Bombona',
        box: 'Box',
        bag: 'Bag',
        compositePackaging: 'Composite Packaging',
      },
      elementsTitle: {
        deleteImageButton: 'Remove image',
      },
    },
    Printers: {
      title: 'Printers',
      total: 'Total:',
      date: 'Date:',
      name: 'Name',
      port: 'IP address',
      actions: 'More options',
      noData: 'No printer found.',
      add: 'Add',
      addTitle: 'Add a new printer',
      select: 'Select if it is a USB printer',
      noPrinter: 'No usb printer found.',
      edit: 'Edit',
      printer: 'Printer',
      localPrinter: 'Local printer',
      delete: {
        part1: 'Are you sure you want to',
        part2: 'delete',
        part3: 'this printer?',
        part4: 'This is a',
        part5: 'risk action',
        part6: ' Upon confirming the deletion, this action',
        part7: "can't be undone",
      },
    },
    Safety: {
      title: 'Security',
      list: 'Registered roles',
      details: 'View role details',
      edit: 'Role edit',
      register: 'Register a new role',
      choosePermission: 'Choose permissions for this role',
      select: 'Choose permissions...',
      noPermission: 'No permissions found.',
      permissionsTypesLabels: {
        role: 'Roles',
        company: 'Company',
        solicitation: 'Solicitations',
        product: 'Products',
        employee: 'Employees',
        volume: 'Volumes',
        invoice: 'Invoices',
        printer: 'Printers',
        client: 'clients',
        shippingCompany: 'Shipping companies',
        event: 'Moviments',
        testPrintProduct: 'Test print',
        report: 'Report',
        dailyProduction: 'Daily Production',
        code: 'Codes',
      },
      EditRole: {
        headerTitle: 'Role',
        infoCardTitle: 'Role info',
        roleNameLabel: 'Name',
        updateCardTitle: 'Update role',
      },
    },
    Permissions: {
      nothing: 'Nothing',
      index: 'Read',
      store: 'Create',
      indexStore: 'Read and create',
      indexUpdate: 'Read and update',
      update: 'Read, update and delete',
      delete: 'Read, create, update e delete',
    },
    Settings: {
      title: 'Settings',
      subtitle: 'Update your data',
    },
    Report: {
      title: 'Report generation',
      dateInit: 'Start date',
      dateEnd: 'End date',
      codeState: 'Select to generate code status report',
      report: 'Generate report',
      reportGenerate: 'Generate a report to display the data',
      PDFGenerate: 'Generate PDF',
      daily: 'Daily production',
      XMLtitle: 'XML Report',
    },
    ShippingCompanies: {
      title: 'Carriers',
      all: 'All',
      register: 'Register',
      total: 'Total: ',
      type: 'Type: ',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      date: 'Date: ',
      details: 'Details',
      noCarriers: 'No registered carriers.',
      newCarrier: 'Register a new carrier',
      company: 'Legal Person',
      selfEmployee: 'Physical Person',
      edit: 'Edit',
      companyName: 'Company Name / Name',
      document: 'CNPJ/CPF',
      actions: 'More options',
    },
    VolumesLabel: {
      code: 'Code',
      status: 'Status',
      headerTitle: 'Labels',
      titlePage: 'Code Printing',
      all: 'All',
      options: {
        printBox: 'Print',
        cancel: 'Cancel package',
      },
      print: {
        name: 'Name',
        id: 'Identifier',
        amount: 'Product amount',
        partitions: 'Partitions amount',
        productPrinting: 'Product label printing',
      },
      total: 'Total',
      type: 'Type',
      date: 'Date',
      range: 'Range',
      details: 'Details',
      noCarriers: 'No code found.',
      company: 'Legal Person',
      selfEmployee: 'Physical Person',
      edit: 'Edit',
      actions: 'Actions',
      printed: 'Printed',
      unprinted: 'Unprinted',
      cancelled: 'Cancelled',
      production: 'Production',
      partialCancelled: 'Partially canceled',
      search: 'Search by code or status',
      PrintVolumeLabel: {
        headerTitle: 'Printing',
        printCard: {
          title: 'Print label',
          name: 'Name',
          id: 'Identifier',
          amount: 'Product amount',
          partitions: 'Partitions amount',
          productPrinting: 'Product label printing',
          printerSelect: {
            placeholder: 'Choose a printer',
            noOptions: 'No printers found.',
          },
        },
        yup: {
          printerRequired: 'Choose a printer.',
        },
      },
      CancelVolumeLabel: {
        headerTitle: 'Cancel label',
        pageTitle: 'Cancel label',
      },
      FilterStatusModal: {
        title: 'Filter by status',
        selectStatusPlaceholder: 'Select the status',
        selectStatusNoOptions: 'No status found',
      },
    },
    Tracking: {
      title: 'Track code',
      noData: 'There were no recent moves.',
      FilterModal: {
        title: 'Filter moviments',
        buttons: {
          withoutFilter: 'All events',
          writeEventsFilter: 'Write',
          readEventsFilter: 'Read',
        },
      },
      OrderModal: {
        title: 'Order moviments',
        buttons: {
          orderByCreationDate: 'Latest moviments',
        },
      },
    },
    Clients: {
      title: 'Client',
      add: 'Add',
      addClient: 'Add a new customer',
      registerRepresentative: 'Add representative',
      representative: 'Representative',
      all: 'All',
      noData: 'No registered clients',
      date: 'Date: ',
      name: 'Company name',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      edit: 'Edit',
      total: 'Total: ',
      details: 'Details',
      addRepresentative: 'Add representative',
      editRepresentative: 'Edit representative',
      noRepresentative: 'No added',
    },
    backEndErrors: {
      'A product with this Identifier already registered.':
        'A product with this Identifier already registered.',
      'Insufficient partition codes.': 'Insufficient partition codes.',
      'Insufficient volume codes.': 'Insufficient volume codes.',
      'Insufficient product codes.': 'Insufficient product codes.',
      'Internal server error.': 'Internal server error',
      'Your license expired. Please upgrade your plan to get access again.':
        'Your license has expired, please update your access plan.',
      'Your license offline time expired. Please, connect you server in internet to get access again.':
        'Your offline license has expired, please connect to the internet to gain access again.',
      'Access denied.': 'Access denied.',
      'Validation error.': 'Validation error.',
      "Passwords don't check.": "Passwords don't check.",
      'confirmPassword is a required field':
        'confirmPassword is a required field.',
      'Old and new password are the same.':
        'Old and new password are the same.',
      'Company not found.': 'Company not found.',
      'You do not have permission to enable or disable an employee.':
        'You are not allowed to enable or disable an employee.',
      'The company must be activated in order to register an employee.':
        'The company must be activated to register an employee.',
      'E-mail already registered.': 'Email has already been registered.',
      'You have exceeded the limit of active employees, upgrade your plan to register more.':
        'You have exceeded the limit of active employees, update your plan to register more.',
      'The company has exceeded the activate employees limit. Please, upgrade your plan.':
        'The company has exceeded the limit of active employees. Please update your plan.',
      'Employee with this email already registered.':
        'Employee with this email already exists.',
      'Employee not found.': 'Employee not found.',
      'You cannot change your password without informing your old password.':
        'You cannot change your password without informing your old password.',
      "You can't change your password without inform your old password.":
        "You can't change your password without inform your old password.",
      'Incorrect password.': 'Incorrect password.',
      'Token already used.': 'Token already used.',
      'Token expired.': 'Token expired.',
      'Token not found.': 'Token not found.',
      'Employee disabled.': 'Employee disabled.',
      'No invoice aggregate with this code.':
        'There is no invoice linked to this code.',
      'It is not possible to delete an invoice already aggregated.':
        'You cannot delete an invoice already aggregated.',
      'Invoice not found.': 'Invoice not found.',
      'Printer not found': 'Printer not found.',
      'A product with this PCE has already been registered.':
        'A product with this PCE has already been registered.',
      'A product with this EAN-13 has already been registered.':
        'A product with this ean is already registered.',
      'Product not found.': 'Product not found.',
      'Permission not found.': 'Permission not found.',
      'A rule with this name has already been created.':
        'A rule with this name has already been created.',
      'Role not found.': 'Rule not found.',
      'There is already a shipping company with this CNPJ.':
        'There is already a carrier with this CNPJ.',
      'There is already a shipping company with this CPF.':
        'There is already a carrier with this CPF.',
      'Shipping company not found.': 'Carrier not found.',
      'Amount not avaliable.': 'Quantity not available.',
      'This code is already aggregated to an invoice.':
        'This code has already been added to an invoice.',
      'Code not found.': 'Code not found.',
      'Invalid code.': 'Invalid code',
      'The code does not belong to a volume.':
        'The code does not belong to a volume.',
      'Codes not found.': 'Codes not found.',
      'Only products requests allowed': 'Only products requests are allowed.',
      'Solicitation already printed.': 'Request already printed.',
      'Solicitation not found': 'Request not found',
      'Solicitation not printed.': 'Request not printed.',
      'A volume with this abbreviation has already been created.':
        'A volume with this abbreviation has already been created.',
      'Volume not found.': 'Volume not found.',
      'A volume with this has already been created.':
        'A volume with this DUN-14 has already been created.',
      'No files uploaded': 'The files were not uploaded.',
      'Unknown permission.': 'Unknown permission.',
      'This invoice was already transferred.':
        'This invoice has already been transferred.',
      'User not found.': 'Employee not found.',
      'Old password is incorrect.': 'Incorrect password.',
      code_not_active: 'Code not activated',
      code_not_print: 'Code not printed',
      no_event_found: 'Event not found',
      code_already_active: 'Code already active',
      code_already_active_aggregation: 'Code already active for aggregation',
      code_not_already_active_aggregation: 'Code not active for aggregation',
      code_does_not_belong_aggregation:
        'Code does not belong to the aggregation',
      code_is_not_valid: 'Code does not exist',
      code_already_marked: 'Code already printed',
      'Communication failure.': 'Hubo un error de comunicación con el sistema.',
    },
    Modal: {
      employeeFilter: 'Filter employees',
      employeeOrder: 'Sort employees',
      nameOrder: 'Alphabetical order',
      mailOrder: 'Sort by email',
      dateFilter: 'Filter by date:',
      registerFilter: 'Filter by registration date',
    },
    PrintTest: {
      title: 'Printing test',
      text: 'Select a tag type',
    },
    SideBar: {
      solicitationsList: 'List Requests',
      solicitationsAdd: 'Generate Requests',
      solicitations: 'Requests',
      moviments: 'Move code',
      tracking: 'Track code',
      products: 'Products',
      productsList: 'List all',
      productsAdd: 'Add',
      volumesLabelList: 'Labels',
      volumes: 'Volumes',
      volumesList: 'List all',
      volumesAdd: 'Add new',
      testPrint: 'Print test',
      shippingCompanies: 'Carriers',
      shippingCompaniesList: 'List all',
      shippingCompaniesAdd: 'Register',
      invoices: 'Invoices',
      invoicesList: 'List all',
      invoicesAdd: 'Import',
      printers: 'Printers',
      printersList: 'List all',
      printersAdd: 'Add',
      report: 'Report',
      employees: 'Employees',
      volumesLabelPrint: 'Code Printing',
      employeesList: 'List all',
      employeesAdd: 'Register',
      company: 'Company',
      security: 'Security',
      settings: 'Settings',
      clients: 'Clients',
      clientsAdd: 'Add',
      clientsList: 'List all',
      reprintLabel: 'Label reprint',
      dailyProduction: 'Daily production',
      dailyProductionXml: 'XML Report',
      codesProduction: 'Aggregated codes',
      printMenuOptions: 'Impressão',
      print: 'Impressão de etiquetas',
      addNewRolls: 'Add code pack',
      assignCompanyRoll: 'Assign codes',
    },
    Warning: {
      invalidSession: 'Your session has expired, please log in again.',
    },
    Packing: {
      title: 'Packaging',
      titleTwo: 'Volume aggregator',
      volume: 'Volume: ',
      maxAggregation: 'Maximum aggregations: ',
      codesForAggregation: 'Codes for aggregation',
      aggregatorVolume: 'Volume aggregator',
      removeCode: 'Delete code',
      noCodesAdd: 'No code added.',
      errors: {
        noVolumeCode: 'Insert the volume code.',
        invalidCode: 'Invalid code.',
        noCode: 'Insert the volume / product code.',
        toMuchAggregation: 'You cannot exceed the aggregation limit.',
        codeList: {
          part1: 'The code',
          part2: 'is already in the code list.',
        },
        invalidVolumeCode: 'This code cannot be added to that volume',
        noLocation: 'You must allow access to your location.',
        noCodes: 'There are no codes to perform the move.',
        oneRequired: 'At least 1 code is required to perform the aggregation.',
      },
    },
    Success: {
      companyUpdate: 'Company updated successfully!',
      completeSearch: 'Search done successfully',
      registerEmployee: 'Employee successfully registered.',
      invoiceLinked: 'Aggregate invoice',
      invoiceTransfered:
        'Custody of the invoice transferred to the license plate vehicle',
      invoiceDelete: 'Invoice deleted.',
      moveError: 'An error occurred while making the move.',
      moveSuccess: 'Move done.',
      printDone: 'Print done.',
      error: 'Error',
      inCode: 'Code',
      rePrintDone: 'Reprint done.',
      codesError: 'Error in the following codes: ',
      printerCreated: 'Printer created.',
      printerUpdate: 'Printer updated.',
      printerDelete: 'Printer deleted.',
      productAdd: 'Product added.',
      productUpdate: 'Product updated.',
      newOcupation: 'Function created.',
      updateRole: 'Rules updated successfully.',
      carrierRegister: 'Registered carrier.',
      carrierUpdate: 'Carrier updated.',
      solicitationDone: 'Request made.',
      aggregationDone: 'Aggregation done.',
      successUpdate: 'Data updated successfully.',
      recoveryEmail: 'A password recovery link has been sent to your email.',
      passwordRecovery: 'Password recovered.',
      activeLink: 'An activation link has been sent to your email.',
      registerActive: 'Account activated successfully.',
      volumeAdd: 'Volume added.',
      volumeUpdate: 'Volume updated.',
      roles: {
        nothing: 'None',
        index: 'Read',
        store: 'Read and create',
        indexUpdate: 'Read and update',
        update: 'Read, update and delete',
        delete: 'Read, create, update and delete',
      },
      createClient: 'Client created.',
      updateClient: 'Cliente updated.',
      createRepresentative: 'Representative created.',
      updateRepresentative: 'Representative updated.',
      dailyProductionFileDownloaded: 'Daily production file downloaded.',
      Sagas: {
        Moviments: {
          VolumesLabel: {
            canceled: 'Label canceled.',
          },
        },
      },
      printSend: 'Impression sent.',
    },
    Solicitations: {
      title: 'Code Solicitations',
      all: 'List',
      total: 'Total',
      amount: 'Amount',
      observation: 'Description',
      print: 'Impression',
      date: 'Created',
      actions: 'More Options',
      options: {
        codeGenerate: 'Generate Codes',
      },
    },
    Print: {
      headerTitle: 'Label printing',
      pageTitle: 'Print labels',
      TestPrint: {
        headerTitle: 'Printing test',
        printCardTitle: 'Perform test print',
      },
    },
  },
};
