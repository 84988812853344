import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import { genericRequest, genericError, genericSuccess } from '../utils';
import { QuestionState } from './types';
import QuestionDTO from '@/dtos/question/QuestionDTO';
import CreateQuestionDTO from '@/dtos/question/CreateQuestionDTO';

const INITIAL_STATE: QuestionState = {
  data: [],
  loading: false,
  error: '',
};

export enum QuestionTypes {
  SET_LOADING = '@questions/SET_LOADING',
  LIST_ALL_RESOURCE_QUESTIONS_REQUEST = '@questions/LIST_ALL_RESOURCE_QUESTIONS_REQUEST',
  LIST_ALL_RESOURCE_QUESTIONS_SUCCESS = '@questions/LIST_ALL_RESOURCE_QUESTIONS_SUCCESS',
  LIST_ALL_RESOURCE_QUESTIONS_ERROR = '@questions/LIST_ALL_ERROR',

  ADD_REQUEST = '@questions/ADD_REQUEST',
  ADD_SUCCESS = '@questions/ADD_SUCCESS',
  ADD_ERROR = '@questions/ADD_ERROR',

  DELETE_REQUEST = '@questions/DELETE_REQUEST',
  DELETE_SUCCESS = '@questions/DELETE_SUCCESS',
  DELETE_ERROR = '@questions/DELETE_ERROR',
  CLEAR = '@questions/CLEAR',
}

const QuestionsActions = {
  setLoading: (loading: boolean) =>
    action(QuestionTypes.SET_LOADING, { loading }),
  listAllResourceQuestionsRequest: ({ resourceId }: { resourceId: string }) =>
    action(QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_REQUEST, {
      resourceId,
    }),
  listAllResourceQuestionsSuccess: (questions: QuestionDTO[]) =>
    action(QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_SUCCESS, questions),
  listAllResourceQuestionsError: (error: string) =>
    action(QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_ERROR, { error }),

  addRequest: (data: CreateQuestionDTO) =>
    action(QuestionTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(QuestionTypes.ADD_SUCCESS),
  addError: (error: string) => action(QuestionTypes.ADD_ERROR, { error }),

  deleteRequest: ({
    questionId,
    resourceId,
  }: {
    resourceId: string;
    questionId: string;
  }) => action(QuestionTypes.DELETE_REQUEST, { questionId, resourceId }),
  deleteSuccess: () => action(QuestionTypes.DELETE_SUCCESS),
  deleteError: (error: string) => action(QuestionTypes.DELETE_ERROR, { error }),
  clear: () => action(QuestionTypes.CLEAR),
};
export default QuestionsActions;

type QuestionsReducer<Action extends AnyAction> = Reducer<
  QuestionState,
  Action
>;

const setLoading: QuestionsReducer<
  ReturnType<typeof QuestionsActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});

const listSuccess: QuestionsReducer<
  ReturnType<typeof QuestionsActions.listAllResourceQuestionsSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    data: payload,
    loading: false,
  };
};
const clear: QuestionsReducer<ReturnType<typeof QuestionsActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<QuestionState>(INITIAL_STATE)
  .handleAction(QuestionTypes.SET_LOADING, setLoading)
  .handleAction(
    QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_REQUEST,
    genericRequest
  )
  .handleAction(QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_SUCCESS, listSuccess)
  .handleAction(QuestionTypes.LIST_ALL_RESOURCE_QUESTIONS_ERROR, genericError)
  .handleAction(QuestionTypes.ADD_REQUEST, genericRequest)
  .handleAction(QuestionTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(QuestionTypes.ADD_ERROR, genericError)
  .handleAction(QuestionTypes.CLEAR, clear);
