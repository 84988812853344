import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { PageDTO } from '@/dtos/generics';
import { RollDTO, PrintRollDTO, CreateRollDTO } from '@/dtos/rolls';
import SearchQuery from '@/utils/SearchQuery';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { RollsState } from './types';

const INITIAL_STATE: RollsState = {
  data: [],
  filters: SearchQuery.build(),
  page: 1,
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
  selected: {} as RollDTO,
};

export enum RollsTypes {
  ADD_REQUEST = '@rolls/ADD_REQUEST',
  ADD_SUCCESS = '@rolls/ADD_SUCCESS',
  ADD_ERROR = '@rolls/ADD_ERROR',
  LIST_REQUEST = '@rolls/LIST_REQUEST',
  LIST_SUCCESS = '@rolls/LIST_SUCCESS',
  LIST_ERROR = '@rolls/LIST_ERROR',
  GET_REQUEST = '@rolls/GET_REQUEST',
  GET_SUCCESS = '@rolls/GET_SUCCESS',
  GET_ERROR = '@rolls/GET_ERROR',
  PRINT_REQUEST = '@rolls/UPDATE_REQUEST',
  PRINT_SUCCESS = '@rolls/UPDATE_SUCCESS',
  PRINT_ERROR = '@rolls/PRINT_ERROR',
  CLEAR = '@rolls/CLEAR',
  SET_FILTERS = '@rolls/SET_FILTERS',
}

const RollsActions = {
  listRequest: (params: SearchQuery) => action(RollsTypes.LIST_REQUEST, params),
  listSuccess: (page: PageDTO<RollDTO>) =>
    action(RollsTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(RollsTypes.LIST_ERROR, { error }),
  getRequest: (id: string) => action(RollsTypes.GET_REQUEST, id),
  getSuccess: (roll: RollDTO) => action(RollsTypes.GET_SUCCESS, roll),
  getError: (error: string) => action(RollsTypes.GET_ERROR, { error }),
  printRequest: (data: PrintRollDTO) => action(RollsTypes.PRINT_REQUEST, data),
  printSuccess: () => action(RollsTypes.PRINT_SUCCESS),
  printError: (error: string) => action(RollsTypes.PRINT_ERROR, { error }),
  clear: () => action(RollsTypes.CLEAR),
  setFilters: (filters: SearchQuery) => action(RollsTypes.SET_FILTERS, filters),
  addRequest: (data: CreateRollDTO) => action(RollsTypes.ADD_REQUEST, data),
  addSuccess: () => action(RollsTypes.ADD_SUCCESS),
  addError: (error: string) => action(RollsTypes.ADD_ERROR, { error }),
};

export default RollsActions;

export type RollsReducer<Action extends AnyAction> = Reducer<
  RollsState,
  Action
>;

const getSucess: RollsReducer<ReturnType<typeof RollsActions.getSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  selected: payload,
  loading: false,
});

const getRollsSuccess: RollsReducer<
  ReturnType<typeof RollsActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
});

const clear: RollsReducer<ReturnType<typeof RollsActions.clear>> = () =>
  INITIAL_STATE;

const setFiltersRolls: RollsReducer<
  ReturnType<typeof RollsActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload,
});

export const reducer = createReducer<RollsState>(INITIAL_STATE)
  .handleAction(RollsTypes.LIST_REQUEST, genericRequest)
  .handleAction(RollsTypes.LIST_SUCCESS, getRollsSuccess)
  .handleAction(RollsTypes.LIST_ERROR, genericError)
  .handleAction(RollsTypes.GET_REQUEST, genericRequest)
  .handleAction(RollsTypes.GET_SUCCESS, getSucess)
  .handleAction(RollsTypes.GET_ERROR, genericError)
  .handleAction(RollsTypes.PRINT_REQUEST, genericRequest)
  .handleAction(RollsTypes.PRINT_SUCCESS, genericSuccess)
  .handleAction(RollsTypes.PRINT_ERROR, genericError)
  .handleAction(RollsTypes.SET_FILTERS, setFiltersRolls)
  .handleAction(RollsTypes.ADD_REQUEST, genericRequest)
  .handleAction(RollsTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(RollsTypes.CLEAR, clear)
  .handleAction(RollsTypes.SET_FILTERS, setFiltersRolls);
