import React from 'react';
import { useTranslation } from 'react-i18next';

import Svg from '@/assets/images/no-data.svg';
import { Title } from '@/components/Text';
import { Container } from './styles';

type NoDataMessage = {
  message?: string;
};
const NoData: React.FC<NoDataMessage> = ({ message, ...rest }) => {
  const { t: translation } = useTranslation();
  const noDataMessage =
    message || translation('page.generic.list.noDataMessage');
  return (
    <Container {...rest}>
      <Title>{noDataMessage}</Title>
      <img src={Svg} alt={noDataMessage} width="54%" height="54%" />
    </Container>
  );
};

export default NoData;
