import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import { CompanyDTO, CreateCompanyDTO, UpdateCompanyDTO } from '@/dtos/company';

class CompanyServices {
  async list(search: SearchQuery): Promise<PageDTO<CompanyDTO>> {
    const { data } = await api.get<PageDTO<CompanyDTO>>('/companies', {
      params: search.query,
    });
    return data;
  }
  async create({ user, ...company }: CreateCompanyDTO): Promise<CompanyDTO> {
    const { data } = await api.post<CompanyDTO>('/companies', {
      user,
      company,
    });
    return data;
  }
  async update(company: UpdateCompanyDTO): Promise<CompanyDTO> {
    const { data } = await api.put<CompanyDTO>(`/companies/${company.id}`, {
      company,
    });
    return data;
  }

  async getById(id: string): Promise<CompanyDTO> {
    const { data } = await api.get<CompanyDTO>(`/companies/${id}`);
    return data;
  }
}

const companyService = new CompanyServices();
export default companyService;
