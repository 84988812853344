import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary.base};
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track {
    background-color: #dedede;
  }
  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.text};
    -webkit-font-smoothing: antialised;
    -moz-osx-font-smoothin: grayscale;
  }
  h1, h2, h3, h4, h5, h6, button {
    font-family: 'Source Sans Pro', sans-serif;
    line-height: normal;
  }
  input {
    font-family: 'Lato', sans-serif;
    line-height: normal;
  }
  span {
    font-family: inherit;
  }
  button {
    cursor: pointer;
    outline: none;
    border: none;

    &:hover, &:focus {
      outline: none;
      border: none;
    }
  }
  input:focus {
    outline: none;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};

    &:hover, &:focus {
    text-decoration: none;

    }
  }
  .Toastify__toast {
    border-radius: 10px;
  }
  .Toastify__toast--success {
    background-color: #63C5C7;
    color: ${({ theme }) => theme.colors.primary.base};
    .Toastify__close-button {
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }
  .Toastify__toast--error {
    background-color: #F291A3;
    color: ${({ theme }) => theme.colors.error};
    .Toastify__close-button {
      color: ${({ theme }) => theme.colors.error};
    }
  }
`;
