import { useSelector } from 'react-redux';
import { ApplicationState } from '@/store/types';
import ToggleRoute from '@/utils/ToggleRoute';

type CallbackResponse = (feature: string) => boolean;
const useCheckFeatureCallback = (): CallbackResponse => {
  const { license } = useSelector(
    ({ user }: ApplicationState) => user.data.company
  );
  const { features } = license.plan;
  const toggle = ToggleRoute.from(features);
  return (feature) => toggle.featureIsEnabled(feature);
};

export default useCheckFeatureCallback;
