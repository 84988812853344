import { all, takeLatest, call, put } from 'redux-saga/effects';

import statusServices from '@/lib/status';
import handleError from '@/adapters/httpErrors';
import { StatusDTO } from '@/dtos/status';
import { PageDTO } from '@/dtos/generics';
import { StatusFieldDTO } from '@/dtos/statusField';
import history from '@/services/history';
import SearchQuery from '@/utils/SearchQuery';

import StatusActions, { StatusTypes } from './duck';

export function* getStatus(): Generator {
  try {
    const data = yield call(statusServices.list, SearchQuery.build());
    yield put(StatusActions.listSuccess(data as PageDTO<StatusDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.listError(error));
  }
}

export function* getStatusFields(): Generator {
  try {
    const data = yield call(statusServices.getStatusFields);
    yield put(StatusActions.getStatusFieldsSuccess(data as StatusFieldDTO[]));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.getStatusFieldsError(error));
  }
}

type GetParams = ReturnType<typeof StatusActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const status = yield call(statusServices.get, payload);
    yield put(StatusActions.getSuccess(status as StatusDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.getError(error));
  }
}

type AddParams = ReturnType<typeof StatusActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(statusServices.create, payload);
    yield put(StatusActions.addSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof StatusActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(statusServices.update, payload);
    yield put(StatusActions.updateSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.updateError(error));
  }
}

export default all([
  takeLatest(StatusTypes.LIST_REQUEST, getStatus),
  takeLatest(StatusTypes.GET_STATUS_FIELDS_REQUEST, getStatusFields),
  takeLatest(StatusTypes.GET_REQUEST, get),
  takeLatest(StatusTypes.ADD_REQUEST, add),
  takeLatest(StatusTypes.UPDATE_REQUEST, update),
]);
