export default {
  translations: {
    Site: {
      initialMessage:
        'Com o sistema de rastreio MetaTrack você tem maior estabilidade , inteligência e facilidade na gerência e na movimentação dos seus produtos.',
      accountActive: 'Ative sua conta',
      passwordRecovery: 'Esqueceu a senha?',
      passwordRecoveryTitle: 'Recupere sua senha',
      newPasswordTitle: 'Defina sua nova senha',
      login: 'Entrar',
    },
    Label: {
      expires: 'Validade em',
      perRoll: 'Valor por pacote de códigos',
      price: 'Valor da licença',
      offline: 'Tempo de funcionamento offline',
      activateUsers: 'Quantidade maxima de usuários',
      rollAmount: 'Quantidade de pacotes',
      codeAmount: 'Quantidade de códigos por pacote',
      ptBR: 'Português',
      enUS: 'Inglês',
      esES: 'Espanhol',
      password: 'Senha',
      email: 'E-mail',
      codeState: 'Gerar relatório de estado dos códigos',
      dateInit: 'Data de início',
      dateEnd: 'Data de fim',
      confirmYourPassword: 'Confirme sua senha',
      confirmPassword: 'Confirmar a senha',
      code: 'Código',
      packageCode: 'Código da embalagem',
      region: 'Região',
      trade: 'Nome fantasia',
      companyName: 'Razão social',
      municipalRegistration: 'Registro municipal',
      municipalExempt: 'Isento',
      stateRegistration: 'Registro estadual',
      stateExempt: 'Isento',
      state: 'Estado',
      city: 'Cidade',
      address: 'Endereço',
      neighborhood: 'Bairro',
      complement: 'Complemento',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      cep: 'CEP',
      name: 'Nome completo',
      phone: 'Telefone',
      office: 'Cargo',
      contactType: 'Setor',
      ownFleet: 'Frota própria',
      driverName: 'Nome do motorista',
      destiny: 'Destino',
      licensePlate: 'Placa do veículo',
      observation: 'Observação',
      aggregateCode: 'Código do volume/produto',
      aggregationCode: 'Código do volume',
      nickname: 'Nome',
      url: 'URL',
      port: 'Porta TCP',
      productImage: 'Imagem do produto',
      unitsAmount: 'Quantidade de unidades internas',
      productAmount: 'Quantidade',
      amount: 'Quantidade de produtos',
      volumesAmount: 'Quantidade',
      amountVolume: 'Quantidade de volumes',
      innerBoxQuantity: 'Quantidade de caixas internas',
      amountPartitions: 'Quantidade de colmeias',
      partitions: 'Colmeias',
      pce: 'PCE',
      country: 'País',
      factory: 'Fábrica',
      weight: 'Peso',
      unit: 'Unidade de medida',
      ean: 'EAN-13',
      dun: 'DUN-14',
      stackingLimit: 'Limite de empilhamento',
      warning: 'Advertência',
      onuNumber: 'Número ONU',
      usefulInformation: 'Informações úteis',
      expirationMonths: 'Validade em meses',
      expirationDays: 'Validade em dias',
      productIdentifier: 'PCE',
      description: 'Descrição',
      perishable: 'Perecível',
      oldPassword: 'Senha antiga',
      newPassword: 'Senha nova',
      shippingCompanyName: 'Razão social ou nome',
      productCode: 'Código do produto',
      solicitationDescription: 'Descrição da solicitação',
      manufacturing: 'Data de fabricação',
      due: 'Data de vencimento',
      lot: 'Lote',
      intrinsicMarking: 'Marcação Intrínseca',
      partitionsAmount: 'Quantidade de colmeias',
      abbrev: 'Abreviação',
      un: 'Número ONU',
      netWeight: 'Peso líquido',
      netUnit: 'Unidade (Kg, g, ...)',
      grossWeight: 'Peso bruto',
      grossUnit: 'Unidade (Kg, g, ...)',
      productSearch: 'Pesquise pelo nome ou PCE',
      employeeSearch: 'Pesquise pelo nome ou E-mail',
      invoiceSearch: 'Pesquise pelo nome',
      searchPrinter: 'Pesquise pelo nome ou url',
      carriersSearch: 'Pesquise pelo nome, CNPJ ou CPF',
      activitySearch: 'Cole o código aqui',
      volumesLabelSearch: 'Pesquise pelo código',
      tr: 'TR',
      store: 'Loja',
      clientSearch: 'Pesquise pelo nome ou CNPJ',
      nullDate: 'Qualquer Data',
      nullLot: 'Qualquer Lote',
    },
    Button: {
      confirm: 'Confirmar',
      add: 'Adicionar',
      cancel: 'Cancelar',
      submit: 'Enviar',
      update: 'Atualizar',
      transfer: 'Transferir',
      register: 'Cadastrar',
      import: 'Importar',
      generate: 'Gerar códigos',
      addCode: 'Adicionar código',
      filter: 'Filtrar',
      order: 'Ordenar',
      reprint: 'Reimprimir',
      previous: 'Anterior',
      next: 'Próxima',
      newRequest: 'Nova Solicitação',
      downloadCodes: 'Baixar códigos',
      requestPartitionCodes: 'Solicitar códigos de colmeia',
      aggregateNewPartition: 'Agregar nova colmeia',
      printRequests: 'Imprimir solicitações',
      printPartitionRequests: 'Imprimir solicitações de colmeias',
      makeRequest: 'Fazer solicitação',
      makeAggregation: 'Fazer agregação',
      print: 'Imprimir',
      printedRequests: 'Impressas',
      unprintedRequests: 'Não impressas',
      product: 'Produto',
      volume: 'Volume',
      cleanFilters: 'Limpar filtros',
      olderRequests: 'Solicitações mais antigas',
      latestRequests: 'Solicitações mais recentes',
      backToDefault: 'Voltar ao padrão',
      dProduction: 'Produção diária',
      choosePrinter: 'Escolha uma impressora',
      linkInvoice: 'Vincular NF-e',
      transferInvoice: 'Transferir custódia',
      download: 'Fazer download',
      edit: 'Editar',
      delete: 'Excluir',
      details: 'Detalhes',
      addRep: 'Adicionar representante',
      moreOptions: 'Mais opções',
      reSendToken: 'Reenviar token',
      goBack: 'Voltar',
    },
    SolicitationPage: {
      title: 'Solicitações de códigos',
      products: 'Produtos',
      partitions: 'Colmeias',
      volumes: 'Volumes',
      impressPrint: 'Impressão',
      reprint: {
        title: 'Reimpressão de etiqueta',
      },
      tableProduct: {
        requestByProduct: 'Solicitação por produto',
        favorites: 'Favoritos',
        products: 'Produtos',
        available: 'Disponíveis',
        print: 'Imprimir',
        generateCodes: 'Gerar códigos',
        more: 'Mais',
      },
      tableVolume: {
        volumeRequest: 'Solicitação por volume',
        amount: 'Quantidade: ',
      },
      addSolicitation: {
        subTitle: 'Nova solicitação',
        cardTitleProduct: 'Gere mais códigos para ',
        rollAssign: 'Atribuir empresa a pocote de códigos',
        cardTitleRoll: 'Gerar pacotes de códigos ',
        labelProduct: 'Produto',
        labelVolume: 'Volume',
        selectProduct: 'Selecione o produto ...',
        selectVolume: 'Selecione o volume ...',
        noProductsFound: 'Nenhum produto encontrado',
        noVolumeFound: 'Nenhum volume encontrado',
        yup: {
          noObservation: 'Informe a descrição da solicitação.',
          required: 'Informe a quantidade de códigos a gerar.',
          amount: 'Informe um número para quantidade de códigos de volumes.',
        },
      },
      addSolicitationModal: {
        title: 'Fazer nova solicitação para ',
      },
      partitionAggregationModal: {
        title: 'Agregação de colmeia',
        yup: {
          volumeRequired: 'Informe um código de volume.',
          productRequired: 'Informe um código de produto.',
        },
      },
      addSolicitationPartitionModal: {
        title: 'Solicitação de colmeia',
      },
      print: {
        selectPrinter: 'Selecione impressora...',
        noPrinterFound: 'Nenhuma impressora encontrada.',
        noCodeAdded: 'Nenhum código adicionado.',
        tagPrinting: 'Impressão de etiquetas ',
        productPrinting: 'Impressão de produto ',
        partitionPrinting: 'Impressão de colmeia ',
        volumePrinting: 'Impressão de volume ',
        available: 'Disponíveis ',
        volumeOf: 'Volume de',
        availableCode: ' Código(s) disponível(is)',
        yupProduct: {
          codeRequired: 'Informe algum código por favor.',
          requiredLot: 'Informe o lote.',
          requiredObservation: 'Informe a observação desta operação.',
          amountRequired: 'Informe a quantidade de caixas a imprimir.',
          manufacturingRequired: 'Informe a data de fabricação do produto.',
          dueRequired: 'O produto é perecível, informe a data de validade.',
          intrinsicMarkingRequired: 'Informe a marcação intrínseca.',
          printerRequired: 'Escolha uma impressora.',
        },
        error: {
          description: 'Você deve escrever um código.',
          codeLimit: 'Você não pode ultrapassar o limite de agregações.',
          thisCodeOne: 'O código',
          thisCodeTwo: 'já está na lista de códigos.',
          notBelongProduct: 'Esse produto não pertence a este volume.',
          notBelongVolume: 'Esse volume não pertence a este volume.',
          exceedingLimitCodes: 'Quantidade está ultrapassando o limite de',
        },
      },
      filterModal: {
        title: 'Filtrar solicitações',
        subTitleOne: 'Filtrar por status de impressão',
        subTitleTwo: 'Filtrar por tipo',
        subTitleThree: 'Filtrar por data',
      },
      orderModal: {
        title: 'Ordenar solicitações',
        subTitleOne: 'Ordenar por nome',
        subTitleTwo: 'Ordenar por data',
      },
      RePrintSolicitation: {
        headerTitle: 'Reimpressão de etiqueta',
        pageTitle: 'Reimprimir etiqueta',
      },
    },
    Volume: {
      headerTitle: 'Volumes',
      title: 'Volumes',
      selectVolumeContent: 'Selecione o conteúdo do volume',
      all: 'Todos',
      total: 'Total: ',
      addVolumes: {
        add: 'Adicionar',
        edit: 'Detalhes',
        cardTitle: 'Adicionar um novo volume',
        products: 'Produtos',
        volumes: 'Volumes',
        selectProduct: 'Selecione o produto...',
        selectVolume: 'Selecione o volume...',
        selectBarcode: 'Selecione o tipo de código de barras...',
      },
      name: 'Nome',
      abbrev: 'Abreviação',
      amount: 'Quantidade de produtos',
      codes: 'Códigos disponíveis',
      content: 'Conteúdo',
      date: 'Data',
      actions: 'Mais opções',
      options: {
        details: 'Detalhes',
        printBox: 'Imprimir',
      },
      PrintVolume: {
        headerTitle: 'Impressão',
        printCardTitle: 'Imprimir',
        internalItems: 'Itens adicionados/Itens totais',
        availableCodes: 'códigos disponíveis',
      },
    },
    Locale: {
      role: 'Regras',
      employee: 'Funcionários',
      company: 'Empresa',
      license: 'Licenças',
      shippingCompany: 'Transportadora',
      solicitation: 'Solicitação',
      code: 'Código',
      invoice: 'Nota Fiscal',
      volume: 'Volume',
      event: 'Movimentação',
      product: 'Produto',
      printer: 'Impressora',
      report: 'Relatório',
      testPrintProduct: 'Impressão de teste',
      dailyProduction: 'Produção diária',
      dailyProductionXml: 'Relatório XML',
    },
    Error: {
      noDataSolicitation: 'Nenhuma solicitação encontrada.',
      noDataVolume: 'Nenhum volume encontrado',
      noDataProduct: 'Nenhum produto encontrado',
      locationPermission: 'É necessário permitir acesso a sua localização.',
      minorDate: 'A data de vencimento não pode ser inferior a de fabricação.',
      noExistCode: 'Esse código não existe no sistema.',
      unableCode: 'Esse código está impossibilitado de ser impresso.',
      codeNeverPrinted: 'Esse código nunca foi impresso.',
      choosePrinter: 'Escolha uma impressora',
      register: 'Cadastrar',
      previous: 'Anterior',
      next: 'Próxima',
      dProduction: 'Produção diária',
      linkInvoice: 'Vincular NF-e',
      transferInvoice: 'Transferir custódia',
      productPermission: 'Você não possui permissão para adicionar um produto.',
      employeeAddPermission:
        'Você não possui permissão para cadastrar um funcionário.',
      volumeAddPermission:
        'Você não possui permissão para adicionar um volume.',
      addShippingCompanyAddPermission:
        'Você não possui permissão para cadastrar uma transportadora.',
      dailyProductionPermission:
        'Você não possui permissão para gerar um relatório sobre a produção diária.',
      editShippingCompanyAddPermission:
        'Você não possui permissão para cadastrar uma transportadora.',
      volumeEditPermission: 'Você não possui permissão para editar um volume.',
      noPermissionToEditPrinter:
        'Você não possui permissão para editar uma impressora.',
      employeeEditPermission:
        'Você não possui permissão para editar um funcionário.',
      clientAddPermission:
        'Você não possui permissão para adicionar um cliente.',
      representativeAddPermission:
        'Você não possui permissão para adicionar um representante.',
      clientEditPermission: 'Você não possui permissão para editar um cliente.',
      representativeEditPermission:
        'Você não possui permissão para editar um representante.',
      typePackageProdcut: 'Informe o tipo de embalagem',
      noBarCode: 'Informe o tipo do código de barras.',
      existProductIdentifier: 'Um produto com esse identificador já existe.',
      amountProductSolicitation: 'A quantidade solicitada não está disponivel',
      notFoundBarcode: 'Nenhum tipo de código de barras encontrado.',
      serverCommunication: 'Houve um erro na comunicação com o servidor.',
      noAddress: 'Endereço não enconterado',
      noState: 'Nenhum estado encontrado',
      noCity: 'Nenhuma cidade encontrada',
      oneField: 'Preencha ao menos um campo.',
      deletePermission: 'Você não possui permissão para deletar este item.',
      errorPassword:
        'A senha deve ter uma letra minúscula, uma maiúscula, um número e um caractere especial.',
      codesAmount: 'Não possui códigos o suficiente',
      noPermissionToImportInvoice:
        'Você não possui permissão para importar uma nota fiscal.',
      noInvoiceFileAdded: 'Insira no mínimo uma nota fiscal.',
      noRoleName: 'Dê um nome a esta função.',
      noPermissionToEnableEmployee:
        'Você não possui permissão para ativar ou desativar um usuário.',
      accessDenied: 'Acesso negado.',
      pageNotFound: 'Página não encontrada.',
      insufficientCodes: 'Códigos insuficientes.',
      enterCode: 'Informe o código.',
      codeLimitReached: 'Limite de códigos atingido.',
      codeAlreadyAdded: 'Código já adicionado',
      productNotBelongAggregator:
        'O código do produto adicionado não pertence a este volume agregador.',
      volumeNotBelongAggregator:
        'O código do volume adicionado não pertence a este volume agregador.',
      productCodeIncompatible:
        'Esse código pertence a um produto. Adicione códigos apenas do volume interno deste volume agregador.',
      volumeCodeIncompatible:
        'Esse código pertence a um volume. Adicione códigos apenas do produto interno deste volume agregador.',
    },
    Select: {
      loadingMessage: 'Carregando...',
      statesList: 'Selecione o estado...',
      solicitationSearch: 'Pesquise pela descrição',
      employeeSearch: 'Pesquise pelo nome ou e-mail',
      function: 'Selecione a ocupação...',
      company: 'Selecione o cliente...',
      noCompany: 'Nenhum cliente encontrado...',
      noFunction: 'Nenhuma ocupação encontrada.',
      select: 'Selecione...',
      selectPacking: 'Selecione a embalagem',
      selectBarCode: 'Selecione o tipo de código de barras...',
      selectCity: 'Selecione a cidade...',
      noState: 'Nenhum estado encontrado.',
      noCity: 'Nenhuma cidade encontrada.',
      selectTagType: 'Selecione o tipo de etiqueta.',
      noProduct: 'Nenhum produto encontrado.',
      noVolume: 'Nenhum volume encontrado.',
      noEventsFound: 'Nenhum evento encontrado.',
      noPrintersFound: 'Nenhuma impressora encontrada.',
      labelTypesSelect: {
        placeholder: 'Selecione o tipo de etiqueta.',
        noOptions: 'Nenhum tipo de etiqueta encontrado.',
        optionsLabels: {
          product: 'Produto',
          volume: 'Volume',
        },
      },
      printersSelect: {
        placeholder: 'Selecione uma impressora...',
        noOptions: 'Nenhuma impressora encontrada.',
      },
    },
    Navbar: {
      settings: 'Configurações',
      exit: 'Sair',
    },
    Yup: {
      empty: 'Este campo é obrigatório.',
      noName: 'Informe o nome.',
      noEmail: 'Informe o e-mail',
      validEmail: 'Deve ser um e-mail válido',
      noStore: 'Informe a loja.',
      noPhone: 'Informe o telefone.',
      noObservation: 'Informe uma observação para esta operação.',
      noCode: 'Informe o codigo de vinculação',
      noAmount: 'Informe a quantidade.',
      noWarning: 'Informe a advertência desse volume.',
      noStackingLimit: 'Informe o limite de empilhamento desse volume',
      noEan: 'Informe o EAN-13 do produto.',
      noDun: 'Informe o DUN-14 da embalagem.',
      noPositive: 'Este número deve ser positivo',
      noInteger: 'Este número deve ser inteiro.',
      noDivisible:
        'A quantidade de produtos deve ser divisível pela quantidade de colméias.',
      noBarCode: 'Informe o tipo do código de barras.',
      noPartitions: 'Informe a quantidade de colmeias.',
      noPacking: 'Informe o tipo de embalagem.',
      noCountry: 'Informe o código do país.',
      noFactory: 'Informe o código da fábrica.',
      noWeight: 'Informe a massa do produto.',
      noUnity: 'Informe a unidade de medida de massa.',
      noId: 'Informe o id do produto',
      noUrl: 'Informe a url da impressora.',
      noPort: 'Informe a porta da impressora.',
      noAbbrev: 'A abreviação é obrigatória.',
      noNetWeight: 'Informe o peso líquido.',
      noGrossWeight: 'Informe o peso bruto.',
      noProduct: 'Escolha o produto.',
      noVolume: 'Escolha o volume.',
      minFixed3: 'O tamanho deve ser de 3 caracteres.',
      minFixed4: 'O tamanho deve ser de 4 caracteres.',
      minFixed5: 'O tamanho deve ser de 5 caravteres.',
      min4: 'O tamanho mínimo deve ser de 4 caracteres',
      min5: 'O tamanho mínimo deve ser de 5 caracateres',
      max5: 'O tamanho máximo dev ser de 5 caracteres',
      min8: 'O tamanho mínimo deve ser de 8 caracteres',
      maxFixed15: 'O número informado execede o limite de 15 caracteres.',
      netUnit: 'Informe a unidade de medida do peso líquido.',
      grossUnit: 'Informe a unidade de medida do peso bruto.',
      noCnpj: 'Informe o CNPJ.',
      noCpf: 'Informe o CPF.',
      sizeCnpj: 'CNPJ inválido, digite novamente.',
      sizeCPF: 'CPF inválido, digite novamente.',
      noMunicipalRegistration: 'Informe o registro municipal.',
      noStateRegistration: 'Informe o registro estadual.',
      noCep: 'Informe o CEP',
      noTargetUf: 'Informe o Estado',
      noTargetCity: 'Informe a cidade.',
      noNeighborhood: 'Informe o bairro.',
      noAddress: 'Informe o endereço.',
      noOffice: 'Informe o cargo.',
      noContactType: 'Informe o setor',
      noPassword: 'Insira uma senha',
      confirmPassword: 'Confirmar a senha',
      passwordsDontCheck: 'As senhas não conferem',
      minPassword: 'A senha deve ter no mínimo 8 dígitos',
      matchesPassword:
        'A senha deve ter pelo menos uma letra minúscula, uma maiúscula, um número e um caractere especial.',
      requiredField: 'Campo obrigatório',
      noTrade: 'Informe o nome fantasia.',
      companyName: 'Informe a razão social.',
      oldPassword: 'Informe a antiga senha',
      noDriver: 'Informe o nome do motorista.',
      errorEAN: 'Não são permitidos letras em um EAN.',
      driverCPF: 'Informe ao CPF do motorista.',
      errorCPF: 'CPF inválido, digite novamente.',
      noDestiny: 'Informe o destino.',
      noPlate: 'Informe a placa do veículo.',
      noLocation: 'É necessário permitir acesso a sua localização.',
      noPce: 'Informe o PCE do produto',
      codeRequest: 'Informe o código.',
      printerRequest: 'Escolha uma impressora.',
      noInnerBoxQuantity: 'Informe a quantidade de itens',
      manufacturingRequired: 'Informe a data de fabricação.',
      amountRequired: 'Informe uma quantidade.',
      printerRequired: 'Escolha uma impressora.',
    },
    AddMoviment: {
      title: 'Movimentar código',
      noCode: 'Nenhum código adicionado.',
      options: {
        deaggregation: 'Desagregação de volume',
        location: 'Localização',
        cancel: 'Cancelado',
        lost: 'Extraviado',
        damaged: 'Danificado',
        unlinked: 'Desvinculado de NFe',
        partition: 'Cancelar Colmeia',
        activateRoll: 'Ativar Pacote',
      },
      CodeList: {
        elementsTitle: {
          removeCode: 'Remover código',
        },
      },
    },
    Company: {
      title: 'Sua empresa',
      infoTitle: 'Informações da empresa',
      updateTitle: 'Atualizar dados da empresa',
    },
    Employee: {
      title: 'Funcionários',
      noEmployee: 'Nenhum funcionario encontrado',
      occupation: 'Ocupação',
      office: 'Cargo',
      user: 'Permissões de usuário',
      actions: 'Mais opções',
      total: 'Total',
      type: 'Tipo',
      register: 'Cadastrar',
      addEmployee: 'Cadastrar um novo funcionário',
      addAdminEmployee: 'Cadastrar funcionario administrador',
      edit: 'Editar',
      activeTotal: 'Ativos',
      disabledTotal: 'Não Ativos',
      name: 'Nome',
      email: 'E-mail',
      activeEmployee: 'Ativar funcionário',
      deactiveEmployee: 'Desativar funcionário',
      editEmployee: 'Editar funcionário',
      disable: {
        part1: 'Tem certeza que deseja ',
        active: 'ativar',
        deactive: 'desativar',
        activation: 'ativação',
        deactivation: 'desativação',
        part2: 'este funcionário?',
        part3: 'Esta é uma ação de ',
        part4: 'risco',
        part5: ' Ao confirmar a ',
        part6: ', esta ação',
        part7:
          'surtira efeitos diretos na utilização do sistema pelo funcionário',
      },
      roleLabel: 'Função (no sistema)',
    },
    Invoice: {
      title: 'Notas fiscais',
      total: 'Total:',
      noMove: 'Sem movimentações',
      aggregated: 'Vinculado',
      transfered: 'Tranferência de Custódia',
      nfe: 'NFe',
      selectCarrier: 'Selecionar a transportadora',
      number: 'Nº de produtos',
      state: 'Estado:',
      date: 'Data',
      actions: 'Mais opções',
      linkInvoice: 'Vincular NF-e',
      details: 'Detalhes',
      importTitle: 'Importação de NF-e',
      noData: 'Nenhuma NF-e importada.',
      aggregation: 'notas agregadas',
      trow: 'Arraste notas fiscais ou clique aqui',
      noMovimets: 'Sem movimentações',
      linked: 'Vinculada',
      transfer: 'Custódia transferida',
      added: 'Adicionada em ',
      productsAmount: 'N ° de produtos:',
      creat: 'Criada em ',
      receiver: 'Destinatário',
      locality: 'Localidade',
      items: 'Itens',
      ean: 'EAN',
      amount: 'Quantidade',
      price: 'Preço',
      ncm: 'NCM',
      unity: 'Unidade',
      noProduct: 'Nenhum produto encontrado.',
      driverInfo: 'Informações do motorista',
      info: 'Informações da operação',
      importedSucesfully: 'Notas importadas com sucesso.',
      InvoiceDetails: {
        CustodyTransfer: {
          invoiceNotAllowed:
            'A nota fiscal deve estar vinculada ou transferida.',
          selectShippingCompany: 'Selecione a transportadora...',
          noShippingCompanyFound: 'Nenhuma transportadora foi encontrada',
        },
      },
      CustodyTransfer: {
        selectShippingCompany: 'Selecione a transportadora...',
        noShippingCompanyFound: 'Nenhuma transportadora foi encontrada',
      },
      deleteModal: {
        part1: 'Excluir',
        part2: 'nota fiscal',
        part3: 'Esta é uma ação de ',
        part4: 'risco',
        part5: ' Tem certeza que deseja ',
        part6: 'excluir',
        part7: 'esta ',
        part8: 'nota fiscal?',
      },
      dailyProductionModal: {
        title: 'Produção diária',
        text: 'Deseja fazer o download do arquivo XML da produção diária? (nfes que foram exportadas no dia)',
      },
      noInvoiceAdded: 'Nenhuma nota fiscal adicionada à fila.',
    },
    Products: {
      title: 'Produtos',
      filter: 'Filtrar por data de cadastro',
      addProducts: 'Cadastre um novo produto',
      register: 'Cadastrar',
      productsCodes: 'Códigos disponíveis',
      noBarCode: 'Sem código de barra',
      addImage: 'Escolha um arquivo',
      total: 'Total: ',
      details: 'Detalhes',
      date: 'Data',
      noData: 'Nenhum produto cadastrado.',
      noPacking: 'Nenhuma embalagem encontrado.',
      name: 'Nome',
      product: 'Produto',
      amount: 'Quantidade',
      actions: 'Mais opções',
      options: {
        details: 'Detalhes',
        print: 'Imprimir',
      },
      info: 'Detalhes',
      volumes: {
        drum: 'Tambor',
        barrel: 'Barril',
        bombona: 'Bombona',
        box: 'Caixa',
        bag: 'Saco',
        compositePackaging: 'Embalagem composta',
      },
      elementsTitle: {
        deleteImageButton: 'Remover imagem',
      },
    },
    Printers: {
      title: 'Impressoras',
      total: 'Total:',
      date: 'Data:',
      name: 'Nome',
      port: 'Endereço IP',
      actions: 'Mais opções',
      noData: 'Nenhuma impressora encontrada.',
      add: 'Adicionar',
      addTitle: 'Adicione uma nova impressora',
      select: 'Selecione se for uma impressora usb',
      noPrinter: 'Nenhuma impressora usb encontrada.',
      edit: 'Editar',
      printer: 'Impressora',
      localPrinter: 'Impressora local',
      delete: {
        part1: 'Tem certeza que deseja',
        part2: 'excluir',
        part3: 'esta impressora?',
        part4: ' Esta é uma ação de',
        part5: 'risco',
        part6: ' Ao confirmar a exclusão, esta ação',
        part7: 'não podera ser desfeita',
      },
    },
    Safety: {
      title: 'Segurança',
      list: 'Funções cadastradas',
      details: 'Ver detalhes da função',
      edit: 'Editar função',
      register: 'Cadastrar uma nova função',
      choosePermission: 'Escolha as permissões desta função',
      select: 'Escolha as permissões...',
      noPermission: 'Nenhuma permissão encontrada.',
      permissionsTypesLabels: {
        role: 'Regras',
        company: 'Empresa',
        solicitation: 'Solicitações',
        product: 'Produtos',
        employee: 'Funcionários',
        volume: 'Volumes',
        invoice: 'Notas fiscais',
        printer: 'Impressoras',
        client: 'Clientes',
        shippingCompany: 'Transportadoras',
        event: 'Movimentações',
        testPrintProduct: 'Impressão de teste',
        report: 'Relatório',
        dailyProduction: 'Produção diária',
        code: 'Códigos',
      },
      EditRole: {
        headerTitle: 'Função',
        infoCardTitle: 'Informações desta função',
        roleNameLabel: 'Nome',
        updateCardTitle: 'Atualizar função',
      },
    },
    Permissions: {
      nothing: 'Nenhuma',
      index: 'Ler',
      store: 'Criar',
      indexStore: 'Ler e criar',
      indexUpdate: 'Ler, atualizar',
      update: 'Ler, atualizar e deletar',
      delete: 'Ler, criar, atualizar e deletar',
    },
    Settings: {
      title: 'Configurações',
      subtitle: 'Atualizar seus dados',
    },
    Report: {
      title: 'Geração de relatório',
      titleTwo: 'Relatório de códigos agregados',
      dateInit: 'Data de início',
      dateEnd: 'Data de fim',
      all: 'Todas',
      codeState: 'Selecione para gerar relatório de estado dos códigos',
      report: 'Gerar relatório',
      reportGenerate: 'Gere um relatório para exibir os dados',
      PDFGenerate: 'Gerar PDF',
      daily: 'Produção Diária',
      XMLtitle: 'Relatório XML',
    },
    ShippingCompanies: {
      title: 'Transportadoras',
      all: 'Todas',
      register: 'Cadastrar',
      total: 'Total: ',
      type: 'Tipo: ',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      date: 'Data: ',
      details: 'Detalhes',
      noCarriers: 'Nenhuma transportadora cadastrada.',
      newCarrier: 'Cadastre uma nova transportadora',
      company: 'Pessoa Jurídica',
      selfEmployee: 'Pessoa Física',
      edit: 'Editar',
      companyName: 'Razão Social/Nome',
      document: 'CNPJ/CPF',
      actions: 'Mais opções',
    },
    VolumesLabel: {
      code: 'Código',
      status: 'Estado',
      headerTitle: 'Etiquetas',
      titlePage: 'Impressão de Códigos',
      all: 'Todas',
      options: {
        printBox: 'Imprimir',
        cancel: 'Cancelar embalagem',
      },
      print: {
        name: 'Nome',
        id: 'Identificador',
        amount: 'Quantidade de produtos',
        partitions: 'Quantidade de colmeias',
        productPrinting: 'Impressão de Etiqueta de Produto',
      },
      total: 'Total',
      type: 'Tipo',
      date: 'Data',
      range: 'Faixa',
      details: 'Detalhes',
      noCarriers: 'Nenhum código encontrado.',
      company: 'Pessoa Jurídica',
      selfEmployee: 'Pessoa Física',
      edit: 'Editar',
      actions: 'Mais opções',
      printed: 'Impresso',
      unprinted: 'Não impresso',
      cancelled: 'Cancelado',
      production: 'Em produção',
      partialCancelled: 'Parcialmente cancelado',
      search: 'Pesquise pelo código ou status',
      PrintVolumeLabel: {
        headerTitle: 'Impressão',
        printCard: {
          title: 'Imprimir etiqueta',
          name: 'Nome',
          id: 'Identificador',
          amount: 'Quantidade de produtos',
          partitions: 'Quantidade de colmeias',
          productPrinting: 'Impressão de Etiqueta de Produto',
          printerSelect: {
            placeholder: 'Escolha uma impressora',
            noOptions: 'Nenhuma impressora encontrada.',
          },
        },
        yup: {
          printerRequired: 'Escolha uma impressora.',
        },
      },
      CancelVolumeLabel: {
        headerTitle: 'Cancelar etiqueta',
        pageTitle: 'Cancelar etiqueta',
      },
      FilterStatusModal: {
        title: 'Filtrar por status',
        selectStatusPlaceholder: 'Selecione o status',
        selectStatusNoOptions: 'Nenhum status encontrado',
      },
    },
    Tracking: {
      title: 'Rastrear código',
      noData: 'Não há movimentações recentes.',
      FilterModal: {
        title: 'Filtrar movimentações',
        buttons: {
          withoutFilter: 'Todos os eventos',
          writeEventsFilter: 'Escrita',
          readEventsFilter: 'Leitura',
        },
      },
      OrderModal: {
        title: 'Ordenar movimentações',
        buttons: {
          orderByCreationDate: 'Últimas movimentações',
        },
      },
    },
    Clients: {
      title: 'Clientes',
      add: 'Adicionar',
      addClient: 'Cadastre um novo Cliente',
      registerRepresentative: 'Cadastre um Representante',
      representative: 'Representante',
      all: 'Todos',
      noData: 'Nenhum cliente cadastrado',
      date: 'Data: ',
      name: 'Razão Social/Nome',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      edit: 'Editar',
      total: 'Total: ',
      details: 'Detalhes',
      addRepresentative: 'Adicionar representante',
      editRepresentative: 'Editar representante',
      noRepresentative: 'Não adicionado',
    },
    BackEndErrors: {
      'A product with this Identifier already registered.':
        'Um produto com esse identificador já existe.',
      'Insufficient partition codes.': 'Códigos de partições insuficientes.',
      'Insufficient volume codes.': 'Códigos de volumes insuficientes.',
      'Insufficient product codes.': 'Códigos de produtos insuficientes.',
      'Internal server error.': 'Erro interno do servidor',
      'Your license expired. Please, upgrade your plan to get access again.':
        'Sua licensa expirou, por favor atualize seu plano de acesso.',
      'Your license offline time expired. Please, connect you server in internet to get access again.':
        'Sua licensa offline expirou, por favor conecte a internet para ter acesso novamente.',
      'Access denied.': 'Acesso negado.',
      'Validation error.': 'Erro na validação.',
      "Passwords don't check.": 'As senhas não conferem.',
      'confirmPassword is a required field': 'Confirme a senha.',
      'Old and new password are the same.':
        'A nova senha e a antiga são as mesmas.',
      'Company not found.': 'Empresa não encontrada.',
      'You do not have permission to enable or disable a employee.':
        'Você não tem permissão para ativar ou desativar um funcionário.',
      'The company must be activated in order to register a employee.':
        'A empresa deve estar ativada para cadastrar um funcionário.',
      'E-mail already registered.': 'Email já foi registrado.',
      'You have exceeded the limit of active employees, upgrade your plan to register more.':
        'Você excedeu o limite de funcionários ativos, atualize seu plano para cadastrar mais.',
      'The company exceeded the activate employees limit. Please, upgrade your plan.':
        'A empresa excedeu o limite de funcionários ativos. Por favor, atualize seu plano.',
      'Employee with this email already registered.':
        'Funcionário com este e-mail já existe.',
      'Employee not found.': 'Funcionário não encontrado.',
      "You can't change your password without inform your old password.":
        'Você não pode alterar sua senha sem informar a sua senha antiga.',
      'Incorrect password.': 'Senha incorreta.',
      'Token already used.': 'Token já foi usado.',
      'Token expired.': 'Token expirado.',
      'Token not found.': 'Token não encontrado.',
      'Employee disabled.': 'Funcionário desativado.',
      'No invoice aggregate with this code.':
        'Não existe nota fiscal vinculada a este código.',
      'It is not possible to delete an invoice already aggregated.':
        'Não é possível excluir uma nota fiscal já agregada.',
      'Invoice not found.': 'Nota fiscal não encontrada.',
      'Printer not found': 'Impressora não encontrada.',
      'A product with this PCE has already been registered.':
        'Um produto com esse PCE já foi registrado.',
      'A product with this EAN-13 has already been registered.':
        'Um produto com esse ean já esta registrado.',
      'Product not found.': 'Produto não encontrado.',
      'Permission not found.': 'Permissão não encontrada.',
      'A rule with this name has already been created.':
        'Uma regra com este nome já foi criada.',
      'Role not found.': 'Regra não encontrada.',
      'There is already a shipping company with this CNPJ.':
        'Já existe uma transportadora com este CNPJ.',
      'There is already a shipping company with this CPF.':
        'Já existe uma transportadora com este CPF.',
      'Shipping company not found.': 'Transportadora não encontrada.',
      'Amount not avaliable.': 'Quantidade não disponível.',
      'This code is already aggregated to an invoice.':
        'Este código já foi agregado a uma nota fiscal.',
      'Code not found.': 'Código não encontrado.',
      'Invalid code.': 'Código inválido',
      'The code does not belong to a volume.':
        'O código não pertence a um volume.',
      'Codes not found.': 'Códigos não encontrados.',
      'Only products solicitations allowed':
        'Somente solicitações de produto são permitidas.',
      'Solicitation already printed.': 'Solicitação já impressa.',
      'Solicitation not found': 'Solicitação não encontrada',
      'Solicitation not printed.': 'Solicitação não impressa.',
      'A volume with this abbreviation has already been created.':
        'Um volume com essa abreviação já foi criado.',
      'Volume not found.': 'Volume não encontrado.',
      'A volume with this has already been created.':
        'Um volume com esse DUN-14 já foi criado.',
      'No files uploaded': 'Os arquivos não foram enviados.',
      'Unknown permission.': 'Permissão desconhecida.',
      'This invoice was already transferred.':
        'Esta nota fiscal já foi transferida.',
      'User not found.': 'Funcionário não encontrado.',
      'Old password is incorrect.': 'Senha incorreta.',
      'Only products requests allowed': 'Apenas pedidos de produtos permitidos',
      'You cannot change your password without informing your old password.':
        'Você não pode alterar sua senha sem informar sua senha antiga.',
      'The company has exceeded the activate employees limit. Please, upgrade your plan.':
        'A empresa excedeu o limite de ativação dos funcionários. Por favor, atualize seu plano.',
      'The company must be activated in order to register an employee.':
        'A empresa deve ser acionada para registrar um funcionário.',
      'You do not have permission to enable or disable an employee.':
        'Você não tem permissão para habilitar ou desativar um funcionário.',
      'Your license expired. Please upgrade your plan to get access again.':
        'Sua licença expirou. Por favor, atualize seu plano para ter acesso novamente.',
      code_not_active: 'Código não ativado',
      code_not_print: 'Código não impresso',
      no_event_found: 'Evento não encontrado',
      code_already_active: 'Código já ativo',
      code_already_active_aggregation: 'Código já ativo para agregação',
      code_not_already_active_aggregation: 'Código não ativo para agregação',
      code_does_not_belong_aggregation: 'Código não pertence a agregação',
      code_is_not_valid: 'Código inexistente',
      code_already_marked: 'Código já impresso',
      'Communication failure.': 'Ocorreu um erro de comunicação com o sistema',
      package_already_active: 'Pacote já ativado',
    },
    Modal: {
      employeeFilter: 'Filtrar funcionários',
      employeeOrder: 'Ordernar funcionários',
      nameOrder: 'Ordem alfabética',
      mailOrder: 'Ordernar por email',
      dateFilter: 'Filtrar por data: ',
      registerFilter: 'Filtrar por data de cadastro',
    },
    PrintTest: {
      title: 'Teste de impressão',
      text: 'Selecione um tipo de etiqueta',
    },
    SideBar: {
      addLicense: 'Adicionar nova licença',
      addClients: 'Adicionar',
      solicitationsList: 'Listar Solicitações',
      solicitationsAdd: 'Gerar Solicitações',
      solicitations: 'Solicitações',
      moviments: 'Movimentar código',
      tracking: 'Rastrear código',
      products: 'Produtos',
      productsList: 'Listar todos',
      productsAdd: 'Adicionar',
      volumesLabelList: 'Etiquetas',
      volumes: 'Volumes',
      volumesList: 'Listar todos',
      volumesAdd: 'Adicionar novo',
      testPrint: 'Teste de impressão',
      shippingCompanies: 'Transportadoras',
      shippingCompaniesList: 'Listar todas',
      shippingCompaniesAdd: 'Cadastrar',
      invoices: 'Notas Fiscais',
      invoicesList: 'Listar todas',
      invoicesAdd: 'Importar',
      printers: 'Impressoras',
      printersList: 'Listar todas',
      printersAdd: 'Adicionar',
      report: 'Relatório',
      employees: 'Funcionários',
      employeesList: 'Listar todos',
      employeesAdd: 'Cadastrar',
      company: 'Empresa',
      security: 'Segurança',
      settings: 'Configurações',
      clients: 'Clientes',
      clientsAdd: 'Adicionar',
      clientsList: 'Listar todos',
      reprintLabel: 'Reimpressão de etiquetas',
      dailyProduction: 'Produção diária',
      dailyProductionXml: 'Relatório XML',
      codesProduction: 'Códigos agregados',
      printMenuOptions: 'Impressão',
      print: 'Impressão de etiquetas',
      addNewRolls: 'Adicionar pacote de códigos',
      assignCompanyRoll: 'Atribuir códigos',
      setCodes: 'Vinculação de código',
    },
    Warning: {
      invalidSession: 'Sua sessão expirou, faça o login novamente.',
    },
    Packing: {
      title: 'Empacotamento',
      titleTwo: 'Volume agregador',
      volume: 'Volume: ',
      maxAggregation: 'Máximo de agregações: ',
      codesForAggregation: 'Códigos para agregação',
      aggregatorVolume: 'Volume agregador',
      removeCode: 'Excluir código',
      noCodesAdd: 'Nenhum código adicionado.',
      errors: {
        noVolumeCode: 'Insira o código do volume.',
        invalidCode: 'Código inválido.',
        noCode: 'Insira o código do volume/produto.',
        toMuchAggregation: 'Você não pode ultrapassar o limite de agregações.',
        codeList: {
          part1: 'O código',
          part2: 'já está na lista de códigos.',
        },
        invalidVolumeCode: 'Este código não pode ser agregado a esse volume',
        noLocation: 'É necessário permitir acesso a sua localização.',
        noCodes: 'Não há códigos para realizar a movimentação.',
        oneRequired:
          'É necessario ao menos 1 código para realizar a agregação.',
      },
    },
    Success: {
      companyUpdate: 'Empresa atualizada com sucesso!',
      completeSearch: 'Busca feita com successo',
      registerEmployee: 'Funcionário cadastrado com sucesso.',
      userUpdate: 'Usuário atualizado.',
      invoiceLinked: 'NF-e agregada.',
      invoiceTransfered: 'Custódia da NF-e tranferida para o veículo de placa',
      invoiceDelete: 'Nota fiscal excluída.',
      moveError: 'Ocorreu um erro ao fazer a movimentação.',
      moveSuccess: 'Movimentação realizada.',
      printDone: 'Impressão realizada.',
      error: 'Erro',
      inCode: 'Código',
      rePrintDone: 'Reimpressão realizada.',
      codesError: 'Erro nos seguintes códigos: ',
      printerCreated: 'Impressora criada.',
      printerUpdate: 'Impressora atualizada.',
      printerDelete: 'Impressora excluída.',
      productAdd: 'Produto adicionado.',
      productUpdate: 'Produto atualizado.',
      newOcupation: 'Função criada.',
      updateRole: 'Regras atualizadas com sucesso.',
      carrierRegister: 'Transportadora cadastrada.',
      carrierUpdate: 'Transportadora atualizada.',
      solicitationDone: 'Solicitação feita.',
      aggregationDone: 'Agregação feita.',
      successUpdate: 'Dados atualizados com sucesso.',
      recoveryEmail:
        'Um link de recuperação de senha foi enviado para o seu e-mail.',
      passwordRecovery: 'Senha recuperada.',
      activeLink: 'Um link de ativação foi enviado para seu e-mail.',
      registerActive: 'Conta ativada com sucesso.',
      volumeAdd: 'Volume adicionado.',
      volumeUpdate: 'Volume atualizado.',
      roles: {
        nothing: 'Nenhuma',
        index: 'Ler',
        store: 'Ler e criar',
        indexUpdate: 'Ler e atualizar',
        update: 'Ler, atualizar e deletar',
        delete: 'Ler, criar, atualizar e deletar',
      },
      createClient: 'Cliente criado.',
      updateClient: 'Cliente atualizado.',
      createRepresentative: 'Responsável criado.',
      updateRepresentative: 'Responsável atualizado.',
      dailyProductionFileDownloaded: 'Arquivo baixado com sucesso.',
      Sagas: {
        Moviments: {
          VolumesLabel: {
            canceled: 'Etiqueta cancelada.',
          },
        },
      },
      printSend: 'Impressão enviada.',
    },
    Solicitations: {
      title: 'Solicitações de Códigos',
      all: 'Listagens',
      total: 'Total',
      amount: 'Quantidade',
      print: 'Impressão',
      observation: 'Descrição',
      date: 'Criação',
      actions: 'Mais Opções',
      options: {
        codeGenerate: 'Gerar Códigos',
        print: 'Imprimir etiquetas',
      },
    },
    Print: {
      headerTitle: 'Impressão de etiquetas',
      pageTitle: 'Imprimir etiquetas',
      TestPrint: {
        headerTitle: 'Teste de impressão',
        printCardTitle: 'Realizar impressão de teste',
      },
    },
    Tab: {
      addProduct: 'Adicionar Produto',
      addVolume: 'Adicionar Volume',
    },
    SetCode: {
      title: 'Vincular Código',
    },
  },
};
