import styled from 'styled-components';

import ButtonBase from './ButtonBase';

export default styled(ButtonBase)`
  width: 100%;
  height: 2.8rem;
  border: 2px solid;
  padding: 2px;
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  transition: color 0.3s, background 0.5s;

  border-color: ${(props) =>
    props.selected ? 'transparent' : props.theme.colors.primary.base};
  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary.base : 'transparent'};
  color: ${(props) => (props.selected ? '#fff' : props.theme.colors.primary.base)};

  &:hover {
    background-color: ${(props) =>
      props.selected ? 'transparent' : props.theme.colors.primary.base};
    color: ${(props) => (props.selected ? props.theme.colors.primary.base : '#fff')};
    border-color: ${(props) =>
      props.selected ? props.theme.colors.primary.base : 'transparent'};
  }
`;
