import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import {
  CreatePrinterDTO,
  PrinterDTO,
  UpdatePrinterDTO,
} from '@/dtos/printers';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { PrinterState } from './types';

const INITIAL_STATE: PrinterState = {
  data: [],
  next: undefined,
  filters: SearchQuery.build(),
  selected: {} as PrinterDTO,
  currentPage: 1,
  prev: undefined,
  total: 0,
  error: '',
  loading: false,
};

export enum PrinterTypes {
  SET_LOADING = '@printers/SET_LOADING',
  SET_FILTERS = '@printers/SET_FILTERS',
  LIST_REQUEST = '@printers/LIST_REQUEST',
  LIST_SUCCESS = '@printers/LIST_SUCCESS',
  LIST_ERROR = '@printers/LIST_ERROR',
  UPDATE_REQUEST = '@printers/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@printers/UPDATE_SUCCESS',
  UPDATE_ERROR = '@printers/UPDATE_ERROR',
  GET_REQUEST = '@printers/GET_REQUEST',
  GET_SUCCESS = '@printers/GET_SUCCESS',
  GET_ERROR = '@printers/GET_ERROR',
  DELETE_REQUEST = '@printers/DELETE_REQUEST',
  DELETE_SUCCESS = '@printers/DELETE_SUCCESS',
  DELETE_ERROR = '@printers/DELETE_ERROR',
  ADD_REQUEST = '@printers/ADD_REQUEST',
  ADD_SUCCESS = '@printers/ADD_SUCCESS',
  ADD_ERROR = '@printers/ADD_ERROR',
  CLEAR = '@printers/CLEAR',
}

const PrinterActions = {
  setLoading: (loading: boolean) =>
    action(PrinterTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(PrinterTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(PrinterTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<PrinterDTO>) =>
    action(PrinterTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(PrinterTypes.LIST_ERROR, { error }),

  updateRequest: (data: UpdatePrinterDTO) =>
    action(PrinterTypes.UPDATE_REQUEST, data),
  updateSuccess: () => action(PrinterTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(PrinterTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(PrinterTypes.GET_REQUEST, id),
  getSuccess: (resource: PrinterDTO) =>
    action(PrinterTypes.GET_SUCCESS, resource),
  getError: (error: string) => action(PrinterTypes.GET_ERROR, { error }),

  deleteRequest: (id: string) => action(PrinterTypes.DELETE_REQUEST, id),
  deleteSuccess: () => action(PrinterTypes.DELETE_SUCCESS),
  deleteError: (error: string) => action(PrinterTypes.DELETE_ERROR, { error }),

  addRequest: (data: CreatePrinterDTO) =>
    action(PrinterTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(PrinterTypes.ADD_SUCCESS),
  addError: (error: string) => action(PrinterTypes.ADD_ERROR, { error }),

  clear: () => action(PrinterTypes.CLEAR),
};
export default PrinterActions;

type PrinterReducer<Action extends AnyAction> = Reducer<PrinterState, Action>;

const setLoading: PrinterReducer<
  ReturnType<typeof PrinterActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: PrinterReducer<
  ReturnType<typeof PrinterActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: PrinterReducer<
  ReturnType<typeof PrinterActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: PrinterReducer<
  ReturnType<typeof PrinterActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: PrinterReducer<ReturnType<typeof PrinterActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<PrinterState>(INITIAL_STATE)
  .handleAction(PrinterTypes.SET_LOADING, setLoading)
  .handleAction(PrinterTypes.SET_FILTERS, setFilters)
  .handleAction(PrinterTypes.LIST_REQUEST, genericRequest)
  .handleAction(PrinterTypes.LIST_SUCCESS, listSuccess)
  .handleAction(PrinterTypes.LIST_ERROR, genericError)
  .handleAction(PrinterTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(PrinterTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(PrinterTypes.UPDATE_ERROR, genericError)
  .handleAction(PrinterTypes.GET_REQUEST, genericRequest)
  .handleAction(PrinterTypes.GET_SUCCESS, getSuccess)
  .handleAction(PrinterTypes.GET_ERROR, genericError)
  .handleAction(PrinterTypes.ADD_REQUEST, genericRequest)
  .handleAction(PrinterTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(PrinterTypes.ADD_ERROR, genericError)
  .handleAction(PrinterTypes.DELETE_REQUEST, genericRequest)
  .handleAction(PrinterTypes.DELETE_SUCCESS, genericSuccess)
  .handleAction(PrinterTypes.DELETE_ERROR, genericError)
  .handleAction(PrinterTypes.CLEAR, clear);
