import React, { lazy, Suspense, memo } from 'react';

import { SvgIconProps } from './types';

const SvgIcon: React.FC<SvgIconProps> = ({ name, size }) => {
  const Svg = lazy(
    () => import(`react-feather/dist/icons/${name ? name : 'alert-circle'}`)
  );

  return (
    <Suspense fallback>
      <Svg size={size} />
    </Suspense>
  );
};

export default memo(SvgIcon);
